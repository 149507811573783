// --------------------------------------------------------------
// Created On: 2022-09-16
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import Modal from "../Modal/Modal";
import ModalHeader from "../ModalHeader/ModalHeader";
import ModalBody from "../ModalBody/ModalBody";
import ModalFooter from "../ModalFooter/ModalFooter";
import PropTypes from "prop-types";
import styles from "./InfoModal.module.scss";

// Modal for showing generic information.
export default function InfoModal(props: Props): Component {
  return (
    <Fragment>
      <span className={props.darkBackground ? styles.linkBright : styles.link} onClick={props.onClick}>
        {props.linkMessage !== undefined && props.linkMessage !== null && props.linkMessage.length !== 0 ? (
          <span>{props.linkMessage}</span>
        ) : (
          <span>&#40; ? &#41;</span>
        )}
      </span>

      <Modal
        show={props.showModal}
        onHide={() => props.onClose()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--super-modal-z-index)" }}
        size="xl"
        animation
        centered
      >
        <ModalHeader>
          <h5 className="font-weight-bold">{props.title}</h5>
        </ModalHeader>

        <ModalBody>{props.children}</ModalBody>

        <ModalFooter>
          <button className="btn btn-secondary" type="button" onClick={() => props.onClose()}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

InfoModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  linkMessage: PropTypes.string,
  darkBackground: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any,
};

interface Props {
  showModal: boolean;
  title: string;
  linkMessage?: string;
  darkBackground: boolean;
  onClick: () => void;
  onClose: () => void;
  children: Children;
}
