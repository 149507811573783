// --------------------------------------------------------------
// Created On: 2023-02-06
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState } from "react";
import PropTypes from "prop-types";
import { API } from "../../../../constants/miscellaneous";
import AssetgroupDiagram from "../AssetgroupDiagram/AssetgroupDiagram";
import Spinner from "../../../../components/Spinner/Spinner";
import TabList from "../../../../components/TabList/TabList";
import apiRequest from "../../../../utilities/api/apiRequest";
import AssociatedUsergroupItem from "./AssociatedUsergroupItem/AssociatedUsergroupItem";
import AssociationModal from "../../../../components/AssociationModal/AssociationModal";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../../redux/selectors";
import styles from "./UsergroupTab.module.scss";

// Tab for associating user groups with asset groups.
export default function UsergroupTab(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [showAssociation, setShowAssociation] = useState(false);
  const [associationErrorMessage, setAssociationErrorMessage] = useState<string>("");
  const currentUser = useSelector(getCurrentUser);

  // Given a list of selected user groups, get the complete nested data for each from the API and associate it.
  async function associateUsergroups(usergroups: Usergroup[]): Promise<void> {
    const apiRequests: Promise<[Response, GetUserResponseBody]>[] = [];

    setLoading(true);
    usergroups.forEach((usergroup) => {
      const apiRequestPromise = apiRequest(
        `${API}/company/${currentUser.companyId}/usergroup/${usergroup.usergroupId}/user`,
        "GET",
        null
      ) as unknown;
      apiRequests.push(apiRequestPromise as Promise<[Response, GetUserResponseBody]>);
    });

    // Wait for all user group API requests to resolve.
    const resolvedPromises = await Promise.all(apiRequests);
    setLoading(false);

    // Process each API request that have resolved.
    let apiErrorMessage = "";
    const tempUsergroups: Usergroup[] = [];

    resolvedPromises.forEach(([response, responseBody], i) => {
      if (response.ok && responseBody && usergroups.length > i) {
        const usergroup = usergroups[i];
        if (usergroup.operateAccessPermission !== undefined) {
          tempUsergroups.push({
            usergroupId: usergroup.usergroupId,
            name: usergroup.name,
            isDefault: false,
            operateAccessPermission: usergroup.operateAccessPermission,
            users: responseBody.users,
          });
        }
      } else {
        apiErrorMessage = "Internal server error. Unable to get user group information.";
      }
    });

    // Check if there was an error while processing API responses.
    if (apiErrorMessage.length > 0) {
      setAssociationErrorMessage(apiErrorMessage);
    } else {
      props.onChangeUsergroups(tempUsergroups);
      setShowAssociation(false);
      setAssociationErrorMessage("");
    }
  }

  return (
    <div>
      <Spinner loading={loading} />

      {/* Interactive asset group diagram. */}
      {!showAssociation && (
        <AssetgroupDiagram
          assetgroup={{
            assetgroupId: props.assetgroupId,
            name: props.assetgroupName,
            usergroups: props.selectedUsergroups,
            assets: [],
            assetgroups: [],
            isDefault: false,
          }}
          onClick={() => {
            /* Do nothing. */
          }}
          display="users"
        />
      )}

      {/* Association menu tabs.*/}
      <div className={`${styles.body} my-4`}>
        <div className="row mx-2">
          <TabList
            tabs={props.tabs}
            selectedTabId={props.tabId}
            onSelect={(tabId: string) => props.onClickTab(tabId)}
          />

          {/* Button and other general content. */}
          <div className={`${styles.tabsBar} py-3 text-center`}>
            <button
              data-test="assetgroup-modal-manage-user-associations-button"
              className={`${styles.associationButton} btn btn-light my-2`}
              disabled={props.disabled}
              onClick={() => setShowAssociation(true)}
            >
              Manage User Group Associations
            </button>
          </div>

          {/* List of associated user groups. */}
          <div className={`${styles.tabsContainer} p-0`}>
            {props.selectedUsergroups.map((usergroup: Usergroup) => (
              <AssociatedUsergroupItem
                key={usergroup.usergroupId}
                usergroupId={usergroup.usergroupId}
                name={usergroup.name}
                operateAccessPermission={usergroup.operateAccessPermission}
              />
            ))}
          </div>
        </div>
      </div>

      <AssociationModal
        showModal={showAssociation}
        title="Manage User Group Associations"
        type="usergroup"
        associatedItemsTitle="Associated User Groups"
        unassociatedItemsTitle="Unassociated User Groups"
        items={props.usergroups}
        associatedItems={props.selectedUsergroups}
        itemIdKey="usergroupId"
        errorMessage={associationErrorMessage}
        disabled={props.disabled}
        onClose={() => setShowAssociation(false)}
        onChange={(associations) => associateUsergroups(associations)}
      />
    </div>
  );
}

UsergroupTab.propTypes = {
  tabId: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  assetgroupId: PropTypes.number.isRequired,
  assetgroupName: PropTypes.string.isRequired,
  usergroups: PropTypes.array.isRequired,
  selectedUsergroups: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClickTab: PropTypes.func.isRequired,
  onChangeUsergroups: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

interface Props {
  tabId: string;
  tabs: ManagementTab[];
  assetgroupId: number;
  assetgroupName: string;
  usergroups: Usergroup[];
  selectedUsergroups: Usergroup[];
  disabled: boolean;
  onClickTab: (tabId: string) => void;
  onChangeUsergroups: (usergroups: Usergroup[]) => void;
  onError: (errorMessage: string) => void;
}

interface GetUserResponseBody {
  users: User[];
}

interface Usergroup {
  usergroupId: number;
  name: string;
  operateAccessPermission: boolean;
  isDefault: boolean;
  users: User[];
}

interface User {
  userId: number;
  name: string;
  emailAddress: string;
  roleId: number;
}

interface ManagementTab {
  tabId: string;
  name: string;
  shortName: string;
  breakPoints: string;
}
