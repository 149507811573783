// --------------------------------------------------------------
// Created On: 2024-12-10
// Author: Dimitra Weinstein
//
// Last Modified: 2025-01-20
// Modified By: Zachary Thomas
//
// Copyright 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { MAX_ROWS_PER_PAGE } from "../../../constants/miscellaneous";
import FilteredTableContainer from "../../../components/FilteredTableContainer/FilteredTableContainer";
import SortedTableHeader from "../../../components/SortedTableHeader/SortedTableHeader";
import PaginationControl from "../../../components/PaginationControl/PaginationControl";
import Spinner from "../../../components/Spinner/Spinner";
import styles from "./SuperAdminReportPageDataTable.module.scss";
import Toast from "../../../components/Toast/Toast";

// Report Data Table.
export default function SuperAdminDataTable(props: Props): Component {
  return (
    <Fragment>
      <Spinner loading={props.loading} />

      {/* Toast error message */}
      {props.failedToLoad && (
        <Toast
          type="error"
          title={`Failed to load ${props.reportType} data`}
          message={props.errorMessage}
          show={props.failedToLoad}
          onClose={() => {
            props.onSetFailedToLoad(false), props.onSetErrorMessage("");
          }}
        />
      )}

      {/* Table */}
      <FilteredTableContainer
        title={`${props.formattedReportType} Report Data (${props.rowCount})`}
        filterPrompt={`Filter ${props.reportType} Report Data...`}
        filter={""}
        hasContent={props.dataRows !== undefined && props.dataRows.length > 0}
        hasFilteredContent={false}
        showFilterInput={false}
        pluralContentType={"report data"}
        buttonText="Download Report"
        onChangeFilter={() => {
          /* Do nothing */
        }}
        onClickButton={() => props.onDownloadCsv()}
      >
        <table className="table">
          <thead className={styles.header}>
            <tr>
              {props.columnHeaders.map((header, i) => (
                <SortedTableHeader
                  key={i}
                  name={header}
                  index={i}
                  selectedIndex={props.sortColumnIndex}
                  selectedAscending={props.sortAscending}
                  onClick={(index) => props.onSortData(index)}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {props.dataRows !== undefined &&
              props.dataRows.map((dataRow, i) => (
                <tr key={i}>
                  {dataRow.map((column, j) => (
                    <td key={j}>{column}</td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </FilteredTableContainer>
      {/* Pagination */}
      <PaginationControl
        pageNumber={props.pageNumber}
        maxPerPage={MAX_ROWS_PER_PAGE}
        totalCount={props.rowCount}
        onClick={(pageNumber) => props.onPaginate(pageNumber)}
      />
    </Fragment>
  );
}

SuperAdminDataTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  failedToLoad: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  reportType: PropTypes.string.isRequired,
  formattedReportType: PropTypes.string.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  pageNumber: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  columnHeaders: PropTypes.array.isRequired,
  sortAscending: PropTypes.bool.isRequired,
  sortColumnIndex: PropTypes.number.isRequired,
  dataRows: PropTypes.array.isRequired,
  onSetFailedToLoad: PropTypes.func.isRequired,
  onSetErrorMessage: PropTypes.func.isRequired,
  onPaginate: PropTypes.func.isRequired,
  onSortData: PropTypes.func.isRequired,
  onDownloadCsv: PropTypes.func.isRequired,
};

interface Props {
  loading: boolean;
  failedToLoad: boolean;
  errorMessage: string;
  reportType: string;
  formattedReportType: string;
  startDate: string | null;
  endDate: string | null;
  pageNumber: number;
  rowCount: number;
  columnHeaders: string[];
  sortAscending: boolean;
  sortColumnIndex: number;
  dataRows: (string | number)[][];
  onSetFailedToLoad: (failedToLoad: boolean) => void;
  onSetErrorMessage: (errorMessage: string) => void;
  onPaginate: (pageNumber: number) => void;
  onSortData: (newColumnIndex: number) => void;
  onDownloadCsv: () => void;
}
