// --------------------------------------------------------------
// Created On: 2021-11-10
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState } from "react";
import PropTypes from "prop-types";
import Spinner from "../../components/Spinner/Spinner";
import Error from "../../components/Error/Error";
import Card from "../../components/Card/Card";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import getApiError from "../../utilities/api/getApiError";
import { useNavigate } from "react-router-dom";
import apiRequest from "../../utilities/api/apiRequest";
import PasswordRequirements from "../../components/PasswordRequirements/PasswordRequirements";
import {
  API,
  MIN_PASSWORD_LENGTH,
  MAX_PASSWORD_LENGTH,
  VALID_PASSWORD_NUMBER_REGULAR_EXPRESSION,
  VALID_PASSWORD_LOWERCASE_REGULAR_EXPRESSION,
  VALID_PASSWORD_UPPERCASE_REGULAR_EXPRESSION,
  VALID_PASSWORD_SPECIAL_REGULAR_EXPRESSION,
} from "../../constants/miscellaneous";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUser, getAccessToken } from "../../redux/selectors";
import { setAuthorizerToken, setAccessToken, setCurrentUser } from "../../redux/actions";
import styles from "./ChangeTemporaryPasswordPage.module.scss";

// Page for users to change their temporary password.
export default function ChangeTemporaryPasswordPage(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const currentUser = useSelector(getCurrentUser);
  const accessToken = useSelector(getAccessToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Check if the user pressed enter, if they did we attempt to change the password.
  function checkPressEnter(event: React.KeyboardEvent<HTMLDivElement>): void {
    if (event.key === "Enter") {
      void changePassword();
    }
  }

  // Validate the passwords.
  function passwordIsValid(): boolean {
    if (newPassword.length < MIN_PASSWORD_LENGTH || newPassword.length > MAX_PASSWORD_LENGTH) {
      setErrorMessage(
        `Your new password must be between ${MIN_PASSWORD_LENGTH} and ${MAX_PASSWORD_LENGTH} characters long.`
      );
      return false;
    } else if (!VALID_PASSWORD_NUMBER_REGULAR_EXPRESSION.test(newPassword)) {
      setErrorMessage("Your new password must include at least one number.");
      return false;
    } else if (!VALID_PASSWORD_LOWERCASE_REGULAR_EXPRESSION.test(newPassword)) {
      setErrorMessage("Your new password must include at least one lower case letter.");
      return false;
    } else if (!VALID_PASSWORD_UPPERCASE_REGULAR_EXPRESSION.test(newPassword)) {
      setErrorMessage("Your new password must include at least one upper case letter.");
      return false;
    } else if (!VALID_PASSWORD_SPECIAL_REGULAR_EXPRESSION.test(newPassword)) {
      setErrorMessage("Your new password must include at least one special character.");
      return false;
    } else if (newPassword !== confirmPassword) {
      setErrorMessage("Your new password does not match the confirmation password.");
      return false;
    } else {
      return true;
    }
  }

  // Attempt to change the temporary password to the new password.
  async function changePassword(): Promise<void> {
    if (passwordIsValid()) {
      const requestBody = {
        emailAddress: currentUser.emailAddress,
        newPassword: newPassword,
        accessToken: accessToken,
      };

      setLoading(true);
      const [response, responseBody] = await apiRequest(`${API}/registration/password`, "PUT", requestBody);
      setLoading(false);

      if (response.ok && responseBody) {
        void login(currentUser.emailAddress, newPassword);
      } else {
        setErrorMessage(await getApiError(response, "Unable to change password."));
      }
    }
  }

  // Attempts to login a user.
  async function login(emailAddress: string, password: string): Promise<void> {
    if (emailAddress.length === 0) {
      setErrorMessage("Please enter an emailAddress.");
    } else if (password.length === 0) {
      setErrorMessage("Please enter a password.");
    } else {
      const requestBody = {
        username: emailAddress,
        password: password,
      };

      setLoading(true);
      const [response, responseBody] = (await apiRequest(`${API}/registration/authenticate`, "POST", requestBody)) as [
        Response,
        ResponseBody
      ];
      setLoading(false);

      if (response.ok && responseBody) {
        dispatch(setAuthorizerToken(responseBody.token));
        dispatch(setAccessToken(responseBody.accessToken));

        if (responseBody.newPasswordRequired) {
          dispatch(
            setCurrentUser(
              currentUser.userId,
              currentUser.userName,
              emailAddress,
              currentUser.companyId,
              currentUser.companyName,
              currentUser.isCompanyHidden,
              responseBody.permissions,
              currentUser.isPackager
            )
          );
          navigate("/change-temp-password");
        } else {
          dispatch(
            setCurrentUser(
              responseBody.userId,
              responseBody.userName,
              responseBody.emailAddress,
              responseBody.companyId,
              responseBody.companyName,
              responseBody.isCompanyHidden,
              responseBody.permissions,
              currentUser.isPackager
            )
          );

          // If we used a link before getting the change password page, redirect to the link.
          if (props.redirectUrl.length > 0) {
            const redirectUrl = props.redirectUrl;
            props.onRedirect();
            navigate(redirectUrl);
          } else {
            navigate("/");
          }
        }
      } else {
        setErrorMessage(await getApiError(response, "Unable to complete login."));
      }
    }
  }

  return (
    <div className="p-4">
      <Spinner loading={loading} />

      <div className={`${styles.body} mx-auto`} onKeyDown={(e) => checkPressEnter(e)}>
        <Card title="Change Password">
          <div className="mx-4 my-3">
            <PasswordRequirements />

            <div className="mb-4">
              <label className="font-weight-bold mt-2">New Password</label>
              <PasswordInput password={newPassword} onChangePassword={(newPassword) => setNewPassword(newPassword)} />
            </div>

            <div className="mb-4">
              <label className="font-weight-bold mt-2">Confirm New Password</label>
              <PasswordInput
                password={confirmPassword}
                onChangePassword={(confirmPassword) => setConfirmPassword(confirmPassword)}
              />
            </div>

            <div className="text-center my-4">
              <button
                type="submit"
                id="submit-login"
                className={`${styles.changePasswordButton} btn btn-primary`}
                onClick={() => changePassword()}
              >
                Save Password
              </button>
            </div>

            <Error message={errorMessage} />
          </div>
        </Card>
      </div>
    </div>
  );
}

ChangeTemporaryPasswordPage.propTypes = {
  redirectUrl: PropTypes.string.isRequired,
  onRedirect: PropTypes.func.isRequired,
};

interface Props {
  redirectUrl: string;
  onRedirect: () => void;
}

interface ResponseBody {
  token: string;
  accessToken: string;
  newPasswordRequired: boolean;
  userId: number;
  userName: string;
  emailAddress: string;
  companyId: number;
  companyName: string;
  isCompanyHidden: boolean;
  permissions: string[];
}
