// --------------------------------------------------------------
// Created On: 2024-12-11
// Author: Dimitra Weinstein
//
// Last Modified: 2025-01-07
// Modified By: Dimitra Weinstein
//
// Copyright 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./SuperAdminReportDateSelector.module.scss";

// Report form date selector.
export default function SuperAdminReportDateSelector(props: Props): Component {
  return (
    <Fragment>
      <div className="row mb-4">
        <div className="col">
          {/* Start Date */}
          <div className="row p-2">
            <label className="form-label">Start Date</label>
            {props.dateRangeIsChecked ? (
              <input
                className={`p-1 ${!props.startDateIsValid || !props.dateRangeIsValid ? styles.inputError : ""}`}
                type="date"
                value={props.startDate !== null ? props.startDate : ""}
                onChange={(e) => props.onStartDateChange(e.target.value)}
              />
            ) : (
              <input className="p-1" type="date" value={props.startDate !== null ? props.startDate : ""} disabled />
            )}
          </div>
          {/* Error Tooltip for start date */}
          {(!props.startDateIsValid || !props.dateRangeIsValid) && (
            <div className="col-12 col-lg-9">
              <span className={styles.errorTooltip}>
                {props.startDateErrorMessage !== "" ? props.startDateErrorMessage : props.errorMessage}
              </span>
            </div>
          )}
        </div>
        <div className="col">
          {/* End Date */}
          <div className="row p-2">
            <label className="form-label">End Date</label>
            {props.dateRangeIsChecked ? (
              <input
                className={`p-1 ${!props.endDateIsValid || !props.dateRangeIsValid ? styles.inputError : ""}`}
                type="date"
                value={props.endDate !== null ? props.endDate : ""}
                onChange={(e) => props.onEndDateChange(e.target.value)}
              />
            ) : (
              <input className="p-1" type="date" value={props.endDate !== null ? props.endDate : ""} disabled />
            )}
          </div>
          {/* Error Tooltip for end date */}
          {(!props.endDateIsValid || !props.dateRangeIsValid) && (
            <div className="col-12 col-lg-9">
              <span className={styles.errorTooltip}>
                {props.endDateErrorMessage !== "" ? props.endDateErrorMessage : props.errorMessage}
              </span>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

SuperAdminReportDateSelector.propTypes = {
  dateRangeIsChecked: PropTypes.bool.isRequired,
  dateRangeIsValid: PropTypes.bool.isRequired,
  startDateIsValid: PropTypes.bool.isRequired,
  endDateIsValid: PropTypes.bool.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  errorMessage: PropTypes.string.isRequired,
  startDateErrorMessage: PropTypes.string.isRequired,
  endDateErrorMessage: PropTypes.string.isRequired,
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
};

interface Props {
  dateRangeIsChecked: boolean;
  dateRangeIsValid: boolean;
  startDateIsValid: boolean;
  endDateIsValid: boolean;
  startDate: string | null;
  endDate: string | null;
  errorMessage: string;
  startDateErrorMessage: string;
  endDateErrorMessage: string;
  onStartDateChange: (startDate: string) => void;
  onEndDateChange: (endDate: string) => void;
}
