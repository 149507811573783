// --------------------------------------------------------------
// Created On: 2021-05-19
// Author: Zachary Thomas
//
// Last Modified: 2025-01-14
// Modified By: Zachary Thomas
//
// Copyright 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Takes a string in PascalCase and converts it to a string in Title Case.
// (ex: "AlertThreshold" => "Alert Threshold").
export default function pascalCaseToTitleCase(string: string): string {
  if (countUpperCaseCharacters(string) >= 2) {
    // The string replace finds any uppercase letter and adds a space before it.
    string = string.replace(/([A-Z])/g, " $1");
    // Remove the initial extra space.
    string = string.slice(1);
  }
  return string;
}

// Counts the number of upper case characters in a string.
function countUpperCaseCharacters(string: string): number {
  let count = 0;

  for (let i = 0; i < string.length; i++) {
    const char = string[i];
    if (char >= "A" && char <= "Z") {
      count++;
    }
  }

  return count;
}
