// --------------------------------------------------------------
// Created On: 2021-09-10
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useState, useEffect } from "react";
import AssetDiagramNode from "./DiagramNode/DiagramNode";
import PropTypes from "prop-types";
import styles from "./AssetgroupDiagram.module.scss";

// An interactive diagram that shows children of the root asset group.
export default function AssetgroupDiagram(props: Props): Component {
  const [tree, setTree] = useState<DiagramTree | null>(null);

  // Convert the associate structure into a simple tree.
  useEffect(() => {
    setTree(assetgroupToDiagramNode(props.assetgroup, "root", 0, props.display));
  }, [props.display, JSON.stringify(props.assetgroup)]);

  // Converts an asset group to a diagram node.
  function assetgroupToDiagramNode(assetgroup: Assetgroup, path: string, depth: number, display: string): DiagramTree {
    const parentNode: DiagramTree = {
      id: assetgroup.assetgroupId,
      name: assetgroup.name,
      path: `${path}-${assetgroup.assetgroupId}ag`,
      depth: depth + 1,
      type: "parent",
      linkable: path !== "root",
      children: [],
    };

    if (display === "assets") {
      assetgroup.assetgroups.forEach((assetgroup) => {
        const childNode = assetgroupToDiagramNode(assetgroup, parentNode.path, parentNode.depth, "assets");
        parentNode.children.push(childNode);
      });

      assetgroup.assets.forEach((asset) => {
        const childNode = assetToDiagramNode(asset, parentNode.path, parentNode.depth);
        parentNode.children.push(childNode);
      });
    } else if (display === "users") {
      assetgroup.usergroups.forEach((usergroup) => {
        const childNode = usergroupToDiagramNode(usergroup, parentNode.path, parentNode.depth);
        parentNode.children.push(childNode);
      });
    }

    return parentNode;
  }

  // Converts a user group to a diagram node.
  function usergroupToDiagramNode(usergroup: Usergroup, path: string, depth: number): DiagramTree {
    const parentNode: DiagramTree = {
      id: usergroup.usergroupId,
      name: usergroup.name,
      path: `${path}-${usergroup.usergroupId}ug`,
      depth: depth + 1,
      type: "parent",
      linkable: false,
      children: [],
    };

    usergroup.users.forEach((user) => {
      const childNode = userToDiagramNode(user, parentNode.path, parentNode.depth);
      parentNode.children.push(childNode);
    });

    return parentNode;
  }

  // Converts an asset to a diagram node.
  function assetToDiagramNode(asset: Asset, path: string, depth: number): DiagramTree {
    return {
      id: asset.assetId,
      name: asset.name,
      path: `${path}-${asset.assetId}a`,
      depth: depth + 1,
      type: "child",
      linkable: false,
      children: [],
    };
  }

  // Converts a user to a diagram node.
  function userToDiagramNode(user: User, path: string, depth: number): DiagramTree {
    return {
      id: user.userId,
      name: user.name,
      path: `${path}-${user.userId}u`,
      depth: depth + 1,
      type: "child",
      linkable: false,
      children: [],
    };
  }

  return (
    <Fragment>
      {tree !== null && tree.children.length > 0 ? (
        <div
          data-test="asset-group-diagram"
          className={`${styles.body} row justify-content-center d-none d-md-flex mx-2 px-2 py-4`}
        >
          <AssetDiagramNode
            node={tree}
            onClick={(selectedId: number, type: string) => props.onClick(selectedId, type)}
          />
        </div>
      ) : (
        <div className={`${styles.body} row justify-content-center d-none d-md-flex mx-2 px-2 py-4`}>
          <div>
            <span className={styles.message}>
              {`No associated ${
                props.display === "assets" ? "assets or asset groups" : "users or user groups"
              } to display`}
            </span>
          </div>
        </div>
      )}
    </Fragment>
  );
}

AssetgroupDiagram.propTypes = {
  assetgroup: PropTypes.object.isRequired,
  display: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

interface Props {
  assetgroup: Assetgroup;
  display: "assets" | "users";
  onClick: (selectedId: number, type: string) => void;
}

interface Assetgroup {
  assetgroupId: number;
  name: string;
  isDefault: boolean;
  assetgroups: Assetgroup[];
  usergroups: Usergroup[];
  assets: Asset[];
}

interface Asset {
  assetId: number;
  name: string;
  nickname: string;
  isRented?: boolean;
  isMigrating?: boolean;
  lastConfigSuccessful?: boolean;
  productManufacturer?: string;
  productModel?: string;
  productType?: string;
}

interface Usergroup {
  usergroupId: number;
  name: string;
  isDefault: boolean;
  operateAccessPermission: boolean;
  users: User[];
}

interface User {
  userId: number;
  name: string;
  emailAddress: string;
  roleId: number;
}

interface DiagramTree {
  id: number;
  name: string;
  path: string;
  depth: number;
  type: string;
  linkable: boolean;
  children: DiagramTree[];
}
