// --------------------------------------------------------------
// Created On: 2022-10-17
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { MAX_ATTRIBUTE_ALIAS_LENGTH } from "../../../constants/miscellaneous";
import styles from "./AttributeAlias.module.scss";

// Form to allow setting an alias name and unit for an attribute.
export default function AttributeAlias(props: Props): Component {
  const [aliasName, setAliasName] = useState<string>("");
  const [unitId, setUnitId] = useState<number | null>(null);

  // If the passed in unit ID or alias changes, then update the state.
  useEffect(() => {
    if (props.aliasName === null) {
      setAliasName("");
    } else {
      setAliasName(props.aliasName);
    }
    setUnitId(props.unitId);
  }, [JSON.stringify(props.unitId), JSON.stringify(props.aliasName)]);

  // Check if an attribute has changed and is ready to be saved.
  function attributeChanged(): boolean {
    let previousAliasName = "";
    if (props.aliasName !== null && props.aliasName !== undefined) {
      previousAliasName = props.aliasName;
    }
    let previousUnitId = null;
    if (props.unitId !== null && props.unitId !== undefined) {
      previousUnitId = props.unitId;
    }
    return previousAliasName !== aliasName || previousUnitId !== unitId;
  }

  return (
    <tr data-test="attribute-row" className={styles.body}>
      <td>{props.name}</td>
      <td>
        <input
          data-test="attribute-alias-input"
          className="form-control mx-auto"
          type="text"
          maxLength={MAX_ATTRIBUTE_ALIAS_LENGTH}
          value={aliasName}
          disabled={!props.isAliasAllowed}
          onChange={(e) => setAliasName(e.target.value)}
        />
      </td>
      <td>
        <select
          data-test="attribute-unit-select"
          className="form-select"
          disabled={props.selectableUnits.length === 0 || !props.isAliasAllowed}
          value={unitId || 0}
          onChange={(e) => setUnitId(parseInt(e.target.value, 10))}
        >
          {props.selectableUnits.length === 0 && <option value="0">N/A</option>}

          {props.selectableUnits.map((unit) => (
            <option key={unit.unitId} value={unit.unitId}>
              {unit.unitName}
            </option>
          ))}
        </select>
      </td>
      <td>
        <button
          data-test="attribute-save-changes-button"
          className={`${styles.button} ${attributeChanged() ? "" : styles.disabled} btn btn-primary w-100`}
          type="button"
          disabled={!attributeChanged()}
          onClick={() => props.onSave(props.attributeId, aliasName, unitId)}
        >
          <span className="d-none d-md-inline">Save Changes</span>
          <span className="d-inline d-md-none">Save</span>
        </button>
      </td>
    </tr>
  );
}

AttributeAlias.propTypes = {
  attributeId: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  aliasName: PropTypes.string,
  isAliasAllowed: PropTypes.bool.isRequired,
  unitId: PropTypes.number,
  unitName: PropTypes.string,
  selectableUnits: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
};

interface Props {
  attributeId: number;
  code: string;
  name: string;
  aliasName: string | null;
  isAliasAllowed: boolean;
  unitId: number | null;
  unitName: string | null;
  selectableUnits: UnitAlias[];
  onSave: (attributeId: number, aliasName: string, unitId: number | null) => void;
}

interface UnitAlias {
  unitId: number;
  unitName: string;
}
