// --------------------------------------------------------------
// Created On: 2022-05-02
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useEffect } from "react";
import Spinner from "../../components/Spinner/Spinner";
import { useDispatch } from "react-redux";
import { setCurrentUser, setAuthorizerToken, setAccessToken } from "../../redux/actions";

// Page that just attempts to load information stored in local storage into Redux for the mobile app.
export default function MobileAppLoadingPage(): Component {
  const dispatch = useDispatch();

  // Attempt to load local storage on initial page load.
  useEffect(() => {
    try {
      const authorizerToken = localStorage.getItem("reduxAuthorizerToken");
      console.log(`Authorizer token loaded from local storage: ${authorizerToken}`);
      const accessToken = localStorage.getItem("reduxAccessToken");
      console.log(`Access token loaded from local storage: ${accessToken}`);
      const currentUser = localStorage.getItem("reduxCurrentUser");
      console.log(`Current user loaded from local storage: ${currentUser}`);

      if (authorizerToken !== null) {
        dispatch(setAuthorizerToken(authorizerToken));
        console.log(`Redux authorizer token loaded: ${authorizerToken}`);
      }

      if (accessToken !== null) {
        dispatch(setAccessToken(accessToken));
        console.log(`Redux access token loaded: ${accessToken}`);
      }

      if (currentUser !== null) {
        const parsedCurrentUser: CurrentUser = JSON.parse(currentUser) as CurrentUser;
        dispatch(
          setCurrentUser(
            parsedCurrentUser.userId,
            parsedCurrentUser.userName,
            parsedCurrentUser.emailAddress,
            parsedCurrentUser.companyId,
            parsedCurrentUser.companyName,
            parsedCurrentUser.isCompanyHidden,
            parsedCurrentUser.permissions,
            parsedCurrentUser.isPackager
          )
        );
        console.log(`Redux current user loaded: ${accessToken}`);
      }
    } catch (e) {
      console.error(e);
      console.error("Redux state was unable to loaded from mobile app loading page.");
    }
  }, []);
  return <Spinner loading={true} />;
}
