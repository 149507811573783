// --------------------------------------------------------------
// Created On: 2023-02-19
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import SearchAssetListRow from "./SearchAssetListRow/SearchAssetListRow";
import { MAX_RESULTS_PER_PAGE } from "../../../constants/miscellaneous";
import PaginationControl from "../../../components/PaginationControl/PaginationControl";
import SortedTableHeader from "../../../components/SortedTableHeader/SortedTableHeader";
import deepCopy from "../../../utilities/deepCopy";
import styles from "./SearchAssetList.module.scss";

// A list of interactive link assets that were the result of a search.
export default function SearchAssetList(props: Props): Component {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [sortAscending, setSortAscending] = useState<boolean>(false);
  const [sortColumnIndex, setSortColumnIndex] = useState<number>(1);

  // Get the assets that exist just on the current page.
  useEffect(() => {
    const startingIndex = pageNumber * MAX_RESULTS_PER_PAGE - MAX_RESULTS_PER_PAGE;
    let endingIndex = pageNumber * MAX_RESULTS_PER_PAGE;
    if (endingIndex >= props.assets.length) {
      endingIndex = props.assets.length;
    }
    const assetsDeepCopy = deepCopy(props.assets);
    const sortedAssets = sortAssets(assetsDeepCopy);
    setAssets(sortedAssets.slice(startingIndex, endingIndex));
  }, [pageNumber, JSON.stringify(props.assets), sortAscending, sortColumnIndex]);

  // If the search results change, reset the selected page to page one.
  useEffect(() => {
    setPageNumber(1);
  }, [JSON.stringify(props.assets)]);

  // If the results change to/from cross company, change the starting sort index.
  useEffect(() => {
    if (props.crossCompany) {
      setSortColumnIndex(1);
      setSortAscending(false);
    } else {
      setSortColumnIndex(2);
      setSortAscending(false);
    }
  }, [props.crossCompany]);

  // Update sorting order.
  function updateSort(newIndex: number): void {
    if (newIndex === sortColumnIndex) {
      setSortAscending(!sortAscending);
    } else {
      setSortAscending(false);
      setSortColumnIndex(newIndex);
    }
  }

  // Sort asset search results based on the current column and ascension rule.
  function sortAssets(assets: Asset[]): Asset[] {
    return assets.sort((a, b) => {
      let aValue = "";
      let bValue = "";

      switch (sortColumnIndex) {
        case 1:
          aValue = a.companyName;
          bValue = b.companyName;
          break;
        case 2:
          aValue = a.name;
          bValue = b.name;
          break;
        case 3:
          aValue = a.nickname;
          bValue = b.nickname;
          break;
        case 4:
          aValue = a.productType;
          bValue = b.productType;
          break;
        case 5:
          aValue = a.productModel;
          bValue = b.productModel;
          break;
        case 6:
          aValue = a.productIdentifier;
          bValue = b.productIdentifier;
          break;
        default:
          aValue = a.companyName;
          bValue = b.companyName;
      }

      if (aValue < bValue) {
        if (sortAscending) {
          return -1;
        } else {
          return 1;
        }
      } else if (aValue > bValue) {
        if (sortAscending) {
          return 1;
        } else {
          return -1;
        }
      } else {
        return 0;
      }
    });
  }

  return !props.loading ? (
    <Fragment>
      {props.assets.length > 0 ? (
        <div>
          <div className="table-responsive">
            <table className={`${styles.table} table mb-0 pb-0`}>
              <thead className={styles.thead}>
                <tr>
                  {props.crossCompany && (
                    <SortedTableHeader
                      name="Company"
                      index={1}
                      selectedIndex={sortColumnIndex}
                      selectedAscending={sortAscending}
                      onClick={(index) => updateSort(index)}
                    />
                  )}
                  <SortedTableHeader
                    name="Name"
                    index={2}
                    selectedIndex={sortColumnIndex}
                    selectedAscending={sortAscending}
                    onClick={(index) => updateSort(index)}
                  />
                  <SortedTableHeader
                    name="Nickname"
                    index={3}
                    selectedIndex={sortColumnIndex}
                    selectedAscending={sortAscending}
                    onClick={(index) => updateSort(index)}
                  />
                  <SortedTableHeader
                    name="Product Type"
                    index={4}
                    selectedIndex={sortColumnIndex}
                    selectedAscending={sortAscending}
                    className="d-none d-xl-table-cell"
                    onClick={(index) => updateSort(index)}
                  />
                  <SortedTableHeader
                    name="Product Part Number"
                    index={5}
                    selectedIndex={sortColumnIndex}
                    selectedAscending={sortAscending}
                    className="d-none d-lg-table-cell"
                    onClick={(index) => updateSort(index)}
                  />
                  <SortedTableHeader
                    name="Product Serial Number"
                    index={6}
                    selectedIndex={sortColumnIndex}
                    selectedAscending={sortAscending}
                    className="d-none d-md-table-cell"
                    onClick={(index) => updateSort(index)}
                  />
                </tr>
              </thead>
              <tbody>
                {assets.map((asset) => (
                  <SearchAssetListRow
                    key={`${asset.assetId}-${asset.companyId}`}
                    assetId={asset.assetId}
                    name={asset.name}
                    nickname={asset.nickname}
                    productType={asset.productType}
                    productModel={asset.productModel}
                    productIdentifier={asset.productIdentifier}
                    companyId={asset.companyId}
                    companyName={asset.companyName}
                    crossCompany={props.crossCompany}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <PaginationControl
            pageNumber={pageNumber}
            maxPerPage={MAX_RESULTS_PER_PAGE}
            totalCount={props.assets.length}
            onClick={(pageNumber) => setPageNumber(pageNumber)}
          />
        </div>
      ) : (
        <div className={`${styles.emptyList} py-5 px-4`}>
          <h5 className="my-0">No assets found</h5>
        </div>
      )}
    </Fragment>
  ) : null;
}

SearchAssetList.propTypes = {
  loading: PropTypes.bool.isRequired,
  assets: PropTypes.array.isRequired,
  crossCompany: PropTypes.bool.isRequired,
};

interface Props {
  loading: boolean;
  assets: Asset[];
  crossCompany: boolean;
}

interface Asset {
  assetId: number;
  name: string;
  nickname: string;
  productType: string;
  productModel: string;
  productIdentifier: string;
  companyId: number;
  companyName: string;
}
