// --------------------------------------------------------------
// Created On: 2025-01-21
// Author: Zachary Thomas
//
// Last Modified: 2025-01-21
// Modified By: Zachary Thomas
//
// Copyright 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Constants related to super admin report types.
export const SUPER_ADMIN_REPORT_TYPES = [
  { code: "Asset", description: "Assets" },
  { code: "User", description: "Users" },
  { code: "AlertThreshold", description: "Total alert count by attribute and comparison" },
  { code: "AlertCompany", description: "Total alert count by company" },
  { code: "Overview", description: "Total company, user, asset, and alert counts" },
  { code: "Company", description: "Total users and assets by company" },
];
