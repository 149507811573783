// --------------------------------------------------------------
// Created On: 2024-12-05
// Author: Hannah Vaughan
//
// Last Modified: 2025-01-03
// Modified By: Zachary Thomas
//
// Copyright 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Spinner from "../../../components/Spinner/Spinner";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import { API, MAX_ACCOUNT_NAME_LENGTH, MAX_COMPANY_NAME_LENGTH } from "../../../constants/miscellaneous";
import IconTooltip from "../../../components/IconTooltip/IconTooltip";
import SortedTableHeader from "../../../components/SortedTableHeader/SortedTableHeader";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import SaveChangesModal from "../../../components/SaveChangesModal/SaveChangesModal";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import apiRequest from "../../../utilities/api/apiRequest";
import getApiError from "../../../utilities/api/getApiError";
import useApi from "../../../hooks/useApi";
import Error from "../../../components/Error/Error";
import deepCopy from "../../../utilities/deepCopy";
import FilteredDevicesTableContainer from "../FilteredDevicesTableContainer/FilteredDevicesTableContainer";
import { COMPANY_TYPES } from "../../../constants/reducerActions";

// Modal for creating, editing, and deleting companies and their associated accounts and devices.
export default function CompanyModal(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [previousName, setPreviousName] = useState<string>("");
  const [isPackager, setIsPackager] = useState<boolean>(false);
  const [previousIsPackager, setPreviousIsPackager] = useState<boolean>(false);
  const [isMigrating, setIsMigrating] = useState<boolean>(false);
  const [previousIsMigrating, setPreviousIsMigrating] = useState<boolean>(false);
  const [isTest, setIsTest] = useState<boolean>(false);
  const [previousIsTest, setPreviousIsTest] = useState<boolean>(false);
  const [accountId, setAccountId] = useState<number | null>(null);
  const [previousAccountId, setPreviousAccountId] = useState<number | null>(null);
  const [accountName, setAccountName] = useState<string>("");
  const [ownedDevices, setOwnedDevices] = useState<Device[]>([]);
  const [previousOwnedDevices, setPreviousOwnedDevices] = useState<Device[]>([]);
  const [devicesFilter, setDevicesFilter] = useState<string>("");
  const [sortColumnIndex, setSortColumnIndex] = useState<number>(1);
  const [sortAscending, setSortAscending] = useState<boolean>(true);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [showConfirmExit, setShowConfirmExit] = useState<boolean>(false);
  const [showNewDeviceForm, setShowNewDeviceForm] = useState<boolean>(false);
  const [deviceTypeId, setDeviceTypeId] = useState<number>(-1);
  const [deviceIdentifier, setDeviceIdentifier] = useState<string>("");
  const organizedOwnedDevices = useMemo(
    () => getFilteredAndSortedOwnedDevices(ownedDevices),
    [JSON.stringify(ownedDevices), devicesFilter, sortAscending, sortColumnIndex]
  );

  // Get the company's information if the company already exists.
  useApi(
    () => {
      setLoading(props.companyId > 0);
      return props.companyId > 0;
    },
    {
      method: "GET",
      url: `${API}/company/${props.companyId}`,
    },
    async (response: Response, responseBody: GetResponseBody) => {
      if (response.ok && responseBody) {
        setName(responseBody.companyName);
        setIsPackager(responseBody.isPackager);
        setIsMigrating(responseBody.isMigrating);
        setIsTest(responseBody.isTest);
        setAccountId(responseBody.accountId);
        setOwnedDevices(responseBody.ownedDevices);

        setPreviousName(responseBody.companyName);
        setPreviousIsPackager(responseBody.isPackager);
        setPreviousIsMigrating(responseBody.isMigrating);
        setPreviousIsTest(responseBody.isTest);
        setPreviousAccountId(responseBody.accountId);
        setPreviousOwnedDevices(responseBody.ownedDevices);

        setErrorMessage("");

        // Set the associated account name based on the provided account ID (if one exists).
        if (responseBody.accountId !== null) {
          const account = props.accounts.find((account) => account.accountId === responseBody.accountId);
          if (account !== undefined) {
            const accountName = `${account.name} (${account.identifier})`;
            setAccountName(accountName);
          }
        }
      } else {
        setErrorMessage(await getApiError(response, "Unable to get company settings."));
      }
      setLoading(false);
    },
    [props.companyId]
  );

  // Clears the company device form once the device has either been added or cleared.
  function clearCompanyDeviceForm(showNewDeviceForm: boolean): void {
    setShowNewDeviceForm(showNewDeviceForm);
    setErrorMessage("");
  }

  // Update sorting order.
  function updateSort(newIndex: number): void {
    if (newIndex === sortColumnIndex) {
      setSortAscending((prev) => !prev);
    } else {
      setSortAscending(false);
      setSortColumnIndex(newIndex);
    }
  }

  // Gets all owned devices that are filtered by identifier and are sorted.
  function getFilteredAndSortedOwnedDevices(ownedDevices: Device[]): Device[] {
    let ownedDevicesDeepCopy = deepCopy(ownedDevices);
    if (devicesFilter.length > 0) {
      ownedDevicesDeepCopy = ownedDevicesDeepCopy.filter(
        (device) => device.deviceIdentifier.toLowerCase().indexOf(devicesFilter.toLowerCase()) >= 0
      );
    }
    return sortOwnedDevices(ownedDevicesDeepCopy);
  }

  // Sort owned devices by device identifier.
  function sortOwnedDevicesByIdentifier(ownedDevices: Device[]): Device[] {
    return ownedDevices.sort((a, b) => {
      const aValue = `${a.deviceTypeId}|${a.deviceIdentifier}`;
      const bValue = `${b.deviceTypeId}|${b.deviceIdentifier}`;
      if (aValue < bValue) {
        return -1;
      } else if (aValue > bValue) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  // Sort owned devices based on the current column and ascension rule.
  function sortOwnedDevices(ownedDevices: Device[]): Device[] {
    return ownedDevices.sort((a, b) => {
      let aValue = "";
      let bValue = "";

      switch (sortColumnIndex) {
        case 1:
          aValue = props.deviceTypes.find((deviceType) => deviceType.deviceTypeId === a.deviceTypeId)?.name || "";
          bValue = props.deviceTypes.find((deviceType) => deviceType.deviceTypeId === b.deviceTypeId)?.name || "";
          break;
        case 2:
          aValue = a.deviceIdentifier;
          bValue = b.deviceIdentifier;
          break;
        default:
          aValue = a.deviceIdentifier;
          bValue = b.deviceIdentifier;
      }

      if (aValue < bValue) {
        return sortAscending ? -1 : 1;
      } else if (aValue > bValue) {
        return sortAscending ? 1 : -1;
      } else {
        return 0;
      }
    });
  }

  // Exit the modal if no changes have been made. Otherwise, prompt the user.
  function exitModal(): void {
    let ownedDevicesChanged = true;
    if (
      JSON.stringify(sortOwnedDevicesByIdentifier(ownedDevices)) ===
      JSON.stringify(sortOwnedDevicesByIdentifier(previousOwnedDevices))
    ) {
      ownedDevicesChanged = false;
    }

    if (
      name === previousName &&
      isPackager === previousIsPackager &&
      isMigrating === previousIsMigrating &&
      isTest === previousIsTest &&
      accountId === previousAccountId &&
      !ownedDevicesChanged
    ) {
      // Safely exit the modal since there have been no changes.
      discardChanges();
    } else {
      // Unsaved changes were found, so the user now needs a chance to save them.
      setShowConfirmExit(true);
    }
  }

  // Exit the modal without saving any changes.
  function discardChanges(): void {
    props.onClose();
    setShowConfirmDelete(false);
    setShowConfirmExit(false);
    setErrorMessage("");
  }

  // Save all changes made to the company and its devices.
  function saveChanges(): void {
    setShowConfirmExit(false);
    setErrorMessage("");
    if (props.isCreatingNewRecord) {
      void createCompany();
    } else {
      void editCompany(props.companyId);
    }
  }

  // Creates a new company.
  async function createCompany(): Promise<void> {
    if (companyIsValid()) {
      const requestBody = {
        companyName: name.trim(),
        isPackager: isPackager,
        isMigrating: isMigrating,
        isTest: isTest,
        accountId: accountId,
        ownedDevices: ownedDevices,
      };

      setLoading(true);
      const [response, responseBody] = (await apiRequest(`${API}/company`, "POST", requestBody)) as [
        Response,
        PostResponseBody
      ];
      setLoading(false);

      if (response.ok) {
        setPreviousName(name);
        setPreviousIsPackager(isPackager);
        setPreviousIsMigrating(isMigrating);
        setPreviousIsTest(isTest);
        setPreviousAccountId(accountId);

        // Reflect the change in the company reducer.
        const newCompany: Company = {
          companyId: responseBody.companyId,
          name: name,
          isPackager: isPackager,
          isMigrating: isMigrating,
          isTest: isTest,
        };
        props.onAction({
          type: COMPANY_TYPES.CREATE_COMPANY,
          payload: {
            company: newCompany,
          },
        });

        setShowConfirmExit(false);
        setErrorMessage("");
        props.onClose();
      } else {
        setErrorMessage(await getApiError(response, "Unable to create company."));
      }
    }
  }

  // Edits an existing company.
  async function editCompany(companyId: number): Promise<void> {
    if (companyIsValid()) {
      const requestBody = {
        companyName: name.trim(),
        isPackager: isPackager,
        isMigrating: isMigrating,
        isTest: isTest,
        accountId: accountId,
        ownedDevices: ownedDevices,
      };

      setLoading(true);
      const [response] = (await apiRequest(`${API}/company/${companyId}`, "PUT", requestBody)) as [
        Response,
        PutResponseBody
      ];
      setLoading(false);

      if (response.ok) {
        setPreviousName(name.trim());
        setPreviousIsPackager(isPackager);
        setPreviousIsMigrating(isMigrating);
        setPreviousIsTest(isTest);
        setPreviousAccountId(accountId);

        // Reflect the change in the company reducer.
        const updatedCompany: Company = {
          companyId: props.companyId,
          name: name,
          isPackager: isPackager,
          isMigrating: isMigrating,
          isTest: isTest,
        };
        props.onAction({
          type: COMPANY_TYPES.UPDATE_COMPANY,
          payload: {
            company: updatedCompany,
          },
        });

        setShowConfirmExit(false);
        setErrorMessage("");
        props.onClose();
      } else {
        setErrorMessage(await getApiError(response, "Unable to update company."));
      }
    }
  }

  // Deletes a company.
  async function deleteCompany(companyId: number): Promise<void> {
    setLoading(true);
    const [response] = (await apiRequest(`${API}/company/${companyId}`, "DELETE", null)) as [
      Response,
      DeleteResponseBody
    ];
    setLoading(false);

    if (response.ok) {
      discardChanges();
      props.onAction({
        type: COMPANY_TYPES.DELETE_COMPANY,
        payload: {
          companyId: companyId,
        },
      });
    } else {
      setShowConfirmDelete(false);
      setErrorMessage(await getApiError(response, "Unable to delete company."));
    }
  }

  // Checks if the current company name is valid (i.e. provided).
  function companyIsValid(): boolean {
    if (name.trim().length === 0) {
      setErrorMessage("A company is required to have a name.");
      return false;
    } else {
      return true;
    }
  }

  // Searches for a matching account.
  async function searchAccounts(filter: string): Promise<void> {
    setAccountName(filter);
    setErrorMessage("");
    const foundAccount = props.accounts.find((account) => filter === `${account.name} (${account.identifier})`);

    if (foundAccount === undefined) {
      setAccountId(null);
    } else {
      setAccountId(foundAccount.accountId);
    }
  }

  // Adds a device to the list of devices that the company owns.
  function addDevice(): void {
    const devicesDeepCopy = deepCopy(ownedDevices);

    // Check if the new device is a duplicate. Do not add it if it is already added.
    const existingDevice = ownedDevices.find(
      (device) => device.deviceTypeId === deviceTypeId && device.deviceIdentifier === deviceIdentifier
    );

    if (existingDevice !== undefined) {
      setErrorMessage("This company already owns this device.");
    } else if (deviceIdentifier.trim() === "") {
      setErrorMessage("Device identifiers cannot be left blank.");
    } else if (deviceTypeId === -1) {
      setErrorMessage("Please select a device type before adding the device to the company.");
    } else {
      setErrorMessage("");
      const newDevice: Device = {
        deviceTypeId: deviceTypeId,
        deviceIdentifier: deviceIdentifier,
      };
      devicesDeepCopy.push(newDevice);
      setOwnedDevices(devicesDeepCopy);
      setDeviceTypeId(-1);
      setDeviceIdentifier("");
      setShowNewDeviceForm(false);
    }
  }

  // Deletes a device from the list of devices that the company owns.
  function deleteDevice(id: number, identifier: string): void {
    const foundDevice = ownedDevices.find(
      (device) => device.deviceTypeId === id && device.deviceIdentifier === identifier
    );
    if (foundDevice !== undefined) {
      let devicesDeepCopy = deepCopy(ownedDevices);
      devicesDeepCopy = devicesDeepCopy.filter(
        (device) => device.deviceIdentifier !== identifier || device.deviceTypeId !== id
      );
      setOwnedDevices(devicesDeepCopy);
    }
  }

  return (
    <div>
      <Spinner loading={loading} />

      <Modal
        show={true}
        onHide={() => exitModal()}
        backdropClassName=""
        style={{ zIndex: "var(--modal-z-index)" }}
        size="xl"
        centered
        animation
      >
        <ModalHeader>
          <h5 className="font-weight-bold">{props.isCreatingNewRecord ? "Create Company" : "Edit Company"}</h5>
        </ModalHeader>

        <ModalBody>
          <div className="mx-2 mt-3">
            {/* Company Name. */}
            <label className="mb-3">Company Name</label>
            <input
              data-test="company-name-input"
              className="form-control mx-auto mb-4"
              type="text"
              maxLength={MAX_COMPANY_NAME_LENGTH}
              value={name}
              disabled={false}
              onChange={(e) => setName(e.target.value)}
            />

            {/* Checkboxes for Packager, Migrating, & Tester Options. */}
            <div className="my-4">
              <div className="form-check">
                <label>Packager&nbsp;</label>
                <IconTooltip
                  id="packager-tooltip"
                  icon="info-circle"
                  message={
                    "If a company is flagged as a packager, then that company cannot view the actual data being " +
                    "reported for assets (prevents them from tracking the location of assets via GPS). As a packager " +
                    "they are also required to pass a validation check before they are able to transfer any assets."
                  }
                  color="var(--info-tooltip)"
                />
                <input
                  data-test="company-is-packager-checkbox"
                  className="form-check-input me-3"
                  type="checkbox"
                  checked={isPackager}
                  onChange={() => setIsPackager((prev) => !prev)}
                />
              </div>
              <div className="form-check">
                <label>Migrating&nbsp;</label>
                <IconTooltip
                  id="migrating-tooltip"
                  icon="info-circle"
                  message={
                    "If a company is migrating, then when a new asset is created it will automatically be flagged " +
                    "as in migration. Assets that are in migration will not apply templates and configuration until " +
                    "they are manually taken out of migration."
                  }
                  color="var(--info-tooltip)"
                />
                <input
                  data-test="company-is-migrating-checkbox"
                  className="form-check-input me-3"
                  type="checkbox"
                  checked={isMigrating}
                  onChange={() => setIsMigrating((prev) => !prev)}
                />
              </div>
              <div className="form-check">
                <label>Tester&nbsp;</label>
                <IconTooltip
                  id="tester-tooltip"
                  icon="info-circle"
                  message={
                    "Tester companies do not show any of their information on reports that are generated from the " +
                    "super admin dashboard. For example, if you generate a report for the number of assets across " +
                    "all companies, if 100 assets exist in tester companies, then these assets will not show on the " +
                    "report. The same is true for users, companies, alerts, alert thresholds, etc."
                  }
                  color="var(--info-tooltip)"
                />
                <input
                  data-test="company-is-test-checkbox"
                  className="form-check-input me-3"
                  type="checkbox"
                  checked={isTest}
                  onChange={() => setIsTest((prev) => !prev)}
                />
              </div>
            </div>

            {/* Associated Account. */}
            <label className="mb-3">Associated Account&nbsp;</label>
            <IconTooltip
              id="associated-account-tooltip"
              icon="info-circle"
              message={
                "A company can optionally be associated with an Epicor account. A company associated with an " +
                "Epicor account will be granted ownership of all monitoring devices that are owned by that account."
              }
              color="var(--info-tooltip)"
            />
            <input
              data-test="associated-account-input"
              className="form-control mx-auto mb-4"
              type="text"
              list="datalist-accounts"
              autoComplete="off"
              maxLength={MAX_ACCOUNT_NAME_LENGTH}
              value={accountName}
              disabled={false}
              onChange={(e) => searchAccounts(e.target.value)}
            />
            <datalist id="datalist-accounts">
              {props.accounts.map((account) => (
                <option key={account.accountId} value={`${account.name} (${account.identifier})`} />
              ))}
            </datalist>

            {/* Associated Devices. */}
            <div>
              <FilteredDevicesTableContainer
                title={`Associated Devices (${organizedOwnedDevices.length})`}
                filterPrompt="Filter by identifier..."
                filter={devicesFilter}
                hasContent={ownedDevices.length > 0}
                hasFilteredContent={organizedOwnedDevices.length > 0}
                pluralContentType="devices"
                showNewDeviceForm={showNewDeviceForm}
                deviceTypes={props.deviceTypes}
                deviceTypeId={deviceTypeId}
                deviceIdentifier={deviceIdentifier}
                onChangeFilter={(filter) => setDevicesFilter(filter)}
                onChangeShowNewDeviceForm={(showNewDeviceForm) => setShowNewDeviceForm(showNewDeviceForm)}
                onClearCompanyDeviceForm={(showNewDeviceForm) => clearCompanyDeviceForm(showNewDeviceForm)}
                onChangeDeviceTypeId={(deviceTypeId) => setDeviceTypeId(deviceTypeId)}
                onChangeDeviceIdentifier={(deviceIdentifier) => setDeviceIdentifier(deviceIdentifier)}
                onAddDevice={() => addDevice()}
              >
                <table className="table table-hover mb-0 pb-0">
                  <thead>
                    <tr>
                      <SortedTableHeader
                        name="Device Type"
                        index={1}
                        selectedIndex={sortColumnIndex}
                        selectedAscending={sortAscending}
                        onClick={(index) => updateSort(index)}
                      />
                      <SortedTableHeader
                        name="Identifier"
                        index={2}
                        selectedIndex={sortColumnIndex}
                        selectedAscending={sortAscending}
                        onClick={(index) => updateSort(index)}
                      />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {organizedOwnedDevices.map((device, i) => (
                      <tr key={i}>
                        <td>
                          {props.deviceTypes.find((deviceType) => deviceType.deviceTypeId === device.deviceTypeId)
                            ?.name || ""}
                        </td>
                        <td>{device.deviceIdentifier}</td>
                        <td>
                          <button
                            data-test="device-delete-button"
                            className="btn btn-danger float-end me-3"
                            type="button"
                            onClick={() => deleteDevice(device.deviceTypeId, device.deviceIdentifier)}
                          >
                            <i className="d-inline fa fa-fw fa-times fa-xs" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </FilteredDevicesTableContainer>
            </div>

            {errorMessage.length > 0 && (
              <div className="mt-4">
                <Error message={errorMessage} />
              </div>
            )}
          </div>
        </ModalBody>

        <ModalFooter>
          {props.isCreatingNewRecord ? (
            <Fragment>
              <button
                data-test="company-modal-create-company-button"
                className="btn btn-primary"
                type="button"
                onClick={() => createCompany()}
              >
                Create Company
              </button>
              <button className="btn btn-secondary" type="button" onClick={() => exitModal()}>
                Cancel
              </button>
            </Fragment>
          ) : (
            <Fragment>
              <button
                data-test="company-modal-delete-company-button"
                className="btn btn-danger me-auto"
                type="button"
                onClick={() => setShowConfirmDelete(true)}
              >
                Delete Company
              </button>

              <button
                data-test="company-modal-save-change-button"
                className="btn btn-primary"
                type="button"
                onClick={() => void editCompany(props.companyId)}
              >
                Save Changes
              </button>

              <button
                data-test="company-modal-close-button"
                className="btn btn-secondary"
                type="button"
                onClick={() => exitModal()}
              >
                Close
              </button>
            </Fragment>
          )}
        </ModalFooter>
      </Modal>

      <ConfirmModal
        showModal={showConfirmDelete}
        title={`Delete '${name}'`}
        content={`Are you sure you want to delete the company '${name}'?`}
        yesText="Delete Company"
        noText="Cancel"
        danger={true}
        onClose={() => setShowConfirmDelete(false)}
        onYes={() => deleteCompany(props.companyId)}
        onNo={() => setShowConfirmDelete(false)}
      />

      <SaveChangesModal
        showModal={showConfirmExit}
        title="Changes have not been saved!"
        content="Are you sure that you want to exit without saving your changes?"
        onClose={() => setShowConfirmExit(false)}
        onSave={() => saveChanges()}
        onNoSave={() => discardChanges()}
      />
    </div>
  );
}

CompanyModal.propTypes = {
  isCreatingNewRecord: PropTypes.bool.isRequired,
  companyId: PropTypes.number.isRequired,
  accounts: PropTypes.any,
  deviceTypes: PropTypes.any,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
};

interface Props {
  isCreatingNewRecord: boolean;
  companyId: number;
  accounts: Account[];
  deviceTypes: DeviceType[];
  onClose: () => void;
  onAction: (action: Action) => void;
}

interface Company {
  companyId: number;
  name: string;
  isPackager: boolean;
  isMigrating: boolean;
  isTest: boolean;
}

interface Account {
  accountId: number;
  name: string;
  identifier: string;
}

interface DeviceType {
  deviceTypeId: number;
  name: string;
}

interface Device {
  deviceTypeId: number;
  deviceIdentifier: string;
}

interface GetResponseBody {
  companyName: string;
  isPackager: boolean;
  isMigrating: boolean;
  isTest: boolean;
  accountId: number | null;
  ownedDevices: Device[];
}

interface PostResponseBody {
  companyId: number;
}

interface PutResponseBody {
  companyId: number;
}

interface DeleteResponseBody {
  message: string;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  companies?: Company[];
  company?: Company;
  companyId?: number;
}
