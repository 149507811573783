// --------------------------------------------------------------
// Created On: 2021-09-21
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect, Fragment } from "react";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import SaveChangesModal from "../../../components/SaveChangesModal/SaveChangesModal";
import getApiError from "../../../utilities/api/getApiError";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import Error from "../../../components/Error/Error";
import Spinner from "../../../components/Spinner/Spinner";
import apiRequest from "../../../utilities/api/apiRequest";
import { API, MIN_FULL_NAME_LENGTH, MAX_FULL_NAME_LENGTH } from "../../../constants/miscellaneous";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import { USER_TYPES } from "../../../constants/reducerActions";
import userHasPermission from "../../../utilities/userHasPermission";
import PhoneNumberInput from "../../../components/PhoneNumberInput/PhoneNumberInput";
import { DELETE_USERS_PERMISSION, UPDATE_USERS_PERMISSION } from "../../../constants/permissions";
import styles from "./UserModal.module.scss";

// Modal for editing, and deleting users.
export default function UserModal(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [showConfirmExit, setShowConfirmExit] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [callingCode, setCallingCode] = useState<string>("1");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [roleId, setRoleId] = useState<number>(0);
  const currentUser = useSelector(getCurrentUser);

  // Update state when user prop changes.
  useEffect(() => {
    if (props.showModal) {
      setName(props.name);
      setRoleId(props.roleId);
      if (
        props.phoneNumberCountryCode !== null &&
        props.phoneNumberCountryCode !== undefined &&
        props.phoneNumber !== null &&
        props.phoneNumber !== undefined
      ) {
        setCallingCode(props.phoneNumberCountryCode);
        setPhoneNumber(props.phoneNumber);
      } else {
        setCallingCode("1");
        setPhoneNumber("");
      }
    }
  }, [props.showModal, props.name, props.roleId, props.phoneNumberCountryCode, props.phoneNumber]);

  // Validate the user settings.
  function userIsValid(): boolean {
    if (name.trim().length < MIN_FULL_NAME_LENGTH || name.trim().length > MAX_FULL_NAME_LENGTH) {
      setErrorMessage(
        `A user's name must be between ${MIN_FULL_NAME_LENGTH} and ${MAX_FULL_NAME_LENGTH} characters long.`
      );
      return false;
    } else {
      return true;
    }
  }

  // Edit a user.
  async function editUser(): Promise<void> {
    if (userIsValid()) {
      let requestPhoneNumber: string | null = null;
      let requestCallingCode: string | null = null;

      if (
        phoneNumber !== null &&
        phoneNumber !== undefined &&
        callingCode !== undefined &&
        callingCode !== null &&
        phoneNumber.length > 5
      ) {
        requestPhoneNumber = phoneNumber;
        requestCallingCode = callingCode;
      }

      const requestBody = {
        name: name.trim(),
        userState: "ENABLED",
        phoneNumberCountryCode: requestCallingCode,
        phoneNumber: requestPhoneNumber,
        roleId: roleId,
      };

      setLoading(true);
      const [response] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/user/${props.userId}`,
        "PUT",
        requestBody
      )) as [Response, PutResponseBody];
      setLoading(false);

      if (response.ok) {
        const user = {
          userId: props.userId,
          emailAddress: props.emailAddress,
          name: name,
          phoneNumberCountryCode: requestCallingCode,
          phoneNumber: requestPhoneNumber,
          roleId: roleId,
        };

        setErrorMessage("");
        props.onClose();

        props.onAction({
          type: USER_TYPES.UPDATE_USER,
          payload: {
            user: user,
          },
        });
      } else {
        setErrorMessage(await getApiError(response, "Unable to update user."));
      }
    }
  }

  // Delete a user.
  async function deleteUser(userId: number): Promise<void> {
    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/user/${props.userId}`,
      "DELETE",
      null
    )) as [Response, DeleteResponseBody];
    setLoading(false);

    if (response.ok) {
      discardChanges();
      props.onAction({
        type: USER_TYPES.DELETE_USER,
        payload: {
          userId: userId,
        },
      });
    } else {
      setShowConfirmDelete(false);
      setErrorMessage(await getApiError(response, "Unable to delete user."));
    }
  }

  // Exit modal if no changes have been made. Otherwise prompt user.
  function exitModal(): void {
    let previousCallingCode = "1";
    if (props.phoneNumberCountryCode !== null && props.phoneNumberCountryCode !== undefined) {
      previousCallingCode = props.phoneNumberCountryCode;
    }

    let previousPhoneNumber = "";
    if (props.phoneNumber !== null && props.phoneNumber !== undefined) {
      previousPhoneNumber = props.phoneNumber;
    }

    if (
      name === props.name &&
      roleId === props.roleId &&
      previousCallingCode === callingCode &&
      previousPhoneNumber === phoneNumber
    ) {
      // Since there have been no changes we can safely exit.
      discardChanges();
    } else {
      // We have unsaved changes, give the user a chance to save them.
      setShowConfirmExit(true);
    }
  }

  // Save changes.
  function saveChanges(): void {
    void editUser();
    setShowConfirmExit(false);
  }

  // Exit without saving changes.
  function discardChanges(): void {
    setShowConfirmExit(false);
    setShowConfirmDelete(false);
    setErrorMessage("");
    setCallingCode("1");
    setPhoneNumber("");
    props.onClose();
  }

  return (
    <div className="user-modal-container">
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => exitModal()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--modal-z-index)" }}
        size="lg"
        animation
      >
        <ModalHeader>
          <h5 className="font-weight-bold">
            <span>Edit User</span>
          </h5>
        </ModalHeader>

        <ModalBody>
          <div className={`${styles.emailAddress} mx-2 mb-2`}>{props.emailAddress}</div>

          <div className="mx-2 mt-3">
            <label className="mb-3">Name</label>
            <input
              data-test="user-modal-name-input"
              className="form-control mx-auto"
              type="text"
              maxLength={MAX_FULL_NAME_LENGTH}
              value={name}
              disabled={!userHasPermission([[UPDATE_USERS_PERMISSION]])}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="mx-2 mt-3">
            <PhoneNumberInput
              data-test="user-modal-phone-number-input"
              labelText="Phone Number"
              labelClassName="mb-3"
              phoneNumber={phoneNumber}
              callingCode={callingCode}
              onChangePhoneNumber={(phoneNumber) => setPhoneNumber(phoneNumber)}
              onChangeCallingCode={(callingCode) => setCallingCode(callingCode)}
            />
          </div>

          <div className="mx-2 mt-3">
            <label className="mb-3">Role</label>
            <select
              data-test="user-modal-role-select"
              className="form-select"
              value={roleId}
              disabled={!userHasPermission([[UPDATE_USERS_PERMISSION]])}
              onChange={(e) => setRoleId(parseInt(e.target.value, 10))}
            >
              {props.roles.map((role) => (
                <option key={role.roleId} value={role.roleId}>
                  {role.name}
                </option>
              ))}
            </select>
          </div>

          {errorMessage.length > 0 && (
            <div className="row">
              <div className="col mt-4 mx-2">
                <Error message={errorMessage} />
              </div>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <Fragment>
            {userHasPermission([[DELETE_USERS_PERMISSION]]) && (
              <button
                className={`${styles.button} btn btn-danger me-auto`}
                type="button"
                onClick={() => setShowConfirmDelete(true)}
              >
                Delete
              </button>
            )}

            {userHasPermission([[UPDATE_USERS_PERMISSION]]) && (
              <button
                data-test="user-modal-save-changes-button"
                className={`${styles.button} btn btn-primary`}
                type="button"
                onClick={() => editUser()}
              >
                Save Changes
              </button>
            )}

            <button
              data-test="user-modal-cancel-button"
              className={`${styles.button} btn btn-secondary`}
              type="button"
              onClick={() => exitModal()}
            >
              Cancel
            </button>
          </Fragment>
        </ModalFooter>
      </Modal>

      <ConfirmModal
        showModal={props.showModal && showConfirmDelete}
        title={`Delete '${name}'?`}
        content={`Are you sure that you want to delete the user '${name}'?`}
        yesText="Delete User"
        noText="Cancel"
        danger={true}
        onClose={() => setShowConfirmDelete(false)}
        onYes={() => deleteUser(props.userId)}
        onNo={() => setShowConfirmDelete(false)}
      />

      <SaveChangesModal
        showModal={props.showModal && showConfirmExit}
        title="Changes have not been saved!"
        content="Are you sure that you want to exit without saving your changes?"
        onClose={() => setShowConfirmExit(false)}
        onSave={() => saveChanges()}
        onNoSave={() => discardChanges()}
      />
    </div>
  );
}

UserModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  emailAddress: PropTypes.string.isRequired,
  phoneNumberCountryCode: PropTypes.string,
  phoneNumber: PropTypes.string,
  roleId: PropTypes.number.isRequired,
  roles: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

interface Props {
  showModal: boolean;
  userId: number;
  name: string;
  emailAddress: string;
  phoneNumberCountryCode: string | null;
  phoneNumber: string | null;
  roleId: number;
  roles: Role[];
  onClose: () => void;
  onAction: (action: Action) => void;
}

interface Role {
  roleId: number;
  name: string;
  description: string;
  immutable: boolean;
}

interface PutResponseBody {
  message: string;
}

interface DeleteResponseBody {
  message: string;
}

interface User {
  userId: number;
  name: string;
  emailAddress: string;
  phoneNumberCountryCode: string | null;
  phoneNumber: string | null;
  roleId: number;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  user?: User;
  userId?: number;
}
