// --------------------------------------------------------------
// Created On: 2021-08-15
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAuthorizerToken } from "../redux/selectors";
import { API_HEADERS } from "../constants/miscellaneous";
import isRealObject from "../utilities/isRealObject";

// Hook for making an API call.
export default function useApi<Type>(
  openingFunction: () => boolean,
  apiRequest: ApiRequest,
  closingFunction: (response: Response, responseBody: Type) => void,
  dependencies: unknown[]
): void {
  const authorizerToken = useSelector(getAuthorizerToken);
  const navigate = useNavigate();

  useEffect(() => {
    let ignore = false;
    const controller = new AbortController();

    // Attempt to get the response body.
    async function getResponseBody<Type>(response: Response): Promise<Type> {
      const json = await response.text();
      try {
        const object = JSON.parse(json) as Type;
        return object;
      } catch {
        return { error: "Response body does not contain valid JSON." } as Type;
      }
    }

    // Make API call.
    async function callApi(apiRequest: ApiRequest): Promise<void> {
      // Function to call before API call.
      // Only continue if the function returns true.
      let proceed = false;
      if (!ignore) {
        proceed = openingFunction();
      }

      if (!proceed) {
        return;
      }

      // Perform API call.
      // Takes 'method', 'url', 'body', and 'authorization'.
      let response: Response = new Response(null, { status: 500 });
      let clonedResponse: Response = new Response(null, { status: 500 });
      let responseBody = null;
      try {
        if (!ignore) {
          if (!apiRequest.method) {
            throw new Error("apiRequest must have a method");
          } else if (!apiRequest.url) {
            throw new Error("apiRequest must have a url");
          }

          // Check if there is a body object to send.
          if (apiRequest.body) {
            // Omit the request body log if it contains sensitive information.
            if (
              isRealObject(apiRequest.body) &&
              ("password" in (apiRequest.body as RealObject) ||
                "currentPassword" in (apiRequest.body as RealObject) ||
                "newPassword" in (apiRequest.body as RealObject))
            ) {
              if (!apiRequest.hideLog) {
                console.log(
                  `Request: ${apiRequest.method} ${apiRequest.url}`,
                  "[Body omitted to hide sensitive information]"
                );
              }
            } else if (!apiRequest.hideLog) {
              console.log(`Request: ${apiRequest.method} ${apiRequest.url}`, apiRequest.body);
            }

            response = await fetch(apiRequest.url, {
              signal: controller.signal,
              method: apiRequest.method,
              body: JSON.stringify(apiRequest.body),
              headers: {
                ...API_HEADERS,
                Authorization: authorizerToken,
              },
            });
          } else {
            // Don't include the body if there is no valid body to send.
            if (!apiRequest.hideLog) {
              console.log(`Request: ${apiRequest.method} ${apiRequest.url}`);
            }
            response = await fetch(apiRequest.url, {
              signal: controller.signal,
              method: apiRequest.method,
              headers: {
                ...API_HEADERS,
                Authorization: authorizerToken,
              },
            });
          }

          // Clone the response to allow other functions to read the response body stream if needed.
          clonedResponse = response.clone();

          if (!apiRequest.hideLog) {
            console.log(`Response:`, response);
          }
          responseBody = await getResponseBody(response);

          if (responseBody !== null && typeof responseBody === "object" && "accessToken" in responseBody) {
            if (!apiRequest.hideLog) {
              console.log("Response Body: [Body omitted to hide sensitive information]");
            }
          } else if (!apiRequest.hideLog) {
            console.log("Response Body:", responseBody);
          }
        }
      } catch (e) {
        if (e instanceof DOMException && !apiRequest.hideLog) {
          console.log("Response: HTTP request aborted.");
        } else if (!apiRequest.hideLog) {
          console.error(e);
        }
      }

      // Check if the response was a 401 status code.
      // If it was then we will redirect to the login page.
      if (response !== null && response !== undefined && response.status === 401) {
        console.log("An unauthorized request was made. Returning to login page.");
        navigate("/login/auth");
      } else {
        // Function to call after API call.
        if (!ignore) {
          closingFunction(clonedResponse, responseBody as Type);
        }
      }
    }

    void callApi(apiRequest);

    return () => {
      ignore = true;
      controller.abort();
    };
  }, dependencies);
}

interface ApiRequest {
  method: RequestMethod;
  url: string;
  body?: unknown;
  hideLog?: boolean;
}
