// --------------------------------------------------------------
// Created On: 2024-12-05
// Author: Hannah Vaughan
//
// Last Modified: 2025-01-02
// Modified By: Hannah Vaughan
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useReducer, useState } from "react";
import ResourceList from "../../components/ResourceList/ResourceList";
import CompanyListRow from "./CompanyListRow/CompanyListRow";
import useApi from "../../hooks/useApi";
import { API } from "../../constants/miscellaneous";
import CompanyModal from "./CompanyModal/CompanyModal";
import Error500Page from "../Error500Page/Error500Page";
import { COMPANY_TYPES } from "../../constants/reducerActions";
import deepCopy from "../../utilities/deepCopy";
import HelpCompany from "../../components/HelpCompany/HelpCompany";
import Spinner from "../../components/Spinner/Spinner";

// Page for super admins to create, manage, and delete companies and their associated accounts and devices.
export default function ManageCompaniesPage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>([]);
  const [companies, dispatch] = useReducer(companyReducer, []);

  // Get a list of non-deleted companies from the API.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/company/map`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        dispatch({
          type: COMPANY_TYPES.SET_COMPANIES,
          payload: {
            companies: responseBody.companies,
          },
        });
        setAccounts(responseBody.accounts);
        setDeviceTypes(responseBody.deviceTypes);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    []
  );

  // Company reducer.
  function companyReducer(state: Company[], action: Action): Company[] {
    switch (action.type) {
      case COMPANY_TYPES.SET_COMPANIES: {
        if (action.payload.companies !== undefined) {
          return action.payload.companies;
        } else {
          return [];
        }
      }

      case COMPANY_TYPES.CREATE_COMPANY: {
        let stateDeepCopy = deepCopy(state);
        const newCompany = action.payload.company;
        if (newCompany !== undefined) {
          // Check if the company already exists before creating it.
          const companyExists = stateDeepCopy.some((company) => company.companyId === newCompany.companyId);
          if (!companyExists) {
            stateDeepCopy.push(newCompany);
            stateDeepCopy = sortCompanies(stateDeepCopy);
          }
        }
        return stateDeepCopy;
      }

      case COMPANY_TYPES.UPDATE_COMPANY: {
        let stateDeepCopy = deepCopy(state);
        const updatedCompany = action.payload.company;
        if (updatedCompany !== undefined) {
          // Find the index of the company.
          const companyIndex = stateDeepCopy.findIndex((company) => company.companyId === updatedCompany.companyId);

          // Do not continue if the company could not be found.
          if (companyIndex === -1) {
            return stateDeepCopy;
          }

          // Replace the current company with the updated one.
          stateDeepCopy.splice(companyIndex, 1, updatedCompany);

          // Sort the companies.
          stateDeepCopy = sortCompanies(stateDeepCopy);
        }
        return stateDeepCopy;
      }

      case COMPANY_TYPES.DELETE_COMPANY: {
        const stateDeepCopy = deepCopy(state);
        const companyId = action.payload.companyId;

        if (companyId !== undefined) {
          // Find the index of the company.
          const companyIndex = stateDeepCopy.findIndex((company) => company.companyId === companyId);

          // Do not continue if the company could not be found.
          if (companyIndex === -1) {
            return stateDeepCopy;
          }

          // Delete the current company.
          stateDeepCopy.splice(companyIndex, 1);
        }
        return stateDeepCopy;
      }

      default: {
        return state;
      }
    }
  }

  // Sorts companies.
  function sortCompanies(companies: Company[]): Company[] {
    return companies.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="p-4">
      <Spinner loading={loading} />

      <div className="row">
        <ResourceList
          resourceNameSingular="Company"
          resourceNamePlural="Companies"
          resourceArticle="a"
          headerButtonLabel="Create Company"
          headerButtonLabelSmall="Create"
          headerButtonUserPermissions={[[]]}
          resourceIdKey="companyId"
          resourcePriorityKey="packager"
          resources={companies}
          resourceRow={CompanyListRow}
          helpModal={<HelpCompany />}
          filterKeys={["name"]}
          loading={loading}
          onClickHeaderButton={() => setSelectedId(0)}
          onSelect={(selectedId) => setSelectedId(selectedId)}
        />

        {selectedId >= 0 && (
          <CompanyModal
            isCreatingNewRecord={selectedId === 0}
            companyId={selectedId}
            accounts={accounts}
            deviceTypes={deviceTypes}
            onClose={() => setSelectedId(-1)}
            onAction={(action) => dispatch(action)}
          />
        )}

        <datalist id="datalist-companies">
          {companies.map((company) => (
            <option key={company.companyId} value={company.name} />
          ))}
        </datalist>
      </div>
    </div>
  );
}

interface ResponseBody {
  companies: Company[];
  accounts: Account[];
  deviceTypes: DeviceType[];
}

interface Company {
  companyId: number;
  name: string;
  isPackager: boolean;
  isMigrating: boolean;
  isTest: boolean;
}

interface Account {
  accountId: number;
  name: string;
  identifier: string;
}

interface DeviceType {
  deviceTypeId: number;
  name: string;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  companies?: Company[];
  company?: Company;
  companyId?: number;
}
