// --------------------------------------------------------------
// Created On: 2022-05-26
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Checks the contents of a response and if the status code is in a valid range for a bad request (400 - 499)
// returns the error message from the response body. Otherwise returns a generic 'Internal server error' message.
export default async function getApiError(response: Response, defaultError: string): Promise<string> {
  const DEFAULT_ERROR_MESSAGE = `Internal server error. ${defaultError}`;

  // Attempt to get a response body from the response.
  let responseBody = null;
  try {
    const responseText = await response.text();
    responseBody = JSON.parse(responseText) as unknown;
  } catch {
    return DEFAULT_ERROR_MESSAGE;
  }

  // Only attempt to find error messages for status codes that don't fall in the correct range.
  if (response.status < 400 || response.status > 499) {
    return DEFAULT_ERROR_MESSAGE;
  } else if (
    typeof responseBody === "object" &&
    !Array.isArray(responseBody) &&
    responseBody !== null &&
    "error" in responseBody &&
    typeof (responseBody as ErrorObject).error === "string"
  ) {
    return (responseBody as ErrorObject).error;
  } else {
    return DEFAULT_ERROR_MESSAGE;
  }
}

interface ErrorObject {
  error: string;
}
