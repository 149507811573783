// --------------------------------------------------------------
// Created On: 2023-11-22
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState } from "react";
import useApi from "../../hooks/useApi";
import { API } from "../../constants/miscellaneous";
import Spinner from "../../components/Spinner/Spinner";
import ResourceList from "../../components/ResourceList/ResourceList";
import Error500Page from "../Error500Page/Error500Page";
import AssetgroupListRow from "./AssetgroupListRow/AssetgroupListRow";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import { MANAGE_MULTIPLE_ALERT_THRESHOLDS_PERMISSION } from "../../constants/permissions";
import HelpAlertThresholdGeofence from "../../components/HelpAlertThresholdGeofence/HelpAlertThresholdGeofence";
import HelpAlertThresholdAsset from "../../components/HelpAlertThresholdAsset/HelpAlertThresholdAsset";
import GeofenceThresholdsModal from "./GeofenceThresholdsModal/GeofenceThresholdsModal";
import AssetThresholdsModal from "./AssetThresholdsModal/AssetThresholdsModal";
import AssetListRow from "./AssetListRow/AssetListRow";
import styles from "./ManageAlertThresholdsPage.module.scss";

// Page for managing alert thresholds.
export default function ManageAlertThresholdsPage(): Component {
  const [loading, setLoading] = useState<boolean>(true);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [selectedType, setSelectedType] = useState<string>("");
  const [assetgroups, setAssetGroups] = useState<AlertAssetgroup[]>([]);
  const [assets, setAssets] = useState<AlertAsset[]>([]);
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const [comparators, setComparators] = useState<Comparator[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [enumerationMap, setEnumerationMap] = useState<EnumerationMap>({});
  const currentUser = useSelector(getCurrentUser);

  // Get alert threshold mapping data from API.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/alertthreshold/map`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        setAssetGroups(responseBody.assetgroups);
        setAssets(responseBody.assets);
        setUsers(responseBody.users);
        setAttributes(responseBody.attributes);
        setComparators(responseBody.alertThresholdComparators);
        setEnumerationMap(responseBody.enumerationMap);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    []
  );

  // Change the currently selected target.
  function updateSelection(selectedId: number, selectedType: string): void {
    setSelectedId(selectedId);
    setSelectedType(selectedType);
    setShowModal(true);
  }

  // Get an asset group's name given its ID.
  function getAssetgroupName(assetgroupId: number): string {
    const assetgroup: AlertAssetgroup | undefined = assetgroups.find(
      (assetgroup) => assetgroup.assetgroupId === assetgroupId
    );

    if (assetgroup !== undefined) {
      return assetgroup.name;
    } else {
      return "";
    }
  }

  // Get an asset's name given its ID.
  function getAssetName(assetId: number): string {
    const asset = assets.find((asset) => asset.assetId === assetId);
    if (asset !== undefined) {
      return asset.name;
    } else {
      return "";
    }
  }

  // Get an asset's rental status given its ID.
  function getAssetIsRental(assetId: number): boolean {
    const asset = assets.find((asset) => asset.assetId === assetId);
    if (asset !== undefined) {
      return asset.isRental;
    } else {
      return false;
    }
  }

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="page-manage-alert-thresholds p-4">
      <Spinner loading={loading} />

      <div className="row">
        {/* Geo-fence alert management. */}
        <div className={`${styles.resourceList} col-12 col-md mx-0`}>
          <ResourceList
            resourceNameSingular="Geo-fence alert threshold"
            resourceNamePlural="Geo-fence alert thresholds"
            resourceArticle="a"
            headerButtonUserPermissions={[]}
            resourceIdKey="assetgroupId"
            resources={assetgroups}
            resourceRow={AssetgroupListRow}
            helpModal={<HelpAlertThresholdGeofence />}
            filterKeys={["name"]}
            loading={loading}
            onClickHeaderButton={() => {
              /* Do nothing. */
            }}
            onSelect={(selectedId) => updateSelection(selectedId, "assetgroup")}
          />
        </div>

        <GeofenceThresholdsModal
          showModal={selectedType === "assetgroup" && showModal ? true : false}
          assetgroupId={selectedId}
          unassignedUsers={users}
          name={getAssetgroupName(selectedId)}
          onClose={() => setShowModal(false)}
        />

        {/* Asset attribute alert management. */}
        <div className={`${styles.resourceList} col-12 col-md mx-0`}>
          <ResourceList
            resourceNameSingular="Asset alert threshold"
            resourceNamePlural="Asset alert thresholds"
            resourceArticle="an"
            headerButtonLabel="Update multiple assets"
            headerButtonLabelSmall="Update multiple"
            headerButtonUserPermissions={[[MANAGE_MULTIPLE_ALERT_THRESHOLDS_PERMISSION]]}
            resourceIdKey="assetId"
            resources={assets}
            resourceRow={AssetListRow}
            helpModal={<HelpAlertThresholdAsset />}
            filterKeys={["name"]}
            loading={loading}
            onClickHeaderButton={() => updateSelection(0, "asset")}
            onSelect={(selectedId) => updateSelection(selectedId, "asset")}
          />
        </div>

        <AssetThresholdsModal
          showModal={selectedType === "asset" && showModal ? true : false}
          assetId={selectedId}
          name={getAssetName(selectedId)}
          isRental={getAssetIsRental(selectedId)}
          unassignedUsers={users}
          attributes={attributes}
          comparators={comparators}
          assets={assets}
          assetgroups={assetgroups}
          enumerationMap={enumerationMap}
          onClose={() => setShowModal(false)}
        />

        <datalist id="datalist-attributes">
          {attributes.map((attribute) => (
            <option key={attribute.regAttributeId} value={attribute.attributeName} />
          ))}
        </datalist>

        <datalist id="datalist-users">
          {users.map((user) => (
            <option key={user.userId} value={`${user.name} (${user.emailAddress})`} />
          ))}
        </datalist>
      </div>
    </div>
  );
}

interface ResponseBody {
  users: User[];
  assetgroups: AlertAssetgroup[];
  assets: AlertAsset[];
  attributes: Attribute[];
  alertThresholdComparators: Comparator[];
  enumerationMap: EnumerationMap;
}

interface User {
  userId: number;
  emailAddress: string;
  name: string;
  hasPhoneNumber: boolean;
}

interface AlertAssetgroup {
  assetgroupId: number;
  name: string;
}

interface AlertAsset {
  assetId: number;
  name: string;
  nickname: string;
  isMigrating: boolean;
  isRental: boolean;
}

interface Attribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  unitId: number | null;
  unitShortName: string | null;
  isBoolean: boolean;
  regEnumId: number | null;
}

interface Comparator {
  alertThresholdComparatorId: number;
  name: string;
}

interface EnumerationMap {
  [key: string]: EnumerationIndexMap;
}

interface EnumerationIndexMap {
  [key: string]: string;
}
