// --------------------------------------------------------------
// Created On: 2024-12-11
// Author: Hannah Vaughan
//
// Last Modified: 2025-01-03
// Modified By: Zachary Thomas
//
// Copyright 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import styles from "./FilteredDevicesTableContainer.module.scss";
import PropTypes from "prop-types";
import IconTooltip from "../../../components/IconTooltip/IconTooltip";
import CompanyDeviceForm from "../CompanyModal/CompanyDeviceForm/CompanyDeviceForm";

// Table with the option to add and filter devices.
export default function FilteredDevicesTableContainer(props: Props): Component {
  return (
    <div data-test="filtered-devices-table-container" className={`${styles.body} table-responsive mb-0 pb-0`}>
      <div className={styles.header}>
        <div className="row pt-2 pb-3 px-2 mx-0">
          {/* Title */}
          <div className="col-6">
            <span data-test="filtered-devices-table-title">{props.title}&nbsp;</span>
            <span className="h5">
              <IconTooltip
                id="associated-devices-tooltip"
                icon="info-circle"
                message={
                  "In addition to the ability to grant ownership of monitoring devices to a company by linking an " +
                  "Epicor account. It is also possible to link ownership of individual devices to a company."
                }
                color="var(--link)"
              />
            </span>
          </div>
          {/* Offset */}
          <div className="col-1 col-lg-2" />
          {/* Filter Bar */}
          <div className="col-5 col-lg-4 d-flex justify-content-center gap-2">
            <div className="input-group pt-1">
              <input
                data-test="filtered-devices-table-filter-input"
                type="search"
                className="form-control rounded"
                placeholder={props.filterPrompt}
                value={props.filter}
                onChange={(e) => props.onChangeFilter(e.target.value)}
              />
            </div>
            <button
              data-test="filtered-devices-add-device-button"
              className="btn btn-primary mt-1"
              type="button"
              onClick={() => props.onChangeShowNewDeviceForm(true)}
            >
              <i className="fa fa-fw fa-plus" />
            </button>
          </div>
          {props.showNewDeviceForm && (
            <CompanyDeviceForm
              deviceTypes={props.deviceTypes}
              showNewDeviceForm={props.showNewDeviceForm}
              deviceTypeId={props.deviceTypeId}
              deviceIdentifier={props.deviceIdentifier}
              onChangeShowNewDeviceForm={(showNewDeviceForm) => props.onClearCompanyDeviceForm(showNewDeviceForm)}
              onChangeDeviceTypeId={(deviceTypeId) => props.onChangeDeviceTypeId(deviceTypeId)}
              onChangeDeviceIdentifier={(deviceIdentifier) => props.onChangeDeviceIdentifier(deviceIdentifier)}
              onAddDevice={() => props.onAddDevice()}
            />
          )}
        </div>
      </div>
      {props.hasContent && props.hasFilteredContent && props.children}

      {!props.hasContent && (
        <div className={`${styles.info} pt-5 pb-4 px-4`}>
          <h5 className="mb-4">No {props.pluralContentType.toLowerCase()} found</h5>
        </div>
      )}

      {props.hasContent && !props.hasFilteredContent && (
        <div className={`${styles.info} pt-5 pb-4 px-4`}>
          <h5 className="mb-4">No {props.pluralContentType.toLowerCase()} match the filter</h5>
        </div>
      )}
    </div>
  );
}

FilteredDevicesTableContainer.propTypes = {
  title: PropTypes.string.isRequired,
  filterPrompt: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  hasContent: PropTypes.bool.isRequired,
  hasFilteredContent: PropTypes.bool.isRequired,
  pluralContentType: PropTypes.string.isRequired,
  showNewDeviceForm: PropTypes.bool.isRequired,
  deviceTypes: PropTypes.any,
  deviceTypeId: PropTypes.number.isRequired,
  deviceIdentifier: PropTypes.string.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onChangeShowNewDeviceForm: PropTypes.func.isRequired,
  onClearCompanyDeviceForm: PropTypes.func.isRequired,
  onChangeDeviceTypeId: PropTypes.func.isRequired,
  onChangeDeviceIdentifier: PropTypes.func.isRequired,
  onAddDevice: PropTypes.func.isRequired,
  children: PropTypes.any,
};

interface Props {
  title: string;
  filterPrompt: string;
  filter: string;
  hasContent: boolean;
  hasFilteredContent: boolean;
  pluralContentType: string;
  showNewDeviceForm: boolean;
  deviceTypes: DeviceType[];
  deviceTypeId: number;
  deviceIdentifier: string;
  onChangeFilter: (filter: string) => void;
  onChangeShowNewDeviceForm: (showNewDeviceForm: boolean) => void;
  onClearCompanyDeviceForm: (showNewDeviceForm: boolean) => void;
  onChangeDeviceTypeId: (deviceTypeId: number) => void;
  onChangeDeviceIdentifier: (deviceIdentifier: string) => void;
  onAddDevice: () => void;
  children?: Component | Component[];
}

interface DeviceType {
  deviceTypeId: number;
  name: string;
}
