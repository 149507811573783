// --------------------------------------------------------------
// Created On: 2022-07-20
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useReducer } from "react";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import useApi from "../../hooks/useApi";
import { API } from "../../constants/miscellaneous";
import { TRANSFER_ASSET_TYPES } from "../../constants/reducerActions";
import deepCopy from "../../utilities/deepCopy";
import Error500Page from "../Error500Page/Error500Page";
import Spinner from "../../components/Spinner/Spinner";
import ResourceList from "../../components/ResourceList/ResourceList";
import TransferLogList from "./TransferLogList/TransferLogList";
import apiRequest from "../../utilities/api/apiRequest";
import {
  TRANSFER_ASSETS_PERMISSION,
  ACCEPT_ASSET_TRANSFERS_PERMISSION,
  DECLINE_ASSET_TRANSFERS_PERMISSION,
} from "../../constants/permissions";
import userHasPermission from "../../utilities/userHasPermission";
import TransferAssetListRow from "./OutgoingTransferAssetListRow/OutgoingTransferAssetListRow";
import ReceivingAssetListRow from "./IncomingTransferAssetListRow/IncomingTransferAssetListRow";
import HelpTransfer from "../../components/HelpTransfer/HelpTransfer";
import HelpTransferReceive from "../../components/HelpTransferReceive/HelpTransferReceive";
import OutgoingTransferAssetModal from "./OutgoingTransferAssetModal/OutgoingTransferAssetModal";
import IncomingTransferAssetModal from "./IncomingTransferAssetModal/IncomingTransferAssetModal";

// Page for transferring assets to other companies.
export default function ManageTransferAssetsPage(): Component {
  const [loading, setLoading] = useState<boolean>(true);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [selectedAsset, setSelectedAsset] = useState<TransferAsset | null>(null);
  const [selectionType, setSelectionType] = useState<"outgoing" | "incoming" | null>(null);
  const [assets, setAssets] = useState<OwnedAsset[]>([]);
  const [outgoingAssets, dispatchOutgoingAsset] = useReducer(outgoingAssetReducer, []);
  const [incomingAssets, dispatchIncomingAsset] = useReducer(incomingAssetReducer, []);
  const [transferLogs, setTransferLogs] = useState<TransferLog[]>([]);
  const currentUser = useSelector(getCurrentUser);

  // Get in progress transfer data from API.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/transfer/map`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        setAssets(responseBody.ownedAssets);
        dispatchOutgoingAsset({
          type: TRANSFER_ASSET_TYPES.SET_ASSETS,
          payload: {
            assets: responseBody.outgoingAssets,
          },
        });
        dispatchIncomingAsset({
          type: TRANSFER_ASSET_TYPES.SET_ASSETS,
          payload: {
            assets: responseBody.incomingAssets,
          },
        });
        setTransferLogs(responseBody.transferLogs);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    []
  );

  // Reducer for outgoing transfer assets.
  function outgoingAssetReducer(state: TransferAsset[], action: TransferOutAction): TransferAsset[] {
    switch (action.type) {
      case TRANSFER_ASSET_TYPES.SET_ASSETS: {
        if (action.payload.assets !== undefined) {
          return action.payload.assets;
        } else {
          return state;
        }
      }

      case TRANSFER_ASSET_TYPES.CREATE_ASSET: {
        let stateDeepCopy = deepCopy(state);
        const newAsset = action.payload.asset;

        // Make sure the payload includes a valid asset.
        if (newAsset !== undefined) {
          const assetExists = stateDeepCopy.some((asset) => asset.assetId === newAsset.assetId);

          // Check if the asset already exists before creating it.
          if (!assetExists) {
            stateDeepCopy.push(newAsset);
            stateDeepCopy = sortAssets(stateDeepCopy);
          }
        }

        void refreshTransferHistoryLogs();

        return stateDeepCopy;
      }

      case TRANSFER_ASSET_TYPES.DELETE_ASSET: {
        const stateDeepCopy = deepCopy(state);
        const assetId = action.payload.assetId;

        // Find the index of the asset.
        const assetIndex = stateDeepCopy.findIndex((asset) => asset.assetId === assetId);
        // Don't continue if we couldn't find the asset.
        if (assetIndex === -1) {
          return state;
        }

        // Delete the current asset.
        stateDeepCopy.splice(assetIndex, 1);
        void refreshTransferHistoryLogs();
        setSelectedAsset(null);
        return stateDeepCopy;
      }

      default: {
        return state;
      }
    }
  }

  // Reducer for rented out assets (current company owns asset).
  function incomingAssetReducer(state: TransferAsset[], action: TransferInAction): TransferAsset[] {
    switch (action.type) {
      case TRANSFER_ASSET_TYPES.SET_ASSETS: {
        if (action.payload.assets !== undefined) {
          return action.payload.assets;
        } else {
          return state;
        }
      }

      case TRANSFER_ASSET_TYPES.CREATE_ASSET: {
        let stateDeepCopy = deepCopy(state);
        const newAsset = action.payload.asset;

        // Make sure the payload includes a valid asset.
        if (newAsset !== undefined) {
          const assetExists = stateDeepCopy.some((asset) => asset.assetId === newAsset.assetId);

          // Check if the asset already exists before creating it.
          if (!assetExists) {
            stateDeepCopy.push(newAsset);
            stateDeepCopy = sortAssets(stateDeepCopy);
          }
        }

        void refreshTransferHistoryLogs();

        return stateDeepCopy;
      }

      case TRANSFER_ASSET_TYPES.DELETE_ASSET: {
        const stateDeepCopy = deepCopy(state);
        const assetId = action.payload.assetId;

        // Find the index of the asset.
        const assetIndex = stateDeepCopy.findIndex((asset) => asset.assetId === assetId);

        // Don't continue if we couldn't find the asset.
        if (assetIndex === -1) {
          return state;
        }

        // Delete the current asset.
        stateDeepCopy.splice(assetIndex, 1);

        void refreshTransferHistoryLogs();
        setSelectedAsset(null);
        return stateDeepCopy;
      }

      default: {
        return state;
      }
    }
  }

  // Sort assets.
  function sortAssets(assets: TransferAsset[]): TransferAsset[] {
    return assets.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  // Select an asset so that it can be viewed in a modal.
  function selectAsset(assetId: number, selectionType: "outgoing" | "incoming" | null): void {
    setSelectionType(selectionType);

    let foundAsset: TransferAsset | undefined;
    if (assetId === 0) {
      foundAsset = {
        assetId: 0,
        name: "",
        companyName: "",
      };
    } else {
      if (selectionType === "outgoing") {
        foundAsset = outgoingAssets.find((asset: TransferAsset) => asset.assetId === assetId);
      } else {
        foundAsset = incomingAssets.find((asset: TransferAsset) => asset.assetId === assetId);
      }
    }

    if (foundAsset === undefined) {
      setSelectedAsset(null);
    } else {
      setSelectedAsset(foundAsset);
    }
  }

  // Refresh the transfer history logs.
  async function refreshTransferHistoryLogs(): Promise<void> {
    const [response, responseBody] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/transferlogs/0`,
      "GET",
      null
    )) as [Response, HistoryResponseBody];

    if (response.ok) {
      setTransferLogs(responseBody.transferLogs);
    } else {
      console.error("Unable to retrieve current transfer logs.");
    }
  }

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="p-4">
      <Spinner loading={loading} />

      <div className="row" data-test="outgoing-transfer-list">
        {userHasPermission([[TRANSFER_ASSETS_PERMISSION]]) && (
          <div className="col-12 col-xl mb-4">
            <ResourceList
              resourceNameSingular="Outgoing Asset Transfer"
              resourceNamePlural="Outgoing Asset Transfers"
              resourceArticle="an"
              headerButtonLabel="Transfer Asset"
              headerButtonLabelSmall="Transfer"
              headerButtonUserPermissions={[[TRANSFER_ASSETS_PERMISSION]]}
              resourceIdKey="assetId"
              resources={outgoingAssets}
              resourceRow={TransferAssetListRow}
              helpModal={<HelpTransfer />}
              filterKeys={["name"]}
              loading={loading}
              onClickHeaderButton={() => selectAsset(0, "outgoing")}
              onSelect={(assetId) => selectAsset(assetId, "outgoing")}
            />
          </div>
        )}

        {userHasPermission([[ACCEPT_ASSET_TRANSFERS_PERMISSION], [DECLINE_ASSET_TRANSFERS_PERMISSION]]) && (
          <div className="col-12 col-xl mb-4" data-test="incoming-transfer-list">
            <ResourceList
              resourceNameSingular="Incoming Asset Transfer"
              resourceNamePlural="Incoming Asset Transfers"
              resourceArticle="an"
              resourceIdKey="assetId"
              resources={incomingAssets}
              resourceRow={ReceivingAssetListRow}
              helpModal={<HelpTransferReceive />}
              filterKeys={["name"]}
              loading={loading}
              onClickHeaderButton={() => {
                /* Do nothing. */
              }}
              onSelect={(assetId) => selectAsset(assetId, "incoming")}
            />
          </div>
        )}
      </div>

      {transferLogs.length > 0 && (
        <div className="my-3">
          <TransferLogList transferLogs={transferLogs} />
        </div>
      )}

      {selectedAsset !== null && selectionType === "outgoing" && (
        <OutgoingTransferAssetModal
          isCreatingNewRecord={selectedAsset.assetId === 0}
          asset={selectedAsset}
          assets={assets}
          onClose={() => selectAsset(-1, null)}
          onAction={(action: TransferOutAction) => dispatchOutgoingAsset(action)}
        />
      )}

      {selectedAsset !== null && selectionType === "incoming" && (
        <IncomingTransferAssetModal
          asset={selectedAsset}
          onClose={() => selectAsset(-1, null)}
          onAction={(action: TransferInAction) => dispatchIncomingAsset(action)}
        />
      )}
    </div>
  );
}

interface ResponseBody {
  ownedAssets: OwnedAsset[];
  outgoingAssets: TransferAsset[];
  incomingAssets: TransferAsset[];
  transferLogs: TransferLog[];
}

interface HistoryResponseBody {
  transferLogs: TransferLog[];
}

interface OwnedAsset {
  assetId: number;
  name: string;
}

interface TransferAsset {
  assetId: number;
  name: string;
  companyName: string;
}

interface TransferAsset {
  assetId: number;
  name: string;
  companyName: string;
}

interface TransferLog {
  assetTransferLogId: number;
  userName: string | null;
  assetName: string;
  companyName: string;
  deviceType: string | null;
  deviceIdentifier: string | null;
  transferCode: string;
  createdUtc: string;
}

interface TransferOutAction {
  type: string;
  payload: TransferOutPayload;
}

interface TransferOutPayload {
  assets?: TransferAsset[];
  asset?: TransferAsset;
  assetId?: number;
  companyId?: number;
}

interface TransferInAction {
  type: string;
  payload: TransferInPayload;
}

interface TransferInPayload {
  assets?: TransferAsset[];
  asset?: TransferAsset;
  assetId?: number;
  companyId?: number;
}
