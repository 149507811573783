// --------------------------------------------------------------
// Created On: 2021-08-06
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useMemo } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

// Column chart for viewing data.
export default function ColumnChart(props: Props): Component {
  const navigate = useNavigate();
  const options = useMemo(
    () => getOptions(props.units, props.assetNames, props.assetColors),
    [props.units, props.assetNames, props.assetColors]
  );

  // Go to the asset details page.
  function goToAssetDetails(assetIndex: number): void {
    if (assetIndex < props.assetIds.length) {
      navigate(`/asset/${props.assetIds[assetIndex]}/data`);
    }
  }

  // Get the options needed to configure the column chart.
  function getOptions(units: string | null, assetNames: string[], assetColors: string[]): ApexOptions | null {
    return {
      chart: {
        height: 400,
        type: "bar",
        events: {
          dataPointSelection: (event, chartContext, config) => {
            goToAssetDetails((config as ApexPointConfig).dataPointIndex);
          },
        },
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        strokeDashArray: 0,
        position: "back",
        row: {
          colors: ["var(--chart-background)", "var(--chart-background-alternative)"],
          opacity: 1,
        },
      },
      xaxis: {
        tickAmount: 10,
        labels: {
          style: {
            fontSize: "12px",
          },
        },
        categories: assetNames,
      },
      yaxis: {
        max: (max: number) => max,
        min: (min: number) => min,
        labels: {
          style: {
            colors: "var(--chart-text)",
          },
          formatter: (value: number) => {
            return `${parseFloat(value.toFixed(6))} ${units === null ? "" : units}`;
          },
        },
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            borderColor: "var(--chart-negative-line)",
            label: {
              borderColor: "var(--chart-negative-line)",
              style: {
                color: "var(--chart-negative-line)",
                background: "var(--chart-negative-line)",
              },
              text: "",
            },
          },
        ],
      },
      colors: assetColors,
      plotOptions: {
        bar: {
          columnWidth: "50%",
          distributed: true,
        },
      },
      legend: {
        show: false,
      },
      fill: {
        colors: assetColors,
      },
    };
  }

  return options ? (
    <Chart options={options} series={[{ name: props.name, data: props.assetValues }]} type="bar" height={400} />
  ) : null;
}

ColumnChart.propTypes = {
  name: PropTypes.string.isRequired,
  units: PropTypes.string.isRequired,
  assetNames: PropTypes.array.isRequired,
  assetValues: PropTypes.array.isRequired,
  assetColors: PropTypes.array.isRequired,
  assetStaleStatus: PropTypes.array.isRequired,
};

interface Props {
  name: string;
  units: string | null;
  assetIds: number[];
  assetNames: string[];
  assetValues: number[];
  assetColors: string[];
  assetStaleStatus: boolean[];
}

interface ApexPointConfig {
  dataPointIndex: number;
}
