// --------------------------------------------------------------
// Created On: 2021-08-06
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Creates a deep copy of an object (arrays are also objects in JS).
export default function deepCopy<Generic>(object: Generic): Generic {
  return JSON.parse(JSON.stringify(object)) as Generic;
}
