// --------------------------------------------------------------
// Created On: 2022-01-05
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect, Fragment } from "react";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import SaveChangesModal from "../../../components/SaveChangesModal/SaveChangesModal";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import Error from "../../../components/Error/Error";
import Spinner from "../../../components/Spinner/Spinner";
import apiRequest from "../../../utilities/api/apiRequest";
import getApiError from "../../../utilities/api/getApiError";
import MaintenanceForm from "./MaintenanceForm/MaintenanceForm";
import isoLocalToIsoUtc from "../../../utilities/time/isoLocalToIsoUtc";
import isoUtcToIsoLocal from "../../../utilities/time/isoUtcToIsoLocal";
import { useParams } from "react-router-dom";
import { getCurrentUser } from "../../../redux/selectors";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import isoIsValid from "../../../utilities/time/isoIsValid";
import {
  API,
  MIN_MAINTENANCE_LENGTH,
  MAX_MAINTENANCE_LENGTH,
  MIN_MAINTENANCE_NOTES_LENGTH,
  MAX_MAINTENANCE_NOTES_LENGTH,
} from "../../../constants/miscellaneous";
import { MAINTENANCE_LOG_TYPES } from "../../../constants/reducerActions";
import userHasPermission from "../../../utilities/userHasPermission";
import {
  CREATE_MAINTENANCE_LOGS_PERMISSION,
  DELETE_MAINTENANCE_LOGS_PERMISSION,
  UPDATE_MAINTENANCE_LOGS_PERMISSION,
} from "../../../constants/permissions";
import styles from "./MaintenanceModal.module.scss";

// Modal for creating, editing, and deleting maintenance logs.
export default function MaintenanceModal(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [showConfirmExit, setShowConfirmExit] = useState<boolean>(false);
  const [date, setDate] = useState<string>("");
  const [time, setTime] = useState<string>("");
  const [workPerformedBy, setWorkPerformedBy] = useState<string>("");
  const [workPerformed, setWorkPerformed] = useState<string>("");
  const [cost, setCost] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [runtimeVibration, setRuntimeVibration] = useState<string>("");
  const [runtimeAsset, setRuntimeAsset] = useState<string>("");
  const [runtimeService, setRuntimeService] = useState<string>("");
  const [clearServiceHours, setClearServiceHours] = useState<boolean>(false);
  const currentUser = useSelector(getCurrentUser);
  const { assetId } = useParams();

  // Update state when selected maintenance log prop changes.
  useEffect(() => {
    if (props.showModal) {
      setWorkPerformedBy(props.selectedLog.workPerformedBy);
      setWorkPerformed(props.selectedLog.workPerformed);
      setCost(props.selectedLog.cost);
      setNote(props.selectedLog.note);
      setRuntimeAsset(props.assetHours);
      setRuntimeService(props.hoursSinceLastServiced);
      setRuntimeVibration(props.vibrationBasedHours);
      const [date, time] = getLocalDateAndTime(props.selectedLog.datePerformed);
      setDate(date);
      setTime(time);
    }
  }, [
    props.showModal,
    props.assetHours,
    props.hoursSinceLastServiced,
    props.vibrationBasedHours,
    JSON.stringify(props.selectedLog),
  ]);

  // Get the local date and time from the maintenance logs UTC date performed.
  function getLocalDateAndTime(datePerformed: string): [string, string] {
    const utcDatePerformed = datePerformed.split(".")[0];
    let localDatePerformed = "";
    if (isoIsValid(utcDatePerformed)) {
      localDatePerformed = isoUtcToIsoLocal(utcDatePerformed).split(".")[0];
    }
    if (isoIsValid(localDatePerformed)) {
      const dateTime = localDatePerformed.split("T");
      return [dateTime[0], dateTime[1].slice(0, -3)];
    } else {
      return ["", ""];
    }
  }

  // Returns whether the current user is allowed to edit the form with their current permissions.
  function formIsEditable(): boolean {
    return (
      (props.mode === "create" && userHasPermission([[CREATE_MAINTENANCE_LOGS_PERMISSION]])) ||
      (props.mode !== "create" && userHasPermission([[UPDATE_MAINTENANCE_LOGS_PERMISSION]]))
    );
  }

  // Validate the maintenance log.
  function logIsValid(): boolean {
    if (workPerformed.trim().length < MIN_MAINTENANCE_LENGTH || workPerformed.trim().length > MAX_MAINTENANCE_LENGTH) {
      setErrorMessage(
        `The service description must be between ${MIN_MAINTENANCE_LENGTH} and ${MAX_MAINTENANCE_LENGTH} characters long.`
      );
      return false;
    } else if (
      workPerformedBy.trim().length < MIN_MAINTENANCE_LENGTH ||
      workPerformedBy.trim().length > MAX_MAINTENANCE_LENGTH
    ) {
      setErrorMessage(
        `The service provider name must be between ${MIN_MAINTENANCE_LENGTH} and ${MAX_MAINTENANCE_LENGTH} characters long.`
      );
      return false;
    } else if (date === "") {
      setErrorMessage("You must select a date for when the maintenance was performed.");
      return false;
    } else if (time === "") {
      setErrorMessage("You must select a time that the maintenance was performed.");
      return false;
    } else if (parseInt(date.split("-")[0], 10) > 9999) {
      setErrorMessage("The entered year cannot be greater than 9999.");
      return false;
    } else if (cost.trim().length < MIN_MAINTENANCE_LENGTH || cost.trim().length > MAX_MAINTENANCE_LENGTH) {
      setErrorMessage(
        `The cost must be between ${MIN_MAINTENANCE_LENGTH} and ${MAX_MAINTENANCE_LENGTH} characters long.`
      );
      return false;
    } else if (note.trim().length < MIN_MAINTENANCE_NOTES_LENGTH || note.trim().length > MAX_MAINTENANCE_NOTES_LENGTH) {
      setErrorMessage(
        `The maintenance notes must be between ${MIN_MAINTENANCE_NOTES_LENGTH} and ${MAX_MAINTENANCE_NOTES_LENGTH} characters long.`
      );
      return false;
    } else {
      return true;
    }
  }

  // Create a maintenance log.
  async function createLog(): Promise<void> {
    if (logIsValid()) {
      const localDate = `${date}T${time}:00`;
      const utcDate = isoLocalToIsoUtc(localDate).split(".")[0];

      let engineRuntime = null;
      if (typeof runtimeAsset === "string" && runtimeAsset.length > 0) {
        engineRuntime = runtimeAsset;
      }

      let currentServiceRuntime = null;
      if (typeof runtimeService === "string" && runtimeService.length > 0) {
        currentServiceRuntime = runtimeService;
      }

      let vibrationBasedRuntime = null;
      if (typeof runtimeVibration === "string" && runtimeVibration.length > 0) {
        vibrationBasedRuntime = runtimeVibration;
      }

      const requestBody = {
        workPerformed: workPerformed.trim(),
        workPerformedBy: workPerformedBy.trim(),
        datePerformed: utcDate,
        engineRuntime: engineRuntime,
        currentServiceRuntime: currentServiceRuntime,
        vibrationBasedRuntime: vibrationBasedRuntime,
        cost: cost.trim(),
        note: note.trim(),
        resetRuntimeSinceLastServiced: clearServiceHours,
      };

      setLoading(true);
      const [response, responseBody] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/asset/${assetId}/maintenance`,
        "POST",
        requestBody
      )) as [Response, PostResponseBody];
      setLoading(false);

      if (response.ok) {
        const newLog = {
          assetMaintenanceLogId: responseBody.assetMaintenanceLogId,
          workPerformed: workPerformed,
          workPerformedBy: workPerformedBy,
          datePerformed: utcDate,
          engineRuntime: engineRuntime,
          currentServiceRuntime: currentServiceRuntime,
          vibrationBasedRuntime: vibrationBasedRuntime,
          cost: cost,
          note: note,
        };
        props.onAction({
          type: MAINTENANCE_LOG_TYPES.CREATE_MAINTENANCE_LOG,
          payload: { maintenanceLog: newLog },
        });
        if (clearServiceHours) {
          props.onClearServiceHours();
        }
        discardChanges();
      } else {
        setErrorMessage(await getApiError(response, "Unable to create maintenance log."));
      }
    }
  }

  // Edit a maintenance log.
  async function editLog(assetMaintenanceLogId: number): Promise<void> {
    if (logIsValid()) {
      const localDate = `${date}T${time}:00`;
      const utcDate = isoLocalToIsoUtc(localDate).split(".")[0];

      let engineRuntime = null;
      if (typeof runtimeAsset === "string" && runtimeAsset.length > 0) {
        engineRuntime = runtimeAsset;
      }

      let currentServiceRuntime = null;
      if (typeof runtimeService === "string" && runtimeService.length > 0) {
        currentServiceRuntime = runtimeService;
      }

      let vibrationBasedRuntime = null;
      if (typeof runtimeVibration === "string" && runtimeVibration.length > 0) {
        vibrationBasedRuntime = runtimeVibration;
      }

      const requestBody = {
        workPerformed: workPerformed.trim(),
        workPerformedBy: workPerformedBy.trim(),
        datePerformed: utcDate,
        engineRuntime: engineRuntime,
        currentServiceRuntime: currentServiceRuntime,
        vibrationBasedRuntime: vibrationBasedRuntime,
        cost: cost.trim(),
        note: note.trim(),
        resetRuntimeSinceLastServiced: clearServiceHours,
      };

      setLoading(true);
      const [response] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/asset/${assetId}/maintenance/${assetMaintenanceLogId}`,
        "PUT",
        requestBody
      )) as [Response, PutResponseBody];
      setLoading(false);

      if (response.ok) {
        const updatedLog = {
          assetMaintenanceLogId: assetMaintenanceLogId,
          workPerformed: workPerformed,
          workPerformedBy: workPerformedBy,
          datePerformed: utcDate,
          engineRuntime: engineRuntime,
          currentServiceRuntime: currentServiceRuntime,
          vibrationBasedRuntime: vibrationBasedRuntime,
          cost: cost,
          note: note,
          resetRuntimeSinceLastServiced: clearServiceHours,
        };
        props.onAction({
          type: MAINTENANCE_LOG_TYPES.UPDATE_MAINTENANCE_LOG,
          payload: { maintenanceLog: updatedLog },
        });
        if (clearServiceHours) {
          props.onClearServiceHours();
        }
        setErrorMessage("");
        props.onClose();
      } else {
        setErrorMessage(await getApiError(response, "Unable to update maintenance log."));
      }
    }
  }

  // Delete a maintenance log.
  async function deleteLog(assetMaintenanceLogId: number): Promise<void> {
    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/asset/${assetId}/maintenance/${assetMaintenanceLogId}`,
      "DELETE",
      null
    )) as [Response, DeleteResponseBody];
    setLoading(false);

    if (response.ok) {
      discardChanges();
      props.onAction({
        type: MAINTENANCE_LOG_TYPES.DELETE_MAINTENANCE_LOG,
        payload: { assetMaintenanceLogId: assetMaintenanceLogId },
      });
    } else {
      setShowConfirmDelete(false);
      setErrorMessage(await getApiError(response, "Unable to delete maintenance log."));
    }
  }

  // Exit modal if no changes have been made. Otherwise prompt user.
  function exitModal(): void {
    // Check to see if state is the same as props.
    const [previousDate, previousTime] = getLocalDateAndTime(props.selectedLog.datePerformed);
    if (
      date === previousDate &&
      time === previousTime &&
      workPerformedBy === props.selectedLog.workPerformedBy &&
      workPerformed === props.selectedLog.workPerformed &&
      cost === props.selectedLog.cost &&
      note === props.selectedLog.note
    ) {
      // Since there have been no changes we can safely exit.
      discardChanges();
    } else {
      // We have unsaved changes, give the user a chance to save them.
      setShowConfirmExit(true);
    }
  }

  // Save changes.
  function saveChanges(): void {
    if (props.mode === "create") {
      void createLog();
    } else {
      void editLog(props.selectedLog.assetMaintenanceLogId);
    }
    setShowConfirmExit(false);
  }

  // Exit without saving changes.
  function discardChanges(): void {
    setShowConfirmExit(false);
    setShowConfirmDelete(false);
    setClearServiceHours(false);
    setErrorMessage("");
    props.onClose();
  }

  return (
    <div>
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => exitModal()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--modal-z-index)" }}
        size="xl"
        animation
        centered
      >
        <ModalHeader>
          <h5 className="font-weight-bold">
            {props.mode === "create" ? <span>Create Maintenance Log</span> : <span>Edit Maintenance Log</span>}
          </h5>
        </ModalHeader>

        <ModalBody>
          <MaintenanceForm
            isRented={props.isRented}
            date={date}
            time={time}
            workPerformedBy={workPerformedBy}
            workPerformed={workPerformed}
            cost={cost}
            note={note}
            runtimeAsset={runtimeAsset || ""}
            runtimeVibration={runtimeVibration || ""}
            runtimeService={runtimeService || ""}
            formIsEditable={formIsEditable()}
            isNewMaintenanceLog={props.mode === "create"}
            clearServiceHours={clearServiceHours}
            onChangeDate={(date) => setDate(date)}
            onChangeTime={(time) => setTime(time)}
            onChangeWorkPerformed={(workPerformed) => setWorkPerformed(workPerformed)}
            onChangeWorkPerformedBy={(workPerformedBy) => setWorkPerformedBy(workPerformedBy)}
            onChangeCost={(cost) => setCost(cost)}
            onChangeNote={(note) => setNote(note)}
            onChangeRuntimeAsset={(runtimeAsset) => setRuntimeAsset(runtimeAsset)}
            onChangeRuntimeVibration={(runtimeVibration) => setRuntimeVibration(runtimeVibration)}
            onChangeRuntimeService={(runtimeService) => setRuntimeService(runtimeService)}
            onClearServiceHours={(clearServiceHours) => setClearServiceHours(clearServiceHours)}
          />

          {errorMessage.length > 0 && (
            <div className="row">
              <div className="col mt-4 mx-2">
                <Error message={errorMessage} />
              </div>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          {props.mode === "create" ? (
            <Fragment>
              <button
                data-test="modal-submit-button"
                className={`${styles.footer} ${styles.btn} btn btn-primary`}
                type="button"
                onClick={() => createLog()}
              >
                Create Maintenance Log
              </button>

              <button
                data-test="modal-cancel-button"
                className={`${styles.footer} ${styles.btn} btn btn-secondary`}
                type="button"
                onClick={() => exitModal()}
              >
                Cancel
              </button>
            </Fragment>
          ) : (
            <Fragment>
              {userHasPermission([[DELETE_MAINTENANCE_LOGS_PERMISSION]]) && (
                <button
                  data-test="modal-delete-button"
                  className={`${styles.footer} ${styles.btn} btn btn-danger me-auto`}
                  type="button"
                  onClick={() => setShowConfirmDelete(true)}
                >
                  Delete
                </button>
              )}

              {userHasPermission([[UPDATE_MAINTENANCE_LOGS_PERMISSION]]) && (
                <button
                  data-test="modal-submit-button"
                  className={`${styles.footer} ${styles.btn} btn btn-primary`}
                  type="button"
                  onClick={() => editLog(props.selectedLog.assetMaintenanceLogId)}
                >
                  Save Changes
                </button>
              )}

              <button
                data-test="modal-cancel-button"
                className={`${styles.footer} ${styles.btn} btn btn-secondary`}
                type="button"
                onClick={() => exitModal()}
              >
                {userHasPermission([[UPDATE_MAINTENANCE_LOGS_PERMISSION], [DELETE_MAINTENANCE_LOGS_PERMISSION]])
                  ? "Cancel"
                  : "Close"}
              </button>
            </Fragment>
          )}
        </ModalFooter>
      </Modal>

      <ConfirmModal
        showModal={props.showModal && showConfirmDelete}
        title="Delete maintenance log?"
        content="Are you sure that you want to delete the maintenance log? This action cannot be undone."
        yesText="Delete Maintenance Log"
        noText="Cancel"
        danger={true}
        onClose={() => setShowConfirmDelete(false)}
        onYes={() => deleteLog(props.selectedLog.assetMaintenanceLogId)}
        onNo={() => setShowConfirmDelete(false)}
      />

      <SaveChangesModal
        showModal={props.showModal && showConfirmExit}
        title="Changes have not been saved!"
        content="Are you sure that you want to exit without saving your changes?"
        onClose={() => setShowConfirmExit(false)}
        onSave={() => saveChanges()}
        onNoSave={() => discardChanges()}
      />
    </div>
  );
}

MaintenanceModal.propTypes = {
  mode: PropTypes.oneOf(["create", "edit"]).isRequired,
  showModal: PropTypes.bool.isRequired,
  isRented: PropTypes.bool.isRequired,
  selectedLog: PropTypes.object.isRequired,
  hoursSinceLastServiced: PropTypes.string.isRequired,
  vibrationBasedHours: PropTypes.string.isRequired,
  assetHours: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

interface Props {
  mode: "create" | "edit";
  showModal: boolean;
  isRented: boolean;
  selectedLog: MaintenanceLog;
  hoursSinceLastServiced: string;
  vibrationBasedHours: string;
  assetHours: string;
  onClose: () => void;
  onAction: (action: Action) => void;
  onClearServiceHours: () => void;
}

interface MaintenanceLog {
  assetMaintenanceLogId: number;
  workPerformed: string;
  workPerformedBy: string;
  datePerformed: string;
  currentServiceRuntime: string | null;
  vibrationBasedRuntime: string | null;
  engineRuntime: string | null;
  cost: string;
  note: string;
}

interface PostResponseBody {
  assetMaintenanceLogId: number;
}

interface PutResponseBody {
  message: string;
}

interface DeleteResponseBody {
  message: string;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  assetMaintenanceLogId?: number;
  maintenanceLog?: MaintenanceLog;
}
