// --------------------------------------------------------------
// Created On: 2022-10-24
// Author: Zachary Thomas
//
// Last Modified: 2025-01-20
// Modified By: Zachary Thomas
//
// Copyright 2024 - 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./FilteredTableContainer.module.scss";

// Table with the option to filter results.
export default function FilteredTableContainer(props: Props): Component {
  return (
    <div data-test="filtered-table-container" className={`${styles.body} table-responsive mb-0 pb-0`}>
      <div className={styles.header}>
        <div className="row pt-2 pb-4 px-2 mx-0">
          {/* Title */}
          <div className="col-6">
            <span data-test="filtered-table-title">{props.title}</span>
          </div>
          {/* Offset. */}
          <div className="col-1 col-lg-2" />
          {/* Filter bar. */}
          {props.showFilterInput && (
            <div className="col-5 col-lg-4">
              <div className="input-group pt-1">
                <input
                  data-test="filtered-table-filter-input"
                  type="search"
                  className="form-control rounded"
                  placeholder={props.filterPrompt}
                  value={props.filter}
                  onChange={(e) => props.onChangeFilter(e.target.value)}
                />
              </div>
            </div>
          )}
          {props.buttonText !== undefined && props.buttonText !== "" && (
            <div className="col pt-2">
              <button
                className="btn btn-primary float-end"
                onClick={() => {
                  if (props.onClickButton !== undefined) {
                    props.onClickButton();
                  }
                }}
              >
                {props.buttonText}
              </button>
            </div>
          )}
        </div>
      </div>
      {props.hasContent && !props.showFilterInput && !props.hasFilteredContent && props.children}

      {props.hasContent && props.hasFilteredContent && props.children}

      {!props.hasContent && (
        <div className={`${styles.info} pt-5 pb-4 px-4`}>
          <h5 className="mb-4">No {props.pluralContentType.toLowerCase()} found.</h5>
        </div>
      )}

      {props.hasContent && !props.hasFilteredContent && props.showFilterInput && (
        <div className={`${styles.info} pt-5 pb-4 px-4`}>
          <h5 className="mb-4">No {props.pluralContentType.toLowerCase()} match the filter.</h5>
        </div>
      )}
    </div>
  );
}

FilteredTableContainer.propTypes = {
  filterPrompt: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  hasContent: PropTypes.bool.isRequired,
  hasFilteredContent: PropTypes.bool.isRequired,
  showFilterInput: PropTypes.bool.isRequired,
  pluralContentType: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  onChangeFilter: PropTypes.func.isRequired,
  onClickButton: PropTypes.func,
  children: PropTypes.any,
};

interface Props {
  title: string;
  filterPrompt: string;
  filter: string;
  hasContent: boolean;
  hasFilteredContent: boolean;
  showFilterInput: boolean;
  pluralContentType: string;
  buttonText?: string;
  onChangeFilter: (filter: string) => void;
  onClickButton?: () => void;
  children?: Children;
}
