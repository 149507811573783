// --------------------------------------------------------------
// Created On: 2021-12-20
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

/// <reference types="google.maps" />
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import deepCopy from "../../../../../utilities/deepCopy";
import { GoogleMap, useJsApiLoader, Polygon, Marker } from "@react-google-maps/api";
import calculateCenterZoom from "../../../../../utilities/calculateCenterZoom";
import {
  GOOGLE_MAPS_API_KEY,
  GOOGLE_MAPS_DEFAULT_ZOOM,
  GOOGLE_MAPS_DEFAULT_LAT,
  GOOGLE_MAPS_DEFAULT_LNG,
  GOOGLE_MAPS_BOTTOM_LEFT,
  GOOGLE_MAPS_HORIZONTAL_BAR,
  GOOGLE_MAPS_ROAD_MAP,
  GOOGLE_MAPS_SATELLITE,
  GOOGLE_MAPS_MIN_ZOOM,
} from "../../../../../constants/googleMaps";
import styles from "./GeofenceMap.module.scss";

// Map that displays geo-fence data.
export default function GeofenceMap(props: Props): Component {
  const [center, setCenter] = useState<ShortPoint>({
    lat: GOOGLE_MAPS_DEFAULT_LAT,
    lng: GOOGLE_MAPS_DEFAULT_LNG,
  });
  const [zoom, setZoom] = useState<number>(GOOGLE_MAPS_DEFAULT_ZOOM);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  // Draw geofence polygons to the map.
  useEffect(() => {
    let geofencePolygon: google.maps.Polygon | null = null;

    if (map !== null) {
      // Get the complete geofence path.
      const geofencePath = props.geofencePoints.map((geofencePoint) => {
        return { lat: geofencePoint.latitude, lng: geofencePoint.longitude };
      });

      // Draw the new geofence polygon.
      if (geofencePath.length > 0) {
        geofencePolygon = new google.maps.Polygon({
          paths: geofencePath,
          strokeColor: `${props.geofenceEnabled ? "#3957d9" : "#41484e"}`,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: `${props.geofenceEnabled ? "#3957d9" : "#41484e"}`,
          fillOpacity: 0.35,
        });

        geofencePolygon.setMap(map);
      }
    }

    // Clean up old polygons off the map.
    return () => {
      if (geofencePolygon !== null) {
        geofencePolygon.setMap(null);
      }
    };
  }, [map, props.geofenceEnabled, JSON.stringify(props.geofencePoints)]);

  // Adjust the zoom and center if the saved geofence points change.
  useEffect(() => {
    // Find the new bounding box that fits all markers.
    if (map !== null) {
      let newBounds;
      if (props.geofencePoints.length > 0) {
        const previousGeofencePointsDeepCopy = deepCopy(props.previousGeofencePoints);
        const boundingBoxPoints: Point[] = [];
        previousGeofencePointsDeepCopy.forEach((previousGeofencePoint) => {
          boundingBoxPoints.push({
            latitude: Number(previousGeofencePoint.latitude),
            longitude: Number(previousGeofencePoint.longitude),
          });
        });
        newBounds = calculateCenterZoom(boundingBoxPoints, map);
        setCenter(newBounds.center);
        setZoom(newBounds.zoom - 1);
      } else {
        setCenter({
          lat: GOOGLE_MAPS_DEFAULT_LAT,
          lng: GOOGLE_MAPS_DEFAULT_LNG,
        });
        setZoom(GOOGLE_MAPS_DEFAULT_ZOOM);
      }
    }
  }, [JSON.stringify(props.previousGeofencePoints), map]);

  // Handel load map event.
  function handleLoadMap(map: google.maps.Map): void {
    const bounds = new window.google.maps.LatLngBounds(center);
    // Only attempt to fit bounds if there is at least two saved geofence points.
    if (props.previousGeofencePoints.length >= 2) {
      map.fitBounds(bounds);
    }
    setMap(map);
  }

  // Handel unmount map event.
  function handleUnmountMap(): void {
    setMap(null);
  }

  // Drag a geofence point to a new location.
  function dragGeofencePoint(geofencePoint: GeofencePoint, newCoordinates: google.maps.LatLng | null): void {
    if (geofencePoint.geofencePointId !== undefined && newCoordinates !== null) {
      props.onUpdatePoint(geofencePoint.geofencePointId, newCoordinates.lat(), newCoordinates.lng());
    }
  }

  // Handles a map click to determine if a new point geo-fence point should be created.
  function handleMapClick(gpsPoint: google.maps.LatLng | null): void {
    if (gpsPoint !== null && gpsPoint.lat() !== null && gpsPoint.lng() !== null) {
      props.onCreatePoint(gpsPoint.lat(), gpsPoint.lng());
    }
  }

  return (
    <div className="mx-2">
      <div className={styles.geofenceMapWrapper}>
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "550px" }}
            center={center}
            zoom={zoom}
            onClick={(e) => handleMapClick(e.latLng)}
            onLoad={(map) => handleLoadMap(map)}
            onUnmount={() => handleUnmountMap()}
            options={{
              fullscreenControl: false,
              scaleControl: true,
              mapTypeControl: true,
              tilt: 0,
              minZoom: GOOGLE_MAPS_MIN_ZOOM,
              styles: [
                {
                  featureType: "poi",
                  elementType: "labels",
                  stylers: [{ visibility: "off" }],
                },
              ],
              mapTypeControlOptions: {
                position: GOOGLE_MAPS_BOTTOM_LEFT,
                style: GOOGLE_MAPS_HORIZONTAL_BAR,
                mapTypeIds: [GOOGLE_MAPS_ROAD_MAP, GOOGLE_MAPS_SATELLITE],
              },
              restriction: {
                latLngBounds: {
                  east: 180,
                  north: 85,
                  south: -85,
                  west: -180,
                },
                strictBounds: true,
              },
            }}
          >
            {props.geofenceEnabled && props.geofencePoints.length > 0 && (
              <Polygon
                options={{
                  paths: props.geofencePoints.map((geofencePoint) => {
                    return { lat: geofencePoint.latitude, lng: geofencePoint.longitude };
                  }),
                  strokeColor: "#3957d9",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: "#3957d9",
                  fillOpacity: 0.35,
                }}
              />
            )}
            {props.showMapAssets &&
              props.assetPoints.map((assetPoint) => (
                <Marker
                  key={assetPoint.assetId}
                  position={{ lat: parseFloat(assetPoint.latitude), lng: parseFloat(assetPoint.longitude) }}
                  title={assetPoint.name}
                  icon={{
                    url: "/mapMarkers/mapMarkerGearActive.png",
                    size: new google.maps.Size(40, 48),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(20, 48),
                    scaledSize: new google.maps.Size(40, 48),
                  }}
                />
              ))}
            {props.geofencePoints.map((geofencePoint) => (
              <Marker
                key={geofencePoint.geofencePointId}
                position={{ lat: geofencePoint.latitude, lng: geofencePoint.longitude }}
                title={`${geofencePoint.geofencePointId}`}
                icon={
                  props.geofenceEnabled
                    ? {
                        url: "/mapMarkers/mapMarkerGeofenceEnabled.png",
                        size: new google.maps.Size(15, 15),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(8, 8),
                        scaledSize: new google.maps.Size(15, 15),
                      }
                    : {
                        url: "/mapMarkers/mapMarkerGeofenceDisabled.png",
                        size: new google.maps.Size(15, 15),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(8, 8),
                        scaledSize: new google.maps.Size(15, 15),
                      }
                }
                draggable={true}
                onDrag={(mapMouseEvent) => dragGeofencePoint(geofencePoint, mapMouseEvent.latLng)}
              />
            ))}
          </GoogleMap>
        )}
      </div>
    </div>
  );
}

GeofenceMap.propTypes = {
  assetPoints: PropTypes.array.isRequired,
  showMapAssets: PropTypes.bool.isRequired,
  geofencePoints: PropTypes.array.isRequired,
  previousGeofencePoints: PropTypes.array.isRequired,
  geofenceEnabled: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onUpdatePoint: PropTypes.func.isRequired,
  onCreatePoint: PropTypes.func.isRequired,
};

interface Props {
  assetPoints: GeofenceAsset[];
  showMapAssets: boolean;
  geofencePoints: GeofencePoint[];
  previousGeofencePoints: GeofencePoint[];
  geofenceEnabled: boolean;
  disabled: boolean;
  onUpdatePoint: (geofencePointId: number, latitude: number, longitude: number) => void;
  onCreatePoint: (latitude: number, longitude: number) => void;
}

interface Point {
  latitude: number;
  longitude: number;
}

interface ShortPoint {
  lat: number;
  lng: number;
}

interface GeofencePoint {
  geofencePointId: number;
  latitude: number;
  longitude: number;
}

interface GeofenceAsset {
  assetId: number;
  name: string;
  latitude: string;
  longitude: string;
}
