// --------------------------------------------------------------
// Created On: 2023-02-17
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect } from "react";
import Spinner from "../../components/Spinner/Spinner";
import useApi from "../../hooks/useApi";
import Error from "../../components/Error/Error";
import Card from "../../components/Card/Card";
import SearchAssetList from "./SearchAssetList/SearchAssetList";
import apiRequest from "../../utilities/api/apiRequest";
import getApiError from "../../utilities/api/getApiError";
import { RPM_COMPANY } from "../../constants/miscellaneous";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCurrentUser } from "../../redux/selectors";
import { API, PRODUCT_MANUFACTURERS } from "../../constants/miscellaneous";
import styles from "./SearchPage.module.scss";

// Page for searching assets.
export default function SearchPage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [assetName, setAssetName] = useState<string>("");
  const [assetNickname, setAssetNickname] = useState<string>("");
  const [productType, setProductType] = useState<string>("");
  const [productManufacturer, setProductManufacturer] = useState<string>("");
  const [productModel, setProductModel] = useState<string>("");
  const [productIdentifier, setProductIdentifier] = useState<string>("");
  const [controllerModel, setControllerModel] = useState<string>("");
  const [deviceModel, setDeviceModel] = useState<string>("");
  const [deviceIdentifier, setDeviceIdentifier] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [assets, setAssets] = useState<Asset[]>([]);
  const [companyId, setCompanyId] = useState<number>(0);
  const [productTypes, setProductTypes] = useState<string[]>([]);
  const [controllers, setControllers] = useState<ControllerSelection[]>([]);
  const [deviceTypes, setDeviceTypes] = useState<DeviceTypeSelection[]>([]);
  const { initialSearchQuery } = useParams<Params>();
  const currentUser = useSelector(getCurrentUser);

  // If the user got to this page by using a simple search bar, start with a simple search.
  useApi(
    () => {
      if (initialSearchQuery !== undefined && initialSearchQuery !== "advanced") {
        setAssetName(initialSearchQuery);
        setLoading(true);
        return true;
      } else {
        return false;
      }
    },
    {
      method: "POST",
      url: `${API}/company/${currentUser.companyName === RPM_COMPANY ? 0 : currentUser.companyId}/asset/search`,
      body: {
        assetId: null,
        assetName: initialSearchQuery,
        assetNickname: null,
        productType: null,
        productManufacturer: null,
        productModel: null,
        productIdentifier: null,
        controllerModel: null,
        controllerManufacturer: null,
        deviceModel: null,
        deviceIdentifier: null,
      },
    },
    async (response: Response, responseBody: PostResponseBody) => {
      if (response.ok && responseBody) {
        setAssets(responseBody.assets);
        setErrorMessage("");
      } else {
        setErrorMessage(await getApiError(response, "Unable to search assets."));
      }
      setLoading(false);
    },
    [initialSearchQuery]
  );

  // Get data for select and data list options.
  useApi(
    () => {
      return true;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/asset/search/map`,
    },
    async (response: Response, responseBody: GetResponseBody) => {
      if (response.ok && responseBody) {
        setProductTypes(responseBody.productTypes);
        setControllers(responseBody.controllers);
        setDeviceTypes(responseBody.deviceTypes);
        setErrorMessage("");
      } else {
        setErrorMessage("Internal server error. Unable to get form data.");
      }
      setLoading(false);
    },
    []
  );

  // Get the user's company ID. In the case of the RPM company, set company ID to 0 to search all companies.
  useEffect(() => {
    if (currentUser.companyName === RPM_COMPANY) {
      setCompanyId(0);
    } else {
      setCompanyId(currentUser.companyId);
    }
  }, [JSON.stringify(currentUser)]);

  // Search for assets.
  async function assetSearch(): Promise<void> {
    if (!loading) {
      const requestBody = {
        assetId: null,
        assetName: assetName.length ? assetName : null,
        assetNickname: assetNickname.length ? assetNickname : null,
        productType: productType.length ? productType : null,
        productManufacturer: productManufacturer.length ? productManufacturer : null,
        productModel: productModel.length ? productModel : null,
        productIdentifier: productIdentifier.length ? productIdentifier : null,
        controllerModel: controllerModel.length ? controllerModel : null,
        controllerManufacturer: null,
        deviceModel: deviceModel.length ? deviceModel : null,
        deviceIdentifier: deviceIdentifier.length ? deviceIdentifier : null,
      };

      setLoading(true);
      const [response, responseBody] = (await apiRequest(
        `${API}/company/${companyId}/asset/search`,
        "POST",
        requestBody
      )) as [Response, PostResponseBody];
      setLoading(false);

      if (response.ok && responseBody) {
        setAssets(responseBody.assets);
        setErrorMessage("");
      } else {
        setErrorMessage(await getApiError(response, "Unable to search assets."));
      }
    }
  }

  return (
    <div className={`${styles.body} p-4`}>
      <Spinner loading={loading} />
      <Card title="Asset Search">
        <div className="m-4">
          <div className={`${styles.searchInputRow} row`}>
            <div className="mb-1 col-12 col-sm-6">
              <label className="mb-1">Asset Name</label>
              <input
                className={`${styles.searchInputField} form-control mx-auto`}
                type="text"
                value={assetName}
                onChange={(e) => setAssetName(e.target.value)}
              />
            </div>

            <div className="mb-1 col-12 col-sm-6">
              <label className="mb-1">Asset Nickname</label>
              <input
                className={`${styles.searchInputField} form-control mx-auto`}
                type="text"
                value={assetNickname}
                onChange={(e) => setAssetNickname(e.target.value)}
              />
            </div>
          </div>

          <div className={`${styles.searchInputRow} row`}>
            <div className="mb-1 col-12 col-sm-6">
              <label className="mb-1">Product Type</label>
              <select
                className={`${styles.searchInputField} form-select mx-auto`}
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
              >
                <option value="">Any</option>
                {productTypes.map((productType) => (
                  <option key={productType} value={productType}>
                    {productType}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-1 col-12 col-sm-6">
              <label className="mb-1">Product Manufacturer</label>
              <select
                className={`${styles.searchInputField} form-select mx-auto`}
                value={productManufacturer}
                onChange={(e) => setProductManufacturer(e.target.value)}
              >
                <option value="">Any</option>
                {PRODUCT_MANUFACTURERS.map((productManufacturer) => (
                  <option key={productManufacturer.code} value={productManufacturer.code}>
                    {productManufacturer.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className={`${styles.searchInputRow} row`}>
            <div className="mb-1 col-12 col-sm-6">
              <label className="mb-1">Product Part Number</label>
              <input
                className={`${styles.searchInputField} form-control mx-auto`}
                type="text"
                value={productModel}
                onChange={(e) => setProductModel(e.target.value)}
              />
            </div>

            <div className="mb-1 col-12 col-sm-6">
              <label className="mb-1">Product Serial Number</label>
              <input
                className={`${styles.searchInputField} form-control mx-auto`}
                type="text"
                value={productIdentifier}
                onChange={(e) => setProductIdentifier(e.target.value)}
              />
            </div>
          </div>

          <div className={`${styles.searchInputRow} row`}>
            <div className="mb-1 col-12 col-sm-6">
              <label className="mb-1">Monitoring Device Type</label>
              <select
                className={`${styles.searchInputField} form-select mx-auto`}
                value={deviceModel}
                onChange={(e) => setDeviceModel(e.target.value)}
              >
                <option value="">Any</option>
                {deviceTypes.map((deviceType) => (
                  <option key={deviceType.model} value={deviceType.model}>
                    {deviceType.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-1 col-12 col-sm-6">
              <label className="mb-1">Monitoring Device Identifier</label>
              <input
                className={`${styles.searchInputField} form-control mx-auto`}
                type="text"
                value={deviceIdentifier}
                onChange={(e) => setDeviceIdentifier(e.target.value)}
              />
            </div>
          </div>

          <div className={`${styles.searchInputRow} row`}>
            <div className="mb-1 col-12 col-sm-6">
              <label className="mb-1">Controller</label>
              <select
                className={`${styles.searchInputField} form-select mx-auto`}
                value={controllerModel}
                onChange={(e) => setControllerModel(e.target.value)}
              >
                <option value="">Any</option>
                {controllers.map((controller) => (
                  <option key={controller.model} value={controller.model}>
                    {controller.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <button
            type="button"
            className={`${styles.searchButton} btn btn-primary mx-auto my-3`}
            disabled={loading}
            onClick={() => assetSearch()}
          >
            Search
          </button>

          <Error message={errorMessage} />
        </div>
      </Card>
      <SearchAssetList loading={loading} assets={assets} crossCompany={currentUser.companyName === RPM_COMPANY} />
    </div>
  );
}

type Params = {
  initialSearchQuery: string;
};

interface PostResponseBody {
  assets: Asset[];
}

interface Asset {
  assetId: number;
  name: string;
  nickname: string;
  productType: string;
  productModel: string;
  productIdentifier: string;
  companyId: number;
  companyName: string;
}

interface GetResponseBody {
  productTypes: string[];
  productManufacturers: string[];
  controllers: ControllerSelection[];
  deviceTypes: DeviceTypeSelection[];
}

interface ControllerSelection {
  model: string;
  name: string;
}

interface DeviceTypeSelection {
  model: string;
  name: string;
}
