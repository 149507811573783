// --------------------------------------------------------------
// Created On: 2022-06-21
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useMemo } from "react";
import Chart from "react-apexcharts";
import formatDateShortLocal from "../../utilities/time/formatDateShortLocal";
import { ApexOptions } from "apexcharts";
import { BREAKPOINT_SMALL } from "../../constants/breakpoints";
import PropTypes from "prop-types";

// Line chart for viewing data.
export default function LineChart(props: Props): Component {
  const defaultColors = ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"];
  const options = useMemo(() => getOptions(props.series as TimeDataSeries[]), [JSON.stringify(props.series)]);

  // Get download file name based on if there are multiple series or not.
  function getFileName(series: TimeDataSeries[]): string {
    if (series.length === 1) {
      return `${series[0].name} History`;
    } else {
      return "Attribute History";
    }
  }

  // Get the options needed to configure the line chart.
  function getOptions(series: TimeDataSeries[]): ApexOptions | null {
    const yAxes: ApexYAxis[] = [];
    series.forEach((singleSeries, i) => {
      const showColoredAxis = i < defaultColors.length && series.length > 1;
      yAxes.push({
        seriesName: singleSeries.name,
        opposite: i % 2 !== 0,
        max: verticalBorderMax(singleSeries),
        min: verticalBorderMin(singleSeries),
        labels: {
          formatter: (value) => {
            return `${
              typeof value === "string" ? parseFloat(parseFloat(value).toFixed(4)) : parseFloat(value.toFixed(4))
            } ${singleSeries.unitShortName === null ? "" : singleSeries.unitShortName}`;
          },
          style: {
            colors: showColoredAxis ? defaultColors[i] : "#000000",
          },
        },
        axisBorder: {
          show: showColoredAxis,
          color: showColoredAxis ? defaultColors[i] : "#000000",
        },
      });
    });

    // Support cached data annotations.
    const pointAnnotations: PointAnnotations[] = [];
    if (props.series.length > 0 && props.series[0].dataMarkers !== undefined) {
      props.series[0].dataMarkers.forEach((marker) => {
        // Get timestamp.
        const timestamp = props.series[0].data[marker.dataPointIndex][0];

        // Don't allow value to be a string.
        let value = props.series[0].data[marker.dataPointIndex][1];
        if (typeof value === "string") {
          value = parseFloat(value);
          if (typeof value === "string") {
            value = 0;
          }
        }

        pointAnnotations.push({
          x: timestamp,
          y: value,
          marker: {
            size: 6,
            fillColor: "var(--white)",
            strokeColor: "var(--bs-warning)",
            radius: 2,
          },
        });
      });
    }

    // Set general options.
    if (series !== null && series.length > 0) {
      return {
        chart: {
          height: 500,
          type: "line",
          zoom: {
            enabled: true,
            type: "x",
            autoScaleYaxis: false,
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: true,
              reset: true,
            },
            export: {
              csv: {
                filename: getFileName(props.series as TimeDataSeries[]),
                columnDelimiter: ",",
                headerCategory: `${Intl.DateTimeFormat().resolvedOptions().timeZone} Time`,
                headerValue: "value",
                dateFormatter(timestamp: number | undefined) {
                  if (timestamp === undefined) {
                    return "";
                  } else {
                    const date = new Date(timestamp);
                    return formatDateShortLocal(date.toISOString().split(".")[0]).replace(",", "");
                  }
                },
              },
              svg: {
                filename: getFileName(props.series as TimeDataSeries[]),
              },
              png: {
                filename: getFileName(props.series as TimeDataSeries[]),
              },
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["var(--chart-background)", "var(--chart-background-alternative)"],
            opacity: 1,
          },
          padding: {
            left: 5,
            right: 0,
          },
        },
        xaxis: {
          type: "datetime",
          tickAmount: 12,
          labels: {
            datetimeUTC: true,
            rotate: -15,
            rotateAlways: true,
            formatter: (value: string) => {
              const date = new Date(value);
              return formatDateShortLocal(date.toISOString().split(".")[0]);
            },
          },
        },
        yaxis: yAxes,
        tooltip: {
          shared: true,
        },
        annotations: {
          points: pointAnnotations,
        },
        responsive: [
          {
            breakpoint: BREAKPOINT_SMALL,
            options: {
              yaxis: {
                show: false,
                labels: {
                  offsetX: 0,
                },
              },
              grid: {
                row: {
                  colors: ["var(--chart-background)", "var(--chart-background-alternative)"],
                  opacity: 1,
                },
                padding: {
                  left: -100,
                  right: -100,
                },
              },
            },
          },
        ],
      };
    } else {
      return null;
    }
  }

  // Calculate max y-axis border value.
  function verticalBorderMax(series: TimeDataSeries): number {
    let maxValue: number | null = null;
    // Find the max value.
    series.data.forEach((dataPoint) => {
      let value = dataPoint[1];
      if (typeof value === "string") {
        value = parseFloat(value as string);
      }
      if (maxValue === null || value > maxValue) {
        maxValue = value;
      }
    });

    // Add a buffer to the max value.
    if (maxValue === null) {
      maxValue = 0;
    } else if (maxValue >= -0.00000001 && maxValue < 0.00000001) {
      maxValue = 0.25;
    } else {
      maxValue = parseFloat(((maxValue as number) + Math.abs(maxValue) * 0.25).toFixed(4));
    }

    return maxValue;
  }

  // Calculate min y-axis border value.
  function verticalBorderMin(series: TimeDataSeries): number {
    let minValue: number | null = null;
    // Find the min value.
    series.data.forEach((dataPoint) => {
      let value = dataPoint[1] as number | string;
      if (typeof value === "string") {
        value = parseFloat(value);
      }
      if (minValue === null || value < minValue) {
        minValue = value;
      }
    });

    // Add a buffer to the min value.
    if (minValue === null) {
      minValue = 0;
    } else if (minValue <= 0.00000001 && minValue > -0.00000001) {
      minValue = -0.25;
    } else {
      const offset = parseFloat((minValue - Math.abs(minValue) * 0.25).toFixed(4));
      if (minValue > 0 && offset < 0) {
        minValue = 0;
      } else {
        minValue = offset;
      }
    }

    return minValue;
  }

  return options ? (
    <Chart
      options={options}
      series={props.series === null ? [] : (props.series as TimeDataSeries[])}
      type="line"
      height={500}
    />
  ) : null;
}

LineChart.propTypes = {
  series: PropTypes.array.isRequired,
};

interface Props {
  series: VariousTimeDataSeries[];
}

interface VariousTimeDataSeries {
  name: string;
  unitName: string;
  unitShortName: string;
  data: [number, number | string][];
  dataMarkers?: DataMarker[];
}

interface DataMarker {
  seriesIndex: number;
  dataPointIndex: number;
}

interface TimeDataSeries {
  name: string;
  unitName: string;
  unitShortName: string;
  data: [number, number][];
}
