// --------------------------------------------------------------
// Last Modified: 2024-12-24
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./InfoBanner.module.scss";

// Info message.
export default function InfoBanner(props: Props): Component {
  return props.message.length > 0 ? (
    <div data-test="info-banner" className={`${styles.body} alert alert-info`} role="alert">
      <div>{props.message}</div>
      {props.children !== undefined && props.children}
    </div>
  ) : null;
}

InfoBanner.propTypes = {
  message: PropTypes.string.isRequired,
  children: PropTypes.any,
};

interface Props {
  message: string;
  children?: Children;
}
