// --------------------------------------------------------------
// Created On: 2021-12-14
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import { combineReducers } from "redux";
import deepCopy from "../utilities/deepCopy";
import {
  SET_AUTHORIZER_TOKEN,
  SET_ACCESS_TOKEN,
  SET_CURRENT_USER,
  SET_ALERTS,
  TOGGLE_ALERT,
  TOGGLE_ALL_ALERTS,
  DELETE_ALERT,
  DELETE_ALL_ALERTS,
  SET_DEVICE_ATTRIBUTES,
  TOGGLE_DEVICE_ATTRIBUTE,
} from "./actions";

// Stores authorization data when the current user logs in.
function authorizerTokenReducer(state = "", action: SetAuthorizerTokenAction): string {
  switch (action.type) {
    case SET_AUTHORIZER_TOKEN:
      localStorage.setItem("reduxAuthorizerToken", action.payload.token);
      return action.payload.token;
    default:
      return state;
  }
}

// Stores access data when the current user logs in.
function accessTokenReducer(state = "", action: SetAccessTokenAction): string {
  switch (action.type) {
    case SET_ACCESS_TOKEN:
      localStorage.setItem("reduxAccessToken", action.payload.token);
      return action.payload.token;
    default:
      return state;
  }
}

// Stores user data when the current user logs in.
const initialCurrentUser: CurrentUser = {
  userId: 0,
  userName: "",
  emailAddress: "",
  companyId: 0,
  companyName: "",
  isCompanyHidden: true,
  permissions: [],
  isPackager: false,
};
function currentUserReducer(state = initialCurrentUser, action: SetCurrentUserAction): CurrentUser {
  switch (action.type) {
    case SET_CURRENT_USER:
      localStorage.setItem("reduxCurrentUser", JSON.stringify(action.payload));
      return action.payload;
    default:
      return state;
  }
}

// Manages alerts that are shown as notifications to the current user in the navbar.
function alertReducer(state: AlertMessage[] = [], action: ReduxAlertAction): AlertMessage[] {
  switch (action.type) {
    case SET_ALERTS: {
      const payload = <SetAlertsPayload>action.payload;
      const alertsDeepCopy = deepCopy(payload.alerts);
      return alertsDeepCopy as AlertMessage[];
    }

    case TOGGLE_ALERT: {
      const payload = <ToggleAlertPayload>action.payload;
      const alertsDeepCopy = deepCopy(state);

      const targetAlert = alertsDeepCopy.find((alert) => alert.alertMessageId === payload.alertMessageId);

      if (targetAlert !== undefined) {
        targetAlert.unread = !targetAlert.unread;
      }

      return alertsDeepCopy;
    }

    case TOGGLE_ALL_ALERTS: {
      const alertsDeepCopy = deepCopy(state);

      let allRead = true;
      alertsDeepCopy.forEach((alert) => {
        if (alert.unread) {
          allRead = false;
        }
      });

      alertsDeepCopy.forEach((alert) => (alert.unread = allRead));

      return alertsDeepCopy;
    }

    case DELETE_ALERT: {
      const payload = <DeleteAlertPayload>action.payload;
      const alertsDeepCopy = deepCopy(state);

      const alertMessageIndex = alertsDeepCopy.findIndex((alert) => alert.alertMessageId === payload.alertMessageId);
      if (alertMessageIndex !== -1) {
        alertsDeepCopy.splice(alertMessageIndex, 1);
      }

      return alertsDeepCopy;
    }

    case DELETE_ALL_ALERTS: {
      return [];
    }

    default:
      return state;
  }
}

// Manages attributes that can be selected for a monitoring device on a graph of historical data.
function deviceAttributeReducer(state: DeviceAttribute[] = [], action: ReduxDeviceAttributeAction): DeviceAttribute[] {
  switch (action.type) {
    case SET_DEVICE_ATTRIBUTES: {
      const payload = <SetDeviceAttributesPayload>action.payload;
      const attributesDeepCopy = deepCopy(payload.attributes);

      const checkedAttributesText = localStorage.getItem("reduxDeviceAttributes");
      if (checkedAttributesText !== null) {
        try {
          const checkedAttributes = JSON.parse(checkedAttributesText) as string[];

          // Add data about if each attribute has been checked previously.
          attributesDeepCopy.forEach((attribute) => {
            if (checkedAttributes !== null && checkedAttributes.includes(attribute.code)) {
              attribute.checked = true;
            } else {
              attribute.checked = false;
            }
          });
        } catch (e) {
          console.error(e);
          console.error("Unable to get checked attributes from local storage.");
        }
      }

      return attributesDeepCopy;
    }

    case TOGGLE_DEVICE_ATTRIBUTE: {
      const payload = <ToggleDeviceAttributePayload>action.payload;
      const attributesDeepCopy = deepCopy(state);
      const checkedAttributesText = localStorage.getItem("reduxDeviceAttributes");
      let checkedAttributes: string[] = [];
      if (checkedAttributesText === null) {
        checkedAttributes = [];
      } else {
        checkedAttributes = JSON.parse(checkedAttributesText) as string[];
      }

      // Check what action to perform based on the current state of the attribute.
      const targetAttribute = attributesDeepCopy.find((attribute) => attribute.code === payload.code);

      if (targetAttribute === undefined) {
        return attributesDeepCopy;
      } else if (targetAttribute.checked) {
        // This attribute needs to be unchecked.
        targetAttribute.checked = false;
        const index = checkedAttributes.indexOf(targetAttribute.code);
        if (index > -1) {
          checkedAttributes.splice(index, 1);
        }
      } else {
        // This attribute needs to be checked.
        targetAttribute.checked = true;
        if (!checkedAttributes.includes(payload.code)) {
          checkedAttributes.push(payload.code);
        }
      }

      localStorage.setItem("reduxDeviceAttributes", JSON.stringify(checkedAttributes));
      return attributesDeepCopy;
    }

    default:
      return state;
  }
}

const rootReducer = combineReducers({
  authorizerToken: authorizerTokenReducer,
  accessToken: accessTokenReducer,
  currentUser: currentUserReducer,
  alerts: alertReducer,
  deviceAttributes: deviceAttributeReducer,
});
export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;

interface AlertMessage {
  alertMessageId: number;
  alertCode: string;
  date: string;
  message: string;
  unread: boolean;
  urgency: string;
}
