// --------------------------------------------------------------
// Created On: 2024-12-11
// Author: Hannah Vaughan
//
// Last Modified: 2024-12-31
// Modified By: Hannah Vaughan
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./CompanyDeviceForm.module.scss";
import { MAX_DEVICE_IDENTIFIER_LENGTH } from "../../../../constants/miscellaneous";

// Form for adding an associated device to a company.
export default function CompanyDeviceForm(props: Props): Component {
  return (
    <div data-test="company-device-block" className={`${styles.body} my-3 mb-0`}>
      <div className="row align-items-center">
        <div className="col-2 col-lg-2 col-xl-2">
          <label className={`${styles.title} mb-2`}>New Device:</label>
        </div>
        <div className="col-6 col-lg-6 col-xl-7">
          <div className="row">
            <div className="col">
              <select
                className="form-select"
                value={props.deviceTypeId}
                onChange={(e) => props.onChangeDeviceTypeId(parseInt(e.target.value))}
              >
                <option value={-1} disabled>
                  Please select a device type...
                </option>
                {props.deviceTypes.map((deviceType) => (
                  <option key={deviceType.deviceTypeId} value={deviceType.deviceTypeId}>
                    {deviceType.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <input
                data-test="new-device-name-input"
                className="form-control mx-auto"
                type="text"
                maxLength={MAX_DEVICE_IDENTIFIER_LENGTH}
                value={props.deviceIdentifier}
                onChange={(e) => props.onChangeDeviceIdentifier(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="col-4 col-lg-4 col-xl-3">
          <button
            data-test="company-delete-new-device-button"
            type="button"
            className={`${styles.button} btn btn-danger me-3 float-end`}
            onClick={() => props.onChangeShowNewDeviceForm(false)}
          >
            <i className="d-inline fa fa-fw fa-times fa-xs" />
          </button>
          <button
            data-test="company-add-device-button"
            type="button"
            className={`${styles.button} btn btn-success me-3 float-end`}
            onClick={() => props.onAddDevice()}
          >
            <span className="d-none d-lg-inline">Add Device</span>
            <i className="d-inline d-lg-none fa fa-fw fa-plus fa-xs" />
          </button>
        </div>
      </div>
    </div>
  );
}

CompanyDeviceForm.propTypes = {
  deviceTypes: PropTypes.any,
  showNewDeviceForm: PropTypes.bool.isRequired,
  deviceTypeId: PropTypes.number.isRequired,
  deviceIdentifier: PropTypes.string.isRequired,
  onChangeShowNewDeviceForm: PropTypes.func.isRequired,
  onChangeDeviceTypeId: PropTypes.func.isRequired,
  onChangeDeviceIdentifier: PropTypes.func.isRequired,
  onAddDevice: PropTypes.func.isRequired,
};

interface Props {
  deviceTypes: DeviceType[];
  showNewDeviceForm: boolean;
  deviceTypeId: number;
  deviceIdentifier: string;
  onChangeShowNewDeviceForm: (showNewDeviceForm: boolean) => void;
  onChangeDeviceTypeId: (deviceTypeId: number) => void;
  onChangeDeviceIdentifier: (deviceIdentifier: string) => void;
  onAddDevice: () => void;
}

interface DeviceType {
  deviceTypeId: number;
  name: string;
}
