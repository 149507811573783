// --------------------------------------------------------------
// Created On: 2024-12-20
// Author: Dimitra Weinstein
//
// Last Modified: 2025-01-07
// Modified By: Dimitra Weinstein
//
// Copyright 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Receives a CSV string and a file name, and initiates a download of the CSV file.
export default function downloadCsvFile(csvString: string, fileName: string): void {
  const blob = new Blob([csvString], { type: "text/csv" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
