// --------------------------------------------------------------
// Created On: 2023-06-13
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import {
  MAX_ASSET_FIELDS_LENGTH,
  PULSE_DEVICE_TYPE,
  COPILOT_DEVICE_TYPE,
  COPILOT_EDGE_DEVICE_TYPE,
  SEEKER_C_DEVICE_TYPE,
  SEEKER_SOLAR_DEVICE_TYPE,
  COPILOT_PMG_DEVICE_TYPE,
} from "../../../constants/miscellaneous";
import {
  ANALOG_INPUT_ONE_ATTRIBUTE,
  ANALOG_INPUT_ONE_CONNECTED_ATTRIBUTE,
  ANALOG_INPUT_ONE_MIN_ATTRIBUTE,
  ANALOG_INPUT_ONE_SPAN_ATTRIBUTE,
  ANALOG_INPUT_ONE_MODE_ATTRIBUTE,
  ANALOG_INPUT_TWO_ATTRIBUTE,
  ANALOG_INPUT_TWO_CONNECTED_ATTRIBUTE,
  ANALOG_INPUT_TWO_MIN_ATTRIBUTE,
  ANALOG_INPUT_TWO_SPAN_ATTRIBUTE,
  ANALOG_INPUT_TWO_MODE_ATTRIBUTE,
  ANALOG_INPUT_THREE_ATTRIBUTE,
  ANALOG_INPUT_THREE_CONNECTED_ATTRIBUTE,
  ANALOG_INPUT_THREE_MIN_ATTRIBUTE,
  ANALOG_INPUT_THREE_SPAN_ATTRIBUTE,
  ANALOG_INPUT_THREE_MODE_ATTRIBUTE,
  ANALOG_INPUT_FOUR_ATTRIBUTE,
  ANALOG_INPUT_FOUR_CONNECTED_ATTRIBUTE,
  ANALOG_INPUT_FOUR_MIN_ATTRIBUTE,
  ANALOG_INPUT_FOUR_SPAN_ATTRIBUTE,
  ANALOG_INPUT_FOUR_MODE_ATTRIBUTE,
  ANALOG_INPUT_FIVE_ATTRIBUTE,
  ANALOG_INPUT_FIVE_CONNECTED_ATTRIBUTE,
  ANALOG_INPUT_FIVE_MIN_ATTRIBUTE,
  ANALOG_INPUT_FIVE_SPAN_ATTRIBUTE,
  ANALOG_INPUT_FIVE_MODE_ATTRIBUTE,
  ANALOG_INPUT_SIX_ATTRIBUTE,
  ANALOG_INPUT_SIX_CONNECTED_ATTRIBUTE,
  ANALOG_INPUT_SIX_MIN_ATTRIBUTE,
  ANALOG_INPUT_SIX_SPAN_ATTRIBUTE,
  ANALOG_INPUT_SIX_MODE_ATTRIBUTE,
  ANALOG_INPUT_SEVEN_ATTRIBUTE,
  ANALOG_INPUT_SEVEN_CONNECTED_ATTRIBUTE,
  ANALOG_INPUT_SEVEN_MIN_ATTRIBUTE,
  ANALOG_INPUT_SEVEN_SPAN_ATTRIBUTE,
  ANALOG_INPUT_SEVEN_MODE_ATTRIBUTE,
  ANALOG_INPUT_EIGHT_ATTRIBUTE,
  ANALOG_INPUT_EIGHT_CONNECTED_ATTRIBUTE,
  ANALOG_INPUT_EIGHT_MIN_ATTRIBUTE,
  ANALOG_INPUT_EIGHT_SPAN_ATTRIBUTE,
  ANALOG_INPUT_EIGHT_MODE_ATTRIBUTE,
  DIGITAL_INPUT_ONE_STATE_ATTRIBUTE,
  DIGITAL_INPUT_ONE_FLOW_ATTRIBUTE,
  DIGITAL_INPUT_ONE_ACCUMULATION_ATTRIBUTE,
  DIGITAL_INPUT_ONE_MODE_ATTRIBUTE,
  DIGITAL_INPUT_ONE_K_FACTOR_ATTRIBUTE,
  DIGITAL_INPUT_ONE_OFFSET_ATTRIBUTE,
  DIGITAL_INPUT_ONE_DEBOUNCE_ATTRIBUTE,
  DIGITAL_INPUT_ONE_UNITS_PER_PULSE_ATTRIBUTE,
  DIGITAL_INPUT_TWO_STATE_ATTRIBUTE,
  DIGITAL_INPUT_TWO_FLOW_ATTRIBUTE,
  DIGITAL_INPUT_TWO_ACCUMULATION_ATTRIBUTE,
  DIGITAL_INPUT_TWO_MODE_ATTRIBUTE,
  DIGITAL_INPUT_TWO_K_FACTOR_ATTRIBUTE,
  DIGITAL_INPUT_TWO_OFFSET_ATTRIBUTE,
  DIGITAL_INPUT_TWO_DEBOUNCE_ATTRIBUTE,
  DIGITAL_INPUT_TWO_UNITS_PER_PULSE_ATTRIBUTE,
  DIGITAL_INPUT_THREE_STATE_ATTRIBUTE,
  DIGITAL_INPUT_THREE_FLOW_ATTRIBUTE,
  DIGITAL_INPUT_THREE_ACCUMULATION_ATTRIBUTE,
  DIGITAL_INPUT_THREE_MODE_ATTRIBUTE,
  DIGITAL_INPUT_THREE_K_FACTOR_ATTRIBUTE,
  DIGITAL_INPUT_THREE_OFFSET_ATTRIBUTE,
  DIGITAL_INPUT_THREE_DEBOUNCE_ATTRIBUTE,
  DIGITAL_INPUT_THREE_UNITS_PER_PULSE_ATTRIBUTE,
  DIGITAL_INPUT_FOUR_STATE_ATTRIBUTE,
  DIGITAL_INPUT_FOUR_FLOW_ATTRIBUTE,
  DIGITAL_INPUT_FOUR_ACCUMULATION_ATTRIBUTE,
  DIGITAL_INPUT_FOUR_MODE_ATTRIBUTE,
  DIGITAL_INPUT_FOUR_K_FACTOR_ATTRIBUTE,
  DIGITAL_INPUT_FOUR_OFFSET_ATTRIBUTE,
  DIGITAL_INPUT_FOUR_DEBOUNCE_ATTRIBUTE,
  DIGITAL_INPUT_FOUR_UNITS_PER_PULSE_ATTRIBUTE,
} from "../../../constants/attributes";
import AnalogSensorSelect from "./AnalogSensorSelect/AnalogSensorSelect";
import DigitalSensorSelect from "./DigitalSensorSelect/DigitalSensorSelect";
import PropTypes from "prop-types";
import styles from "./MonitoringDeviceForm.module.scss";

// Form for selecting a monitoring device for an asset, and to configure the sensors for that monitoring device.
export default function MonitoringDeviceForm(props: Props): Component {
  return (
    <div data-test="monitoring-device-form-container" className={`${styles.body} mb-3`}>
      <div className={`${styles.header} px-2 mb-2`}>
        <label>Monitoring Device</label>
      </div>

      <div className="mb-3 mx-3">
        <label className="mb-3 me-2">Model</label>
        <select
          data-test="monitoring-device-model-selection"
          className="form-select"
          value={props.deviceType === null ? "" : props.deviceType}
          disabled={props.disabled}
          onChange={(e) => props.onChange(e.target.value, props.deviceIdentifier)}
        >
          <option value="">None</option>
          {props.deviceTypes.map((deviceType) => (
            <option key={deviceType.deviceTypeId} value={deviceType.model}>
              {deviceType.name}
            </option>
          ))}
        </select>
      </div>

      {props.deviceType !== null && (
        <div className="mb-3 mx-3">
          <label className="mb-3 me-2">
            <span>Identifier</span>
          </label>
          <input
            data-test="monitoring-device-id-input"
            className="form-control mx-auto"
            type="text"
            list="datalist-devices"
            autoComplete="off"
            maxLength={MAX_ASSET_FIELDS_LENGTH}
            value={props.deviceIdentifier === null ? "" : props.deviceIdentifier}
            disabled={props.disabled}
            onChange={(e) => props.onChange(props.deviceType, e.target.value)}
          />
          <datalist id="datalist-devices">
            {props.monitoringDevices !== undefined &&
              props.monitoringDevices.map((monitoringDevice) => (
                <option key={monitoringDevice.deviceId} value={monitoringDevice.deviceIdentifier} />
              ))}
          </datalist>
        </div>
      )}

      <div>
        {props.deviceType === PULSE_DEVICE_TYPE && (
          <img className={styles.image} src="/images/serial_number_pulse.jpg" alt="Pulse identifier." />
        )}

        {props.deviceType === SEEKER_C_DEVICE_TYPE && (
          <img className={styles.image} src="/images/serial_number_seeker_c.jpg" alt="Seeker C identifier." />
        )}

        {props.deviceType === SEEKER_SOLAR_DEVICE_TYPE && (
          <img className={styles.image} src="/images/serial_number_seeker_solar.jpg" alt="Seeker Solar identifier." />
        )}

        {props.deviceType === COPILOT_DEVICE_TYPE && (
          <div className="my-3 mx-3">
            <img className={styles.image} src="/images/serial_number_copilot.jpg" alt="Co-pilot identifier." />

            <AnalogSensorSelect
              sensorNumber={1}
              sourceAttribute={ANALOG_INPUT_ONE_ATTRIBUTE}
              sourceConnectedAttribute={ANALOG_INPUT_ONE_CONNECTED_ATTRIBUTE}
              sourceModeAttribute={ANALOG_INPUT_ONE_MODE_ATTRIBUTE}
              sourceMinAttribute={ANALOG_INPUT_ONE_MIN_ATTRIBUTE}
              sourceSpanAttribute={ANALOG_INPUT_ONE_SPAN_ATTRIBUTE}
              analogSensors={props.analogSensors}
              analogSensorMap={props.analogSensorMap}
              disabled={props.disabled}
              onChange={(
                analogSensorId,
                attributeId,
                attributeCode,
                attributeConnectedCode,
                attributeModeCode,
                attributeMinCode,
                attributeSpanCode
              ) =>
                props.onChangeAnalogSensor(
                  analogSensorId,
                  attributeId,
                  attributeCode,
                  attributeConnectedCode,
                  attributeModeCode,
                  attributeMinCode,
                  attributeSpanCode
                )
              }
            />

            <AnalogSensorSelect
              sensorNumber={2}
              sourceAttribute={ANALOG_INPUT_TWO_ATTRIBUTE}
              sourceConnectedAttribute={ANALOG_INPUT_TWO_CONNECTED_ATTRIBUTE}
              sourceModeAttribute={ANALOG_INPUT_TWO_MODE_ATTRIBUTE}
              sourceMinAttribute={ANALOG_INPUT_TWO_MIN_ATTRIBUTE}
              sourceSpanAttribute={ANALOG_INPUT_TWO_SPAN_ATTRIBUTE}
              analogSensors={props.analogSensors}
              analogSensorMap={props.analogSensorMap}
              disabled={props.disabled}
              onChange={(
                analogSensorId,
                attributeId,
                attributeCode,
                attributeConnectedCode,
                attributeModeCode,
                attributeMinCode,
                attributeSpanCode
              ) =>
                props.onChangeAnalogSensor(
                  analogSensorId,
                  attributeId,
                  attributeCode,
                  attributeConnectedCode,
                  attributeModeCode,
                  attributeMinCode,
                  attributeSpanCode
                )
              }
            />

            <AnalogSensorSelect
              sensorNumber={3}
              sourceAttribute={ANALOG_INPUT_THREE_ATTRIBUTE}
              sourceConnectedAttribute={ANALOG_INPUT_THREE_CONNECTED_ATTRIBUTE}
              sourceModeAttribute={ANALOG_INPUT_THREE_MODE_ATTRIBUTE}
              sourceMinAttribute={ANALOG_INPUT_THREE_MIN_ATTRIBUTE}
              sourceSpanAttribute={ANALOG_INPUT_THREE_SPAN_ATTRIBUTE}
              analogSensors={props.analogSensors}
              analogSensorMap={props.analogSensorMap}
              disabled={props.disabled}
              onChange={(
                analogSensorId,
                attributeId,
                attributeCode,
                attributeConnectedCode,
                attributeModeCode,
                attributeMinCode,
                attributeSpanCode
              ) =>
                props.onChangeAnalogSensor(
                  analogSensorId,
                  attributeId,
                  attributeCode,
                  attributeConnectedCode,
                  attributeModeCode,
                  attributeMinCode,
                  attributeSpanCode
                )
              }
            />

            <AnalogSensorSelect
              sensorNumber={4}
              sourceAttribute={ANALOG_INPUT_FOUR_ATTRIBUTE}
              sourceConnectedAttribute={ANALOG_INPUT_FOUR_CONNECTED_ATTRIBUTE}
              sourceModeAttribute={ANALOG_INPUT_FOUR_MODE_ATTRIBUTE}
              sourceMinAttribute={ANALOG_INPUT_FOUR_MIN_ATTRIBUTE}
              sourceSpanAttribute={ANALOG_INPUT_FOUR_SPAN_ATTRIBUTE}
              analogSensors={props.analogSensors}
              analogSensorMap={props.analogSensorMap}
              disabled={props.disabled}
              onChange={(
                analogSensorId,
                attributeId,
                attributeCode,
                attributeConnectedCode,
                attributeModeCode,
                attributeMinCode,
                attributeSpanCode
              ) =>
                props.onChangeAnalogSensor(
                  analogSensorId,
                  attributeId,
                  attributeCode,
                  attributeConnectedCode,
                  attributeModeCode,
                  attributeMinCode,
                  attributeSpanCode
                )
              }
            />

            <DigitalSensorSelect
              sensorNumber={1}
              sourceStateAttribute={DIGITAL_INPUT_ONE_STATE_ATTRIBUTE}
              sourceFlowAttribute={DIGITAL_INPUT_ONE_FLOW_ATTRIBUTE}
              sourceAccumulationAttribute={DIGITAL_INPUT_ONE_ACCUMULATION_ATTRIBUTE}
              sourceModeAttribute={DIGITAL_INPUT_ONE_MODE_ATTRIBUTE}
              sourceKFactorAttribute={DIGITAL_INPUT_ONE_K_FACTOR_ATTRIBUTE}
              sourceOffsetAttribute={DIGITAL_INPUT_ONE_OFFSET_ATTRIBUTE}
              sourceDebounceAttribute={DIGITAL_INPUT_ONE_DEBOUNCE_ATTRIBUTE}
              sourceUnitsPerPulseAttribute={DIGITAL_INPUT_ONE_UNITS_PER_PULSE_ATTRIBUTE}
              digitalSensors={props.digitalSensors}
              digitalSensorMap={props.digitalSensorMap}
              disabled={props.disabled}
              onChange={(
                digitalSensorId,
                attributeStateCode,
                attributeFlowCode,
                attributeAccumulationCode,
                attributeModeCode,
                attributeKFactorCode,
                attributeOffsetCode,
                attributeDebounceCode,
                attributeUnitsPerPulseCode,
                reportingDescription
              ) =>
                props.onChangeDigitalSensor(
                  digitalSensorId,
                  attributeStateCode,
                  attributeFlowCode,
                  attributeAccumulationCode,
                  attributeModeCode,
                  attributeKFactorCode,
                  attributeOffsetCode,
                  attributeDebounceCode,
                  attributeUnitsPerPulseCode,
                  reportingDescription
                )
              }
            />
          </div>
        )}

        {props.deviceType === COPILOT_EDGE_DEVICE_TYPE && (
          <div className="my-3 mx-3">
            <AnalogSensorSelect
              sensorNumber={1}
              sourceAttribute={ANALOG_INPUT_ONE_ATTRIBUTE}
              sourceConnectedAttribute={ANALOG_INPUT_ONE_CONNECTED_ATTRIBUTE}
              sourceModeAttribute={ANALOG_INPUT_ONE_MODE_ATTRIBUTE}
              sourceMinAttribute={ANALOG_INPUT_ONE_MIN_ATTRIBUTE}
              sourceSpanAttribute={ANALOG_INPUT_ONE_SPAN_ATTRIBUTE}
              analogSensors={props.analogSensors}
              analogSensorMap={props.analogSensorMap}
              disabled={props.disabled}
              onChange={(
                analogSensorId,
                attributeId,
                attributeCode,
                attributeConnectedCode,
                attributeModeCode,
                attributeMinCode,
                attributeSpanCode
              ) =>
                props.onChangeAnalogSensor(
                  analogSensorId,
                  attributeId,
                  attributeCode,
                  attributeConnectedCode,
                  attributeModeCode,
                  attributeMinCode,
                  attributeSpanCode
                )
              }
            />

            <AnalogSensorSelect
              sensorNumber={2}
              sourceAttribute={ANALOG_INPUT_TWO_ATTRIBUTE}
              sourceConnectedAttribute={ANALOG_INPUT_TWO_CONNECTED_ATTRIBUTE}
              sourceModeAttribute={ANALOG_INPUT_TWO_MODE_ATTRIBUTE}
              sourceMinAttribute={ANALOG_INPUT_TWO_MIN_ATTRIBUTE}
              sourceSpanAttribute={ANALOG_INPUT_TWO_SPAN_ATTRIBUTE}
              analogSensors={props.analogSensors}
              analogSensorMap={props.analogSensorMap}
              disabled={props.disabled}
              onChange={(
                analogSensorId,
                attributeId,
                attributeCode,
                attributeConnectedCode,
                attributeModeCode,
                attributeMinCode,
                attributeSpanCode
              ) =>
                props.onChangeAnalogSensor(
                  analogSensorId,
                  attributeId,
                  attributeCode,
                  attributeConnectedCode,
                  attributeModeCode,
                  attributeMinCode,
                  attributeSpanCode
                )
              }
            />

            <AnalogSensorSelect
              sensorNumber={3}
              sourceAttribute={ANALOG_INPUT_THREE_ATTRIBUTE}
              sourceConnectedAttribute={ANALOG_INPUT_THREE_CONNECTED_ATTRIBUTE}
              sourceModeAttribute={ANALOG_INPUT_THREE_MODE_ATTRIBUTE}
              sourceMinAttribute={ANALOG_INPUT_THREE_MIN_ATTRIBUTE}
              sourceSpanAttribute={ANALOG_INPUT_THREE_SPAN_ATTRIBUTE}
              analogSensors={props.analogSensors}
              analogSensorMap={props.analogSensorMap}
              disabled={props.disabled}
              onChange={(
                analogSensorId,
                attributeId,
                attributeCode,
                attributeConnectedCode,
                attributeModeCode,
                attributeMinCode,
                attributeSpanCode
              ) =>
                props.onChangeAnalogSensor(
                  analogSensorId,
                  attributeId,
                  attributeCode,
                  attributeConnectedCode,
                  attributeModeCode,
                  attributeMinCode,
                  attributeSpanCode
                )
              }
            />

            <AnalogSensorSelect
              sensorNumber={4}
              sourceAttribute={ANALOG_INPUT_FOUR_ATTRIBUTE}
              sourceConnectedAttribute={ANALOG_INPUT_FOUR_CONNECTED_ATTRIBUTE}
              sourceModeAttribute={ANALOG_INPUT_FOUR_MODE_ATTRIBUTE}
              sourceMinAttribute={ANALOG_INPUT_FOUR_MIN_ATTRIBUTE}
              sourceSpanAttribute={ANALOG_INPUT_FOUR_SPAN_ATTRIBUTE}
              analogSensors={props.analogSensors}
              analogSensorMap={props.analogSensorMap}
              disabled={props.disabled}
              onChange={(
                analogSensorId,
                attributeId,
                attributeCode,
                attributeConnectedCode,
                attributeModeCode,
                attributeMinCode,
                attributeSpanCode
              ) =>
                props.onChangeAnalogSensor(
                  analogSensorId,
                  attributeId,
                  attributeCode,
                  attributeConnectedCode,
                  attributeModeCode,
                  attributeMinCode,
                  attributeSpanCode
                )
              }
            />

            <DigitalSensorSelect
              sensorNumber={1}
              sourceStateAttribute={DIGITAL_INPUT_ONE_STATE_ATTRIBUTE}
              sourceFlowAttribute={DIGITAL_INPUT_ONE_FLOW_ATTRIBUTE}
              sourceAccumulationAttribute={DIGITAL_INPUT_ONE_ACCUMULATION_ATTRIBUTE}
              sourceModeAttribute={DIGITAL_INPUT_ONE_MODE_ATTRIBUTE}
              sourceKFactorAttribute={DIGITAL_INPUT_ONE_K_FACTOR_ATTRIBUTE}
              sourceOffsetAttribute={DIGITAL_INPUT_ONE_OFFSET_ATTRIBUTE}
              sourceDebounceAttribute={DIGITAL_INPUT_ONE_DEBOUNCE_ATTRIBUTE}
              sourceUnitsPerPulseAttribute={DIGITAL_INPUT_ONE_UNITS_PER_PULSE_ATTRIBUTE}
              digitalSensors={props.digitalSensors}
              digitalSensorMap={props.digitalSensorMap}
              disabled={props.disabled}
              onChange={(
                digitalSensorId,
                attributeStateCode,
                attributeFlowCode,
                attributeAccumulationCode,
                attributeModeCode,
                attributeKFactorCode,
                attributeOffsetCode,
                attributeDebounceCode,
                attributeUnitsPerPulseCode,
                reportingDescription
              ) =>
                props.onChangeDigitalSensor(
                  digitalSensorId,
                  attributeStateCode,
                  attributeFlowCode,
                  attributeAccumulationCode,
                  attributeModeCode,
                  attributeKFactorCode,
                  attributeOffsetCode,
                  attributeDebounceCode,
                  attributeUnitsPerPulseCode,
                  reportingDescription
                )
              }
            />

            <DigitalSensorSelect
              sensorNumber={2}
              sourceStateAttribute={DIGITAL_INPUT_TWO_STATE_ATTRIBUTE}
              sourceFlowAttribute={DIGITAL_INPUT_TWO_FLOW_ATTRIBUTE}
              sourceAccumulationAttribute={DIGITAL_INPUT_TWO_ACCUMULATION_ATTRIBUTE}
              sourceModeAttribute={DIGITAL_INPUT_TWO_MODE_ATTRIBUTE}
              sourceKFactorAttribute={DIGITAL_INPUT_TWO_K_FACTOR_ATTRIBUTE}
              sourceOffsetAttribute={DIGITAL_INPUT_TWO_OFFSET_ATTRIBUTE}
              sourceDebounceAttribute={DIGITAL_INPUT_TWO_DEBOUNCE_ATTRIBUTE}
              sourceUnitsPerPulseAttribute={DIGITAL_INPUT_TWO_UNITS_PER_PULSE_ATTRIBUTE}
              digitalSensors={props.digitalSensors}
              digitalSensorMap={props.digitalSensorMap}
              disabled={props.disabled}
              onChange={(
                digitalSensorId,
                attributeStateCode,
                attributeFlowCode,
                attributeAccumulationCode,
                attributeModeCode,
                attributeKFactorCode,
                attributeOffsetCode,
                attributeDebounceCode,
                attributeUnitsPerPulseCode,
                reportingDescription
              ) =>
                props.onChangeDigitalSensor(
                  digitalSensorId,
                  attributeStateCode,
                  attributeFlowCode,
                  attributeAccumulationCode,
                  attributeModeCode,
                  attributeKFactorCode,
                  attributeOffsetCode,
                  attributeDebounceCode,
                  attributeUnitsPerPulseCode,
                  reportingDescription
                )
              }
            />
          </div>
        )}

        {props.deviceType === COPILOT_PMG_DEVICE_TYPE && (
          <div className="my-3 mx-3">
            <img className={styles.image} src="/images/serial_number_pmg.jpg" alt="Co-pilot PMG identifier." />

            <AnalogSensorSelect
              sensorNumber={1}
              sourceAttribute={ANALOG_INPUT_ONE_ATTRIBUTE}
              sourceConnectedAttribute={ANALOG_INPUT_ONE_CONNECTED_ATTRIBUTE}
              sourceModeAttribute={ANALOG_INPUT_ONE_MODE_ATTRIBUTE}
              sourceMinAttribute={ANALOG_INPUT_ONE_MIN_ATTRIBUTE}
              sourceSpanAttribute={ANALOG_INPUT_ONE_SPAN_ATTRIBUTE}
              analogSensors={props.analogSensors}
              analogSensorMap={props.analogSensorMap}
              disabled={props.disabled}
              onChange={(
                analogSensorId,
                attributeId,
                attributeCode,
                attributeConnectedCode,
                attributeModeCode,
                attributeMinCode,
                attributeSpanCode
              ) =>
                props.onChangeAnalogSensor(
                  analogSensorId,
                  attributeId,
                  attributeCode,
                  attributeConnectedCode,
                  attributeModeCode,
                  attributeMinCode,
                  attributeSpanCode
                )
              }
            />

            <AnalogSensorSelect
              sensorNumber={2}
              sourceAttribute={ANALOG_INPUT_TWO_ATTRIBUTE}
              sourceConnectedAttribute={ANALOG_INPUT_TWO_CONNECTED_ATTRIBUTE}
              sourceModeAttribute={ANALOG_INPUT_TWO_MODE_ATTRIBUTE}
              sourceMinAttribute={ANALOG_INPUT_TWO_MIN_ATTRIBUTE}
              sourceSpanAttribute={ANALOG_INPUT_TWO_SPAN_ATTRIBUTE}
              analogSensors={props.analogSensors}
              analogSensorMap={props.analogSensorMap}
              disabled={props.disabled}
              onChange={(
                analogSensorId,
                attributeId,
                attributeCode,
                attributeConnectedCode,
                attributeModeCode,
                attributeMinCode,
                attributeSpanCode
              ) =>
                props.onChangeAnalogSensor(
                  analogSensorId,
                  attributeId,
                  attributeCode,
                  attributeConnectedCode,
                  attributeModeCode,
                  attributeMinCode,
                  attributeSpanCode
                )
              }
            />

            <AnalogSensorSelect
              sensorNumber={3}
              sourceAttribute={ANALOG_INPUT_THREE_ATTRIBUTE}
              sourceConnectedAttribute={ANALOG_INPUT_THREE_CONNECTED_ATTRIBUTE}
              sourceModeAttribute={ANALOG_INPUT_THREE_MODE_ATTRIBUTE}
              sourceMinAttribute={ANALOG_INPUT_THREE_MIN_ATTRIBUTE}
              sourceSpanAttribute={ANALOG_INPUT_THREE_SPAN_ATTRIBUTE}
              analogSensors={props.analogSensors}
              analogSensorMap={props.analogSensorMap}
              disabled={props.disabled}
              onChange={(
                analogSensorId,
                attributeId,
                attributeCode,
                attributeConnectedCode,
                attributeModeCode,
                attributeMinCode,
                attributeSpanCode
              ) =>
                props.onChangeAnalogSensor(
                  analogSensorId,
                  attributeId,
                  attributeCode,
                  attributeConnectedCode,
                  attributeModeCode,
                  attributeMinCode,
                  attributeSpanCode
                )
              }
            />

            <AnalogSensorSelect
              sensorNumber={4}
              sourceAttribute={ANALOG_INPUT_FOUR_ATTRIBUTE}
              sourceConnectedAttribute={ANALOG_INPUT_FOUR_CONNECTED_ATTRIBUTE}
              sourceModeAttribute={ANALOG_INPUT_FOUR_MODE_ATTRIBUTE}
              sourceMinAttribute={ANALOG_INPUT_FOUR_MIN_ATTRIBUTE}
              sourceSpanAttribute={ANALOG_INPUT_FOUR_SPAN_ATTRIBUTE}
              analogSensors={props.analogSensors}
              analogSensorMap={props.analogSensorMap}
              disabled={props.disabled}
              onChange={(
                analogSensorId,
                attributeId,
                attributeCode,
                attributeConnectedCode,
                attributeModeCode,
                attributeMinCode,
                attributeSpanCode
              ) =>
                props.onChangeAnalogSensor(
                  analogSensorId,
                  attributeId,
                  attributeCode,
                  attributeConnectedCode,
                  attributeModeCode,
                  attributeMinCode,
                  attributeSpanCode
                )
              }
            />

            <AnalogSensorSelect
              sensorNumber={5}
              sourceAttribute={ANALOG_INPUT_FIVE_ATTRIBUTE}
              sourceConnectedAttribute={ANALOG_INPUT_FIVE_CONNECTED_ATTRIBUTE}
              sourceModeAttribute={ANALOG_INPUT_FIVE_MODE_ATTRIBUTE}
              sourceMinAttribute={ANALOG_INPUT_FIVE_MIN_ATTRIBUTE}
              sourceSpanAttribute={ANALOG_INPUT_FIVE_SPAN_ATTRIBUTE}
              analogSensors={props.analogSensors}
              analogSensorMap={props.analogSensorMap}
              disabled={props.disabled}
              onChange={(
                analogSensorId,
                attributeId,
                attributeCode,
                attributeConnectedCode,
                attributeModeCode,
                attributeMinCode,
                attributeSpanCode
              ) =>
                props.onChangeAnalogSensor(
                  analogSensorId,
                  attributeId,
                  attributeCode,
                  attributeConnectedCode,
                  attributeModeCode,
                  attributeMinCode,
                  attributeSpanCode
                )
              }
            />

            <AnalogSensorSelect
              sensorNumber={6}
              sourceAttribute={ANALOG_INPUT_SIX_ATTRIBUTE}
              sourceConnectedAttribute={ANALOG_INPUT_SIX_CONNECTED_ATTRIBUTE}
              sourceModeAttribute={ANALOG_INPUT_SIX_MODE_ATTRIBUTE}
              sourceMinAttribute={ANALOG_INPUT_SIX_MIN_ATTRIBUTE}
              sourceSpanAttribute={ANALOG_INPUT_SIX_SPAN_ATTRIBUTE}
              analogSensors={props.analogSensors}
              analogSensorMap={props.analogSensorMap}
              disabled={props.disabled}
              onChange={(
                analogSensorId,
                attributeId,
                attributeCode,
                attributeConnectedCode,
                attributeModeCode,
                attributeMinCode,
                attributeSpanCode
              ) =>
                props.onChangeAnalogSensor(
                  analogSensorId,
                  attributeId,
                  attributeCode,
                  attributeConnectedCode,
                  attributeModeCode,
                  attributeMinCode,
                  attributeSpanCode
                )
              }
            />

            <AnalogSensorSelect
              sensorNumber={7}
              sourceAttribute={ANALOG_INPUT_SEVEN_ATTRIBUTE}
              sourceConnectedAttribute={ANALOG_INPUT_SEVEN_CONNECTED_ATTRIBUTE}
              sourceModeAttribute={ANALOG_INPUT_SEVEN_MODE_ATTRIBUTE}
              sourceMinAttribute={ANALOG_INPUT_SEVEN_MIN_ATTRIBUTE}
              sourceSpanAttribute={ANALOG_INPUT_SEVEN_SPAN_ATTRIBUTE}
              analogSensors={props.analogSensors}
              analogSensorMap={props.analogSensorMap}
              disabled={props.disabled}
              onChange={(
                analogSensorId,
                attributeId,
                attributeCode,
                attributeConnectedCode,
                attributeModeCode,
                attributeMinCode,
                attributeSpanCode
              ) =>
                props.onChangeAnalogSensor(
                  analogSensorId,
                  attributeId,
                  attributeCode,
                  attributeConnectedCode,
                  attributeModeCode,
                  attributeMinCode,
                  attributeSpanCode
                )
              }
            />

            <AnalogSensorSelect
              sensorNumber={8}
              sourceAttribute={ANALOG_INPUT_EIGHT_ATTRIBUTE}
              sourceConnectedAttribute={ANALOG_INPUT_EIGHT_CONNECTED_ATTRIBUTE}
              sourceModeAttribute={ANALOG_INPUT_EIGHT_MODE_ATTRIBUTE}
              sourceMinAttribute={ANALOG_INPUT_EIGHT_MIN_ATTRIBUTE}
              sourceSpanAttribute={ANALOG_INPUT_EIGHT_SPAN_ATTRIBUTE}
              analogSensors={props.analogSensors}
              analogSensorMap={props.analogSensorMap}
              disabled={props.disabled}
              onChange={(
                analogSensorId,
                attributeId,
                attributeCode,
                attributeConnectedCode,
                attributeModeCode,
                attributeMinCode,
                attributeSpanCode
              ) =>
                props.onChangeAnalogSensor(
                  analogSensorId,
                  attributeId,
                  attributeCode,
                  attributeConnectedCode,
                  attributeModeCode,
                  attributeMinCode,
                  attributeSpanCode
                )
              }
            />

            <DigitalSensorSelect
              sensorNumber={1}
              sourceStateAttribute={DIGITAL_INPUT_ONE_STATE_ATTRIBUTE}
              sourceFlowAttribute={DIGITAL_INPUT_ONE_FLOW_ATTRIBUTE}
              sourceAccumulationAttribute={DIGITAL_INPUT_ONE_ACCUMULATION_ATTRIBUTE}
              sourceModeAttribute={DIGITAL_INPUT_ONE_MODE_ATTRIBUTE}
              sourceKFactorAttribute={DIGITAL_INPUT_ONE_K_FACTOR_ATTRIBUTE}
              sourceOffsetAttribute={DIGITAL_INPUT_ONE_OFFSET_ATTRIBUTE}
              sourceDebounceAttribute={DIGITAL_INPUT_ONE_DEBOUNCE_ATTRIBUTE}
              sourceUnitsPerPulseAttribute={DIGITAL_INPUT_ONE_UNITS_PER_PULSE_ATTRIBUTE}
              digitalSensors={props.digitalSensors}
              digitalSensorMap={props.digitalSensorMap}
              disabled={props.disabled}
              onChange={(
                digitalSensorId,
                attributeStateCode,
                attributeFlowCode,
                attributeAccumulationCode,
                attributeModeCode,
                attributeKFactorCode,
                attributeOffsetCode,
                attributeDebounceCode,
                attributeUnitsPerPulseCode,
                reportingDescription
              ) =>
                props.onChangeDigitalSensor(
                  digitalSensorId,
                  attributeStateCode,
                  attributeFlowCode,
                  attributeAccumulationCode,
                  attributeModeCode,
                  attributeKFactorCode,
                  attributeOffsetCode,
                  attributeDebounceCode,
                  attributeUnitsPerPulseCode,
                  reportingDescription
                )
              }
            />

            <DigitalSensorSelect
              sensorNumber={2}
              sourceStateAttribute={DIGITAL_INPUT_TWO_STATE_ATTRIBUTE}
              sourceFlowAttribute={DIGITAL_INPUT_TWO_FLOW_ATTRIBUTE}
              sourceAccumulationAttribute={DIGITAL_INPUT_TWO_ACCUMULATION_ATTRIBUTE}
              sourceModeAttribute={DIGITAL_INPUT_TWO_MODE_ATTRIBUTE}
              sourceKFactorAttribute={DIGITAL_INPUT_TWO_K_FACTOR_ATTRIBUTE}
              sourceOffsetAttribute={DIGITAL_INPUT_TWO_OFFSET_ATTRIBUTE}
              sourceDebounceAttribute={DIGITAL_INPUT_TWO_DEBOUNCE_ATTRIBUTE}
              sourceUnitsPerPulseAttribute={DIGITAL_INPUT_TWO_UNITS_PER_PULSE_ATTRIBUTE}
              digitalSensors={props.digitalSensors}
              digitalSensorMap={props.digitalSensorMap}
              disabled={props.disabled}
              onChange={(
                digitalSensorId,
                attributeStateCode,
                attributeFlowCode,
                attributeAccumulationCode,
                attributeModeCode,
                attributeKFactorCode,
                attributeOffsetCode,
                attributeDebounceCode,
                attributeUnitsPerPulseCode,
                reportingDescription
              ) =>
                props.onChangeDigitalSensor(
                  digitalSensorId,
                  attributeStateCode,
                  attributeFlowCode,
                  attributeAccumulationCode,
                  attributeModeCode,
                  attributeKFactorCode,
                  attributeOffsetCode,
                  attributeDebounceCode,
                  attributeUnitsPerPulseCode,
                  reportingDescription
                )
              }
            />

            <DigitalSensorSelect
              sensorNumber={3}
              sourceStateAttribute={DIGITAL_INPUT_THREE_STATE_ATTRIBUTE}
              sourceFlowAttribute={DIGITAL_INPUT_THREE_FLOW_ATTRIBUTE}
              sourceAccumulationAttribute={DIGITAL_INPUT_THREE_ACCUMULATION_ATTRIBUTE}
              sourceModeAttribute={DIGITAL_INPUT_THREE_MODE_ATTRIBUTE}
              sourceKFactorAttribute={DIGITAL_INPUT_THREE_K_FACTOR_ATTRIBUTE}
              sourceOffsetAttribute={DIGITAL_INPUT_THREE_OFFSET_ATTRIBUTE}
              sourceDebounceAttribute={DIGITAL_INPUT_THREE_DEBOUNCE_ATTRIBUTE}
              sourceUnitsPerPulseAttribute={DIGITAL_INPUT_THREE_UNITS_PER_PULSE_ATTRIBUTE}
              digitalSensors={props.digitalSensors}
              digitalSensorMap={props.digitalSensorMap}
              disabled={props.disabled}
              onChange={(
                digitalSensorId,
                attributeStateCode,
                attributeFlowCode,
                attributeAccumulationCode,
                attributeModeCode,
                attributeKFactorCode,
                attributeOffsetCode,
                attributeDebounceCode,
                attributeUnitsPerPulseCode,
                reportingDescription
              ) =>
                props.onChangeDigitalSensor(
                  digitalSensorId,
                  attributeStateCode,
                  attributeFlowCode,
                  attributeAccumulationCode,
                  attributeModeCode,
                  attributeKFactorCode,
                  attributeOffsetCode,
                  attributeDebounceCode,
                  attributeUnitsPerPulseCode,
                  reportingDescription
                )
              }
            />

            <DigitalSensorSelect
              sensorNumber={4}
              sourceStateAttribute={DIGITAL_INPUT_FOUR_STATE_ATTRIBUTE}
              sourceFlowAttribute={DIGITAL_INPUT_FOUR_FLOW_ATTRIBUTE}
              sourceAccumulationAttribute={DIGITAL_INPUT_FOUR_ACCUMULATION_ATTRIBUTE}
              sourceModeAttribute={DIGITAL_INPUT_FOUR_MODE_ATTRIBUTE}
              sourceKFactorAttribute={DIGITAL_INPUT_FOUR_K_FACTOR_ATTRIBUTE}
              sourceOffsetAttribute={DIGITAL_INPUT_FOUR_OFFSET_ATTRIBUTE}
              sourceDebounceAttribute={DIGITAL_INPUT_FOUR_DEBOUNCE_ATTRIBUTE}
              sourceUnitsPerPulseAttribute={DIGITAL_INPUT_FOUR_UNITS_PER_PULSE_ATTRIBUTE}
              digitalSensors={props.digitalSensors}
              digitalSensorMap={props.digitalSensorMap}
              disabled={props.disabled}
              onChange={(
                digitalSensorId,
                attributeStateCode,
                attributeFlowCode,
                attributeAccumulationCode,
                attributeModeCode,
                attributeKFactorCode,
                attributeOffsetCode,
                attributeDebounceCode,
                attributeUnitsPerPulseCode,
                reportingDescription
              ) =>
                props.onChangeDigitalSensor(
                  digitalSensorId,
                  attributeStateCode,
                  attributeFlowCode,
                  attributeAccumulationCode,
                  attributeModeCode,
                  attributeKFactorCode,
                  attributeOffsetCode,
                  attributeDebounceCode,
                  attributeUnitsPerPulseCode,
                  reportingDescription
                )
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}

MonitoringDeviceForm.propTypes = {
  deviceIdentifier: PropTypes.string,
  deviceType: PropTypes.string,
  deviceTypes: PropTypes.array.isRequired,
  monitoringDevices: PropTypes.array,
  analogSensors: PropTypes.array.isRequired,
  analogSensorMap: PropTypes.object.isRequired,
  digitalSensors: PropTypes.array.isRequired,
  digitalSensorMap: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeAnalogSensor: PropTypes.func.isRequired,
  onChangeDigitalSensor: PropTypes.func.isRequired,
};

interface Props {
  deviceIdentifier: string | null;
  deviceType: string | null;
  deviceTypes: DeviceType[];
  monitoringDevices?: MonitoringDevice[];
  analogSensors: AnalogSensor[];
  analogSensorMap: AnalogSensorMap;
  digitalSensors: DigitalSensor[];
  digitalSensorMap: DigitalSensorMap;
  disabled: boolean;
  onChange: (deviceModel: string | null, deviceIdentifier: string | null) => void;
  onChangeAnalogSensor: (
    analogSensorId: string,
    attributeId: string,
    attributeCode: string,
    attributeConnectedCode: string,
    attributeModeCode: string,
    attributeMinCode: string,
    attributeSpanCode: string
  ) => void;
  onChangeDigitalSensor: (
    digitalSensorId: string,
    attributeStateCode: string,
    attributeFlowCode: string,
    attributeAccumulationCode: string,
    attributeModeCode: string,
    attributeKFactorCode: string,
    attributeOffsetCode: string,
    attributeDebounceCode: string,
    attributeUnitsPerPulseCode: string,
    reportingDescription: string
  ) => void;
}

interface DeviceType {
  deviceTypeId: number;
  model: string;
  name: string;
}

interface AnalogSensor {
  analogSensorId: number;
  name: string;
  applications: AnalogApplication[];
}

interface AnalogApplication {
  attributeId: number;
  attributeConnectedId: number;
  name: string;
}

interface AnalogSensorMap {
  [key: string]: AnalogSensorSelection;
}

interface AnalogSensorSelection {
  analogSensorId: number;
  attributeCode: string;
  attributeConnectedCode: string;
  attributeModeCode: string;
  attributeMinCode: string;
  attributeSpanCode: string;
  mappedAttributeId: number;
  mappedAttributeConnectedId: number;
}

interface DigitalSensor {
  digitalSensorId: number;
  name: string;
  applications: DigitalApplication[];
}

interface DigitalApplication {
  attributeStateId: number;
  attributeFlowId: number;
  attributeAccumulationId: number;
  reportingDescription: string;
}

interface DigitalSensorMap {
  [key: string]: DigitalSensorSelection;
}

interface DigitalSensorSelection {
  digitalSensorId: number;
  attributeStateCode: string;
  attributeFlowCode: string;
  attributeAccumulationCode: string;
  attributeModeCode: string;
  attributeKFactorCode: string;
  attributeOffsetCode: string;
  attributeDebounceCode: string;
  attributeUnitsPerPulseCode: string;
  mappedAttributeStateId: number;
  mappedAttributeFlowId: number;
  mappedAttributeAccumulationId: number;
  reportingDescription: string;
}

interface MonitoringDevice {
  deviceId: number;
  deviceIdentifier: string;
}
