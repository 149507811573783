// --------------------------------------------------------------
// Created On: 2021-09-15
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect, useMemo, Fragment } from "react";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import Error from "../Error/Error";
import Modal from "../Modal/Modal";
import ModalHeader from "../ModalHeader/ModalHeader";
import ModalBody from "../ModalBody/ModalBody";
import ModalFooter from "../ModalFooter/ModalFooter";
import AssociationContainer from "./AssociationContainer/AssociationContainer";
import deepCopy from "../../utilities/deepCopy";
import PropTypes from "prop-types";
import styles from "./AssociationModal.module.scss";

// Modal for making selections that will become associations.
export default function AssociationModal<Type extends Item>(props: Props<Type>): Component {
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);
  const [selectionType, setSelectionType] = useState<string>("");
  const [associatedItems, setAssociatedItems] = useState<Item[]>([]);
  const [showAddConfirmRole, setShowAddConfirmRole] = useState<boolean>(false);
  const [showRemoveConfirmRole, setShowRemoveConfirmRole] = useState<boolean>(false);
  const unassociatedItems = useMemo(
    () => getUnassociatedItems(associatedItems, props.items),
    [JSON.stringify(associatedItems), JSON.stringify(props.items)]
  );

  // Sort currently associated items.
  useEffect(() => {
    const associatedItemsDeepCopy = deepCopy(props.associatedItems);
    setAssociatedItems(sortItems(associatedItemsDeepCopy));
  }, [JSON.stringify(props.associatedItems), JSON.stringify(props.items)]);

  // Sort items based on name.
  function sortItems(items: Item[]): Item[] {
    return items.sort((a: Item, b: Item) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  // Get the list of unassociated items.
  function getUnassociatedItems(associatedItems: Item[], items: Item[]): Item[] {
    const itemAssociationMap: ItemAssociationMap = {};

    // Use a hash table to figure out what items are unassociated.
    items.forEach((item) => {
      itemAssociationMap[getItemId(item)] = item;
    });
    associatedItems.forEach((associatedItem) => {
      if (getItemId(associatedItem) in itemAssociationMap) {
        delete itemAssociationMap[getItemId(associatedItem)];
      }
    });

    // Convert the resulting hash table to an array of items.
    const unassociatedItems: Item[] = [];
    Object.keys(itemAssociationMap).forEach((itemAssociationMapKey: string) => {
      unassociatedItems.push(itemAssociationMap[itemAssociationMapKey]);
    });

    return sortItems(unassociatedItems);
  }

  // Get ID from item.
  function getItemId(item: Item): number {
    switch (props.type) {
      case "asset":
        if (item.assetId !== undefined) {
          return item.assetId;
        }
        break;
      case "user":
        if (item.userId !== undefined) {
          return item.userId;
        }
        break;
      case "assetgroup":
        if (item.assetgroupId !== undefined) {
          return item.assetgroupId;
        }
        break;
      case "usergroup":
        if (item.usergroupId !== undefined) {
          return item.usergroupId;
        }
        break;
      default:
        return 0;
    }
    return 0;
  }

  // Disassociate items.
  function disassociateItems(itemIds: number[]): void {
    let associatedItemsDeepCopy = deepCopy(associatedItems);

    // Filter the items out of the association list.
    associatedItemsDeepCopy = associatedItemsDeepCopy.filter(
      (associatedItem: Item) => !itemIds.includes(getItemId(associatedItem))
    );

    // Update state and clear any selections.
    setAssociatedItems(associatedItemsDeepCopy);
    setSelectedItemIds([]);
    setSelectionType("");
  }

  // Associate items.
  function associateItems(itemIds: number[]): void {
    let associatedItemsDeepCopy = deepCopy(associatedItems);
    const unassociatedItemsDeepCopy = deepCopy(unassociatedItems);

    // Add the items to the association list.
    itemIds.forEach((itemId) => {
      const item = unassociatedItemsDeepCopy.find((unassociatedItem: Item) => getItemId(unassociatedItem) === itemId);
      if (item !== undefined) {
        if (props.type === "usergroup") {
          item.operateAccessPermission = false;
        }
        associatedItemsDeepCopy = [item, ...associatedItemsDeepCopy];
      }
    });

    // Update state and clear any selections.
    setAssociatedItems(associatedItemsDeepCopy);
    setSelectedItemIds([]);
    setSelectionType("");
  }

  // Disassociate all items.
  function disassociateAllItems(): void {
    setAssociatedItems([]);
    setSelectedItemIds([]);
    setSelectionType("");
  }

  // Associate all items.
  function associateAllItems(): void {
    const associatedItemsDeepCopy = deepCopy(associatedItems);
    const unassociatedItemsDeepCopy = deepCopy(unassociatedItems);

    // Convert unassociated items to the associated format.
    unassociatedItemsDeepCopy.forEach((item: Item) => {
      const newAssociationItem: Item = {
        [props.itemIdKey]: getItemId(item),
        name: item.name,
      };

      if (props.type === "usergroup") {
        newAssociationItem.operateAccessPermission = false;
        newAssociationItem.users = item.users;
      } else if (props.type === "asset") {
        newAssociationItem.nickname = item.nickname;
        newAssociationItem.productModel = item.productModel;
        newAssociationItem.productManufacturer = item.productManufacturer;
        const lastUpdated = {
          code: "RequestTimeUTC",
          name: "Data Last Updated",
          value: "time unknown",
          unitShort: "",
          unitLong: "",
          unitSymbol: "",
          icon: "",
          isHistorical: false,
        };
        newAssociationItem.deviceLog = { lastUpdated: lastUpdated };
        if (
          newAssociationItem.deviceLog.gps !== undefined &&
          item.deviceLog !== undefined &&
          item.deviceLog.gps !== undefined &&
          item.deviceLog.gps.latitude !== undefined &&
          item.deviceLog.gps.longitude !== undefined
        ) {
          newAssociationItem.deviceLog.gps.latitude = item.deviceLog.gps.latitude;
          newAssociationItem.deviceLog.gps.longitude = item.deviceLog.gps.longitude;
        }
      }

      associatedItemsDeepCopy.push(newAssociationItem);
    });
    setAssociatedItems(sortItems(associatedItemsDeepCopy));
    setSelectedItemIds([]);
    setSelectionType("");
  }

  // Select items.
  function selectItems(itemIds: number[], type: string): void {
    setSelectedItemIds(itemIds);
    setSelectionType(type);
  }

  // Exit without saving changes.
  function discardChanges(): void {
    setAssociatedItems(props.associatedItems);
    props.onClose();
  }

  // Check to see if the user really wants to change the user group operation permission.
  function confirmRoleChange(usergroupId: number, operateAccessPermission: boolean): void {
    setSelectedItemIds([usergroupId]);
    if (operateAccessPermission) {
      setShowRemoveConfirmRole(true);
    } else {
      setShowAddConfirmRole(true);
    }
  }

  // Set the operation permission that this user group has in regards to this asset group.
  function grantOperationPermission(operateAccessPermission: boolean): void {
    if (selectedItemIds.length > 0) {
      const associatedItemsDeepCopy = deepCopy(associatedItems);
      const usergroupId = selectedItemIds[0];

      const associatedItemIndex = associatedItemsDeepCopy.findIndex(
        (associatedItem: Item) => getItemId(associatedItem) === usergroupId
      );

      if (associatedItemIndex > -1) {
        associatedItemsDeepCopy[associatedItemIndex].operateAccessPermission = operateAccessPermission;
      }

      setAssociatedItems(associatedItemsDeepCopy);
      setShowAddConfirmRole(false);
      setShowRemoveConfirmRole(false);
    }
  }

  return (
    <div>
      <Modal
        show={props.showModal}
        onHide={() => discardChanges()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--super-modal-z-index)" }}
        size="xl"
        animation
        centered
      >
        <ModalHeader>
          <h5 className="modal-title font-weight-bold">{props.title}</h5>
        </ModalHeader>

        <ModalBody>
          <AssociationContainer
            type={props.type}
            associatedItemsTitle={props.associatedItemsTitle}
            unassociatedItemsTitle={props.unassociatedItemsTitle}
            associatedItems={associatedItems}
            unassociatedItems={unassociatedItems}
            itemIdKey={props.itemIdKey}
            parentName={props.parentName}
            selectedItemIds={selectedItemIds}
            selectionType={selectionType}
            disabled={props.disabled}
            onSelectItems={(itemIds, type) => selectItems(itemIds, type)}
            onAssociateItems={(itemIds) => associateItems(itemIds)}
            onDisassociateItems={(itemIds) => disassociateItems(itemIds)}
            onAssociateAllItems={() => associateAllItems()}
            onDisassociateAllItems={() => disassociateAllItems()}
            onConfirmRoleChange={(usergroupId, operateAccessPermission) =>
              confirmRoleChange(usergroupId, operateAccessPermission)
            }
          />

          {props.errorMessage !== undefined && props.errorMessage !== null && props.errorMessage.length > 0 && (
            <div className="row">
              <div className="col mt-4 mx-2">
                <Error message={props.errorMessage} />
              </div>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <Fragment>
            <button
              data-test="associate-modal-done-button"
              className="btn btn-primary mx-3"
              type="button"
              onClick={() => props.onChange(associatedItems as Type[])}
            >
              Done
            </button>

            <button className="btn btn-secondary" type="button" onClick={() => discardChanges()}>
              Cancel
            </button>
          </Fragment>
        </ModalFooter>
      </Modal>

      <ConfirmModal
        showModal={props.showModal && showAddConfirmRole}
        title="Add Operation Privileges"
        content={
          "You are about to give this user group operating privileges for all assets in this asset group." +
          " Are you sure that you want to continue?"
        }
        yesText="Confirm"
        noText="Cancel"
        danger={false}
        onClose={() => setShowAddConfirmRole(false)}
        onYes={() => grantOperationPermission(true)}
        onNo={() => setShowAddConfirmRole(false)}
      />

      <ConfirmModal
        showModal={props.showModal && showRemoveConfirmRole}
        title="Remove Operation Privileges"
        content={
          "You are about to remove operating privileges from this user group." +
          " Are you sure that you want to continue?"
        }
        yesText="Confirm"
        noText="Cancel"
        danger={false}
        onClose={() => setShowRemoveConfirmRole(false)}
        onYes={() => grantOperationPermission(false)}
        onNo={() => setShowRemoveConfirmRole(false)}
      />
    </div>
  );
}

AssociationModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  associatedItemsTitle: PropTypes.string.isRequired,
  unassociatedItemsTitle: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  associatedItems: PropTypes.array.isRequired,
  itemIdKey: PropTypes.string.isRequired,
  parentName: PropTypes.string,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

interface Props<Type> {
  showModal: boolean;
  title: string;
  type: string;
  associatedItemsTitle: string;
  unassociatedItemsTitle: string;
  items: Type[];
  associatedItems: Type[];
  itemIdKey: string;
  parentName?: string;
  errorMessage?: string;
  disabled: boolean;
  onClose: () => void;
  onChange: (items: Type[]) => void;
}

interface Item {
  assetId?: number;
  userId?: number;
  assetgroupId?: number;
  usergroupId?: number;
  name: string;
  operateAccessPermission?: boolean;
  emailAddress?: string;
  nickname?: string;
  productModel?: string;
  productManufacturer?: string;
  deviceLog?: DeviceLog;
  users?: User[];
  isDefault?: boolean;
}

interface User {
  userId: number;
  name: string;
  emailAddress: string;
  roleId: number;
}

interface ItemAssociationMap {
  [key: string]: Item;
}

type DeviceLog = {
  lastUpdated: DeviceLogUnit;
} & {
  [key: string]: DeviceLogUnit;
};

interface DeviceLogUnit {
  code: string;
  name: string;
  value: string | number | boolean;
  latitude?: number;
  longitude?: number;
  unitLong: string;
  unitShort: string;
  unitSymbol: string;
  icon: string;
  isHistorical: boolean;
  sensorConnected?: boolean;
  mostRecentUtc?: string;
}
