// --------------------------------------------------------------
// Created On: 2023-11-27
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { EQUAL_TO_COMPARATOR_NAME, NOT_EQUAL_TO_COMPARATOR_NAME } from "../../../../constants/miscellaneous";
import styles from "./AssetThresholdControl.module.scss";

// Control for updating or deleting an asset threshold.
export default function AssetThresholdControl(props: Props): Component {
  // Gets the attribute unit name using the attribute ID.
  function getAttributeUnitName(attributeName: string, attributes: Attribute[]): string {
    const attribute = attributes.find((attribute) => attribute.attributeName === attributeName);
    if (attribute !== undefined && attribute.unitShortName !== null) {
      return attribute.unitShortName;
    } else {
      return "";
    }
  }

  // Checks if the specified attribute describes a boolean value.
  function attributeIsBoolean(attributeName: string, attributes: Attribute[]): boolean {
    const attribute = attributes.find((attribute) => attribute.attributeName === attributeName);
    if (attribute !== undefined && attribute.isBoolean !== undefined) {
      return attribute.isBoolean;
    } else {
      return false;
    }
  }

  // Checks if the specified attribute describes an enumeration value.
  function attributeIsEnumeration(attributeName: string, attributes: Attribute[]): boolean {
    const attribute = attributes.find((attribute) => attribute.attributeName === attributeName);
    if (attribute !== undefined && attribute.regEnumId !== undefined && attribute.regEnumId !== null) {
      return true;
    } else {
      return false;
    }
  }

  // Gets an array of enumeration options for a specific attribute.
  function getEnumerationOptionsForAttribute(
    attributeName: string,
    attributes: Attribute[],
    enumerationMap: EnumerationMap
  ): EnumerationOption[] {
    const enumerationOptions: EnumerationOption[] = [];
    const attribute = attributes.find((attribute) => attribute.attributeName === attributeName);
    if (attribute !== undefined && attribute.regEnumId !== null && enumerationMap[attribute.regEnumId] !== undefined) {
      const enumerationOptionIndexes = Object.keys(enumerationMap[attribute.regEnumId]);
      enumerationOptionIndexes.forEach((enumerationOptionIndex) => {
        if (enumerationMap[attribute.regEnumId as number][enumerationOptionIndex] !== undefined) {
          enumerationOptions.push({
            index: parseInt(enumerationOptionIndex, 10),
            name: enumerationMap[attribute.regEnumId as number][enumerationOptionIndex],
          });
        }
      });
    }
    return enumerationOptions;
  }

  // Get the ID of a comparator by name.
  function getComparatorIdByName(name: string, comparators: Comparator[]): number {
    const foundComparator = comparators.find((comparator) => comparator.name === name);
    if (foundComparator !== undefined) {
      return foundComparator.alertThresholdComparatorId;
    } else {
      return 0;
    }
  }

  // Set attribute type. If we are dealing with a boolean attribute the comparator always defaults to 'equal to'.
  function updateThresholdAttribute(attributeName: string, attributes: Attribute[], comparators: Comparator[]): void {
    if (attributeIsBoolean(attributeName, attributes)) {
      props.onUpdateThreshold(attributeName, getComparatorIdByName("equal to", comparators), "", "");
    } else {
      props.onUpdateThreshold(attributeName, 0, "", "");
    }
  }

  return (
    <div className={styles.body} data-test="asset-manage-threshold-container">
      <div className="mt-2 mb-3">
        {!props.isRental && (
          <i
            data-test="delete-asset-threshold-button"
            className={`${styles.deleteButton} fa fa-fw fa-times float-end`}
            onClick={() => props.onRemoveThreshold()}
          />
        )}
      </div>

      <div>
        <div className="row">
          <div
            className={
              attributeIsBoolean(props.attributeName, props.attributes) ? "col-12 col-lg-4" : "col-12 col-lg-3"
            }
          >
            <label className="mb-3 me-2">Attribute</label>

            <input
              data-test="asset-threshold-attribute-selector"
              className="form-control mb-3"
              type="text"
              list="datalist-attributes"
              autoComplete="off"
              value={props.attributeName}
              disabled={props.isRental}
              onChange={(e) => updateThresholdAttribute(e.target.value, props.attributes, props.comparators)}
            />
          </div>

          {/* Selected attribute is boolean. */}
          {attributeIsBoolean(props.attributeName, props.attributes) && (
            <div className="col-12 col-lg-4">
              <label className="mb-3 me-2">Is Value</label>
              <select
                data-test="asset-threshold-boolean-attribute-value-selector"
                className="form-select col-12 mb-3"
                value={props.thresholdValue}
                disabled={props.isRental}
                onChange={(e) =>
                  props.onUpdateThreshold(
                    props.attributeName,
                    getComparatorIdByName("equal to", props.comparators),
                    e.target.value,
                    props.message
                  )
                }
              >
                <option value="" disabled>
                  Please select a value...
                </option>
                <option value="1">True</option>
                <option value="0">False</option>
              </select>
            </div>
          )}

          {/* Selected attribute is an enumeration. */}
          {attributeIsEnumeration(props.attributeName, props.attributes) && (
            <Fragment>
              <div className="col-12 col-lg-2">
                <label className="mb-3 me-2">Comparator</label>
                <select
                  data-test="asset-threshold-comparator-selector"
                  className="form-select col-12 mb-3"
                  value={props.alertThresholdComparatorId}
                  disabled={props.isRental}
                  onChange={(e) =>
                    props.onUpdateThreshold(
                      props.attributeName,
                      parseInt(e.target.value, 10),
                      props.thresholdValue,
                      props.message
                    )
                  }
                >
                  <option value="0" disabled>
                    Please select a comparator...
                  </option>
                  {props.comparators.map(
                    (comparator) =>
                      [EQUAL_TO_COMPARATOR_NAME, NOT_EQUAL_TO_COMPARATOR_NAME].includes(comparator.name) && (
                        <option
                          value={comparator.alertThresholdComparatorId}
                          key={comparator.alertThresholdComparatorId}
                        >
                          {comparator.name}
                        </option>
                      )
                  )}
                </select>
              </div>

              <div className="col-12 col-lg-3">
                <label
                  className={`mb-3 me-2 ${
                    getAttributeUnitName(props.attributeName, props.attributes).length > 0 ? "w-75" : "w-100"
                  }`}
                >
                  Value
                </label>
                <select
                  data-test="asset-threshold-enumeration-attribute-value-selector"
                  className="form-select col-12 mb-3"
                  value={props.thresholdValue}
                  disabled={props.isRental}
                  onChange={(e) =>
                    props.onUpdateThreshold(
                      props.attributeName,
                      props.alertThresholdComparatorId,
                      e.target.value,
                      props.message
                    )
                  }
                >
                  <option value="" disabled>
                    Please select a value...
                  </option>
                  {getEnumerationOptionsForAttribute(props.attributeName, props.attributes, props.enumerationMap).map(
                    (enumerationOption) => (
                      <option key={enumerationOption.index} value={enumerationOption.index}>
                        {enumerationOption.name}
                      </option>
                    )
                  )}
                </select>
              </div>
            </Fragment>
          )}

          {/* No attribute selected, or selected attribute is not a boolean and not an enumeration. */}
          {!attributeIsBoolean(props.attributeName, props.attributes) &&
            !attributeIsEnumeration(props.attributeName, props.attributes) && (
              <Fragment>
                <div className="col-12 col-lg-3">
                  <label className="mb-3 me-2">Comparator</label>
                  <select
                    data-test="asset-threshold-comparator-selector"
                    className="form-select col-12 mb-3"
                    value={props.alertThresholdComparatorId}
                    disabled={props.isRental}
                    onChange={(e) =>
                      props.onUpdateThreshold(
                        props.attributeName,
                        parseInt(e.target.value, 10),
                        props.thresholdValue,
                        props.message
                      )
                    }
                  >
                    <option value="0" disabled>
                      Please select a comparator...
                    </option>
                    {props.comparators.map((comparator) => (
                      <option value={comparator.alertThresholdComparatorId} key={comparator.alertThresholdComparatorId}>
                        {comparator.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-12 col-lg-3">
                  <label
                    className={`mb-3 me-2 ${
                      getAttributeUnitName(props.attributeName, props.attributes).length > 0 ? "w-75" : "w-100"
                    }`}
                  >
                    Value
                  </label>
                  <input
                    data-test="asset-threshold-comparison-value-input"
                    className={`form-control mx-auto mb-3 d-inline-block ${
                      getAttributeUnitName(props.attributeName, props.attributes).length > 0 ? "w-50" : "w-100"
                    }`}
                    type="text"
                    value={props.thresholdValue}
                    disabled={props.isRental}
                    onChange={(e) =>
                      props.onUpdateThreshold(
                        props.attributeName,
                        props.alertThresholdComparatorId,
                        e.target.value,
                        props.message
                      )
                    }
                  />
                  <span className="mx-2">{getAttributeUnitName(props.attributeName, props.attributes)}</span>
                </div>
              </Fragment>
            )}

          <div
            className={
              attributeIsBoolean(props.attributeName, props.attributes) ||
              attributeIsEnumeration(props.attributeName, props.attributes)
                ? "col-12 col-lg-4"
                : "col-12 col-lg-3"
            }
          >
            <label className="mb-3 me-2">Message</label>
            <input
              data-test="asset-threshold-message-input"
              className="form-control mx-auto mb-3"
              type="text"
              value={props.message}
              disabled={props.isRental}
              onChange={(e) =>
                props.onUpdateThreshold(
                  props.attributeName,
                  props.alertThresholdComparatorId,
                  props.thresholdValue,
                  e.target.value
                )
              }
            />
          </div>
        </div>
        <div className="my-3">
          <button
            data-test="asset-threshold-manage-subscribed-users-button"
            className="btn btn-primary"
            type="button"
            onClick={() => props.onSelectUsers()}
          >
            Manage Subscribed Users
          </button>
        </div>
      </div>
    </div>
  );
}

AssetThresholdControl.propTypes = {
  attributeName: PropTypes.string.isRequired,
  alertThresholdComparatorId: PropTypes.number.isRequired,
  thresholdValue: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  attributes: PropTypes.array.isRequired,
  comparators: PropTypes.array.isRequired,
  isRental: PropTypes.bool.isRequired,
  enumerationMap: PropTypes.object.isRequired,
  onUpdateThreshold: PropTypes.func.isRequired,
  onRemoveThreshold: PropTypes.func.isRequired,
  onSelectUsers: PropTypes.func.isRequired,
};

interface Props {
  attributeName: string;
  alertThresholdComparatorId: number;
  thresholdValue: string;
  message: string;
  attributes: Attribute[];
  comparators: Comparator[];
  isRental: boolean;
  enumerationMap: EnumerationMap;
  onUpdateThreshold: (
    attributeName: string,
    alertThresholdComparatorId: number,
    thresholdValue: string,
    message: string
  ) => void;
  onRemoveThreshold: () => void;
  onSelectUsers: () => void;
}

interface Attribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  unitId: number | null;
  unitShortName: string | null;
  isBoolean: boolean;
  regEnumId: number | null;
}

interface Comparator {
  alertThresholdComparatorId: number;
  name: string;
}

interface EnumerationMap {
  [key: string]: EnumerationIndexMap;
}

interface EnumerationIndexMap {
  [key: string]: string;
}

interface EnumerationOption {
  index: number;
  name: string;
}
