// --------------------------------------------------------------
// Created On: 2021-10-25
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect } from "react";
import Spinner from "../../components/Spinner/Spinner";
import Error from "../../components/Error/Error";
import Success from "../../components/Success/Success";
import Card from "../../components/Card/Card";
import apiRequest from "../../utilities/api/apiRequest";
import getApiError from "../../utilities/api/getApiError";
import { API } from "../../constants/miscellaneous";
import styles from "./ForgotPasswordPage.module.scss";

// Page for users to request a new temporary password.
export default function ForgotPasswordPage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");

  // If an input is being edited, remove any old messages.
  useEffect(() => {
    setErrorMessage("");
    setSuccessMessage("");
  }, [emailAddress]);

  // Check if the user pressed enter, if they did we request a new password.
  function checkPressEnter(event: React.KeyboardEvent<HTMLDivElement>): void {
    if (event.key === "Enter") {
      void requestNewPassword(emailAddress);
    }
  }

  // Request a new temporary password via email.
  async function requestNewPassword(emailAddress: string): Promise<void> {
    const requestBody = {
      emailAddress: emailAddress,
    };

    setLoading(true);
    const [response, responseBody] = (await apiRequest(`${API}/registration/password`, "POST", requestBody)) as [
      Response,
      ResponseBody
    ];
    setLoading(false);

    // Check if there was an error requesting a new password.
    if (response.ok && responseBody) {
      setSuccessMessage(
        `A message with information about how to access your account has been sent to '${emailAddress}'.`
      );
      setErrorMessage("");
    } else {
      setSuccessMessage("");
      setErrorMessage(await getApiError(response, "Unable to request new password."));
    }
  }

  return (
    <div className="page-forgot-password p-4">
      <Spinner loading={loading} />

      <div className="col-12 col-xl-6 offset-xl-3" onKeyDown={(e) => checkPressEnter(e)}>
        <Card title="Verify Email Address">
          <div className="login-form-inner px-5 mb-4">
            <p className={`${styles.explanation} my-4`}>
              Enter your email address to start the verification process. You will receive an email with instructions on
              how to reset your password.
            </p>

            <label className="login-label mt-2">Email</label>

            <input
              type="email"
              className="email-input form-control mt-2 mb-4"
              id="email-text"
              aria-describedby="Email"
              placeholder="Enter email"
              onChange={(e) => setEmailAddress(e.target.value)}
            />

            {errorMessage.length === 0 && successMessage.length === 0 && (
              <div className="text-center my-4">
                <button
                  type="submit"
                  id="submit-login"
                  className={`${styles.requestBtn} btn btn-primary`}
                  onClick={() => requestNewPassword(emailAddress)}
                >
                  Send Verification Email
                </button>
              </div>
            )}

            <Error message={errorMessage} />
            <Success message={successMessage} />
          </div>
        </Card>
      </div>
    </div>
  );
}

interface ResponseBody {
  message: string;
}
