// --------------------------------------------------------------
// Created On: 2024-12-10
// Author: Dimitra Weinstein
//
// Last Modified: 2025-01-20
// Modified By: Zachary Thomas
//
// Copyright 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------
import React, { useState, Fragment, useEffect } from "react";
import { API, MAX_ROWS_PER_PAGE } from "../../constants/miscellaneous";
import apiRequest from "../../utilities/api/apiRequest";
import formatTitleCase from "../../utilities/formatTitleCase";
import isoAddDays from "../../utilities/time/isoAddDays";
import getApiError from "../../utilities/api/getApiError";
import downloadCsvFile from "../../utilities/downloadCsvFile";
import Spinner from "../../components/Spinner/Spinner";
import Toast from "../../components/Toast/Toast";
import pascalCaseToTitleCase from "../../utilities/pascalCaseToTitleCase";
import SuperAdminReportPageForm from "./SuperAdminReportPageForm/SuperAdminReportPageForm";
import SuperAdminDataTable from "./SuperAdminReportPageDataTable/SuperAdminReportPageDataTable";
import useApi from "../../hooks/useApi";

// Page for generating Admin Reports.
export default function SuperAdminReportPage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [startDateErrorMessage, setStartDateErrorMessage] = useState<string>("");
  const [endDateErrorMessage, setEndDateErrorMessage] = useState<string>("");
  const [reportType, setReportType] = useState<string>("");
  const [reportTypeIsValid, setReportTypeIsValid] = useState<boolean>(true);
  const [dateRangeIsChecked, setDateRangeIsChecked] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [startDateOffset, setStartDateOffset] = useState<string | null>(null);
  const [endDateOffset, setEndDateOffset] = useState<string | null>(null);
  const [startDateIsValid, setStartDateIsValid] = useState<boolean>(true);
  const [endDateIsValid, setEndDateIsValid] = useState<boolean>(true);
  const [dateRangeIsValid, setDateRangeIsValid] = useState<boolean>(true);
  const [columnHeaders, setColumnHeaders] = useState<string[]>([]);
  const [dataRows, setDataRows] = useState<(string | number)[][]>([]);
  const [columnName, setColumnName] = useState<string | null>(null);
  const [sortAscending, setSortAscending] = useState<boolean>(false);
  const [sortColumnIndex, setSortColumnIndex] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rowCount, setRowCount] = useState<number>(0);
  const [newReportCounter, setNewReportCounter] = useState<number>(0);

  // Api call that paginates and sorts report data.
  useApi(
    () => {
      if (newReportCounter > 0 && validReportType(reportType)) {
        if ((dateRangeIsChecked && validDateRange(startDate, endDate)) || !dateRangeIsChecked) {
          setLoading(true);
          return true;
        } else {
          setLoading(false);
          return false;
        }
      } else {
        setLoading(false);
        return false;
      }
    },
    {
      method: "POST",
      url: `${API}/report`,
      body: {
        reportType: reportType,
        startDate: startDateOffset,
        endDate: endDateOffset,
        pageNumber: pageNumber,
        rowsPerPage: MAX_ROWS_PER_PAGE,
        columnName: formatColumnName(columnName),
        sortDirection: convertSortAscendingBooleanToString(sortAscending),
        returnCsv: false,
      },
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        setLoading(false);
        setFailedToLoad(false);
        setErrorMessage("");
        setColumnHeaders(formatColumnHeaders(responseBody.headers));
        setDataRows(responseBody.rows);
        setRowCount(responseBody.rowCount);
      } else {
        setLoading(false);
        setFailedToLoad(true);
        setErrorMessage(await getApiError(response, "Unable to sort report data."));
      }
    },
    [pageNumber, columnName, sortAscending, sortColumnIndex, startDateOffset, endDateOffset, newReportCounter]
  );

  // If the type of report changes, reset supporting state.
  useEffect(() => {
    if (reportType !== "") {
      setColumnHeaders([]);
      setDataRows([]);
      setRowCount(0);
      setPageNumber(1);
      setColumnName(null);
      setSortAscending(false);
      setSortColumnIndex(0);
      setNewReportCounter((previous) => previous + 1);
    }
  }, [reportType]);

  // When startDate and endDate are set, get offset to include whole days.
  useEffect(() => {
    if (startDate !== null && startDate.length > 0 && endDate !== null && endDate.length > 0) {
      setStartDateOffset(`${startDate} 00:00:00`);
      setEndDateOffset(isoAddDays(`${endDate}T00:00:00`, 1).split(".")[0].replace("T", " "));
    }
  }, [startDate, endDate]);

  // Update sorting order.
  async function handleSortData(selectedIndex: number): Promise<void> {
    const selectedColumnName: string = columnHeaders[selectedIndex];
    if (selectedIndex !== sortColumnIndex) {
      setSortColumnIndex(selectedIndex);
      setColumnName(selectedColumnName);
      setSortAscending(false);
    } else {
      setColumnName(selectedColumnName);
      setSortAscending((previous) => !previous);
    }
  }

  // Paginate through report data.
  async function handlePaginateData(newPageNumber: number): Promise<void> {
    if (newPageNumber !== pageNumber) {
      setPageNumber(newPageNumber);
    }
  }

  // Format column header names for api call.
  function formatColumnName(columnName: string | null): string | null {
    if (columnName !== null) {
      columnName = columnName.replaceAll(" ", "_").toLowerCase();
    }
    return columnName;
  }

  // Convert sortAscending value to string equivalent for api call.
  function convertSortAscendingBooleanToString(sortAscending: boolean): string {
    if (sortAscending === true) {
      return "ASC";
    } else {
      return "DESC";
    }
  }

  // Enable and disable date selection inputs, clear any error messages.
  function handleSelectDateRange(): void {
    if (!dateRangeIsChecked) {
      setDateRangeIsChecked(true);
      setErrorMessage("");
      setStartDateErrorMessage("");
      setEndDateErrorMessage("");
    } else {
      setDateRangeIsChecked(false);
      setStartDate(null);
      setEndDate(null);
      setStartDateIsValid(true);
      setEndDateIsValid(true);
      setDateRangeIsValid(true);
      setErrorMessage("");
      setStartDateErrorMessage("");
      setEndDateErrorMessage("");
    }
  }

  // Function to validate report type.
  function validReportType(reportType: string): boolean {
    if (reportType === "" || reportType === null || reportType === undefined) {
      setReportTypeIsValid(false);
      setErrorMessage("Must select valid report type.");
      return false;
    } else {
      setReportTypeIsValid(true);
      setErrorMessage("");
      return true;
    }
  }

  // Validates start date on change and sets to state.
  function handleStartDateChange(date: string): void {
    // Check that date is not an empty string.
    if (date !== "" && date !== null && date !== undefined) {
      const today = new Date();
      const newStartDate = new Date(date);
      // Check that selected date is not greater than current date.
      if (newStartDate > today) {
        setStartDateIsValid(false);
        setStartDateErrorMessage("Start date must be before or equal to today's date.");
      } else {
        setStartDateIsValid(true);
        setStartDateErrorMessage("");
        setStartDate(date);
      }
    } else {
      setStartDateIsValid(false);
      setStartDateErrorMessage("Must select valid start date.");
    }
  }

  // Validates end date on change and sets to state.
  function handleEndDateChange(date: string): void {
    // Check that date param is not empty string.
    if (date !== "" || date !== null || date !== undefined) {
      const today = new Date();
      const newEndDate = new Date(date);
      // Check that selected date is not greater than current date.
      if (newEndDate > today) {
        setEndDateIsValid(false);
        setEndDateErrorMessage("End date must be before or equal to today's date.");
      } else {
        setEndDateIsValid(true);
        setEndDateErrorMessage("");
        setEndDate(date);
      }
    } else {
      setEndDateIsValid(false);
      setEndDateErrorMessage("Must select valid end date.");
    }
  }

  // Function to validate date range on submit.
  function validDateRange(startDate: string | null, endDate: string | null): boolean {
    let validDateRange = true;
    // Ensure dates are not null or empty string.
    if (startDate !== "" && endDate !== "" && startDate !== null && endDate !== null) {
      // Ensure start date is not greater than end date.
      if (startDate > endDate) {
        setDateRangeIsValid(false);
        setErrorMessage("Start date must begin before end date.");
        validDateRange = false;
      } else {
        setDateRangeIsValid(true);
        setErrorMessage("");
        validDateRange = true;
      }
    } else {
      setDateRangeIsValid(false);
      setErrorMessage("Must select valid date range.");
      validDateRange = false;
    }
    return validDateRange;
  }

  // Function that downloads CSV file of report data.
  async function handleDownloadCsv(): Promise<void> {
    // Format request body.
    const requestBody = {
      reportType: reportType,
      startDate: startDateOffset,
      endDate: endDateOffset,
      pageNumber: 1,
      rowsPerPage: null,
      columnName: formatColumnName(columnName),
      sortDirection: convertSortAscendingBooleanToString(sortAscending),
      returnCsv: true,
    };
    // Make api call.
    setLoading(true);
    const [response, responseBody] = (await apiRequest(`${API}/report`, "POST", requestBody)) as [
      Response: Response,
      ResponseBody: string
    ];
    setLoading(false);
    setFailedToLoad(false);
    if (response.ok && responseBody) {
      // Include date range in csv file name if dates are not null.
      if (startDate !== null && endDate !== null) {
        downloadCsvFile(responseBody, `${reportType}Report_${startDate}_${endDate}.csv`);
      } else {
        downloadCsvFile(responseBody, `${reportType}_Report.csv`);
      }
    } else {
      setFailedToLoad(true);
      setErrorMessage(await getApiError(response, "Unable to download csv."));
    }
  }

  // Format an array of string headers from 'snake_case' into 'Title Case'.
  function formatColumnHeaders(columnHeaders: string[]): string[] {
    return columnHeaders.map((columnHeader) => formatTitleCase(columnHeader.replaceAll("_", " ")));
  }

  return (
    <Fragment>
      <Spinner loading={loading} />

      {/* Toast error message. */}
      {failedToLoad && (
        <Toast
          type="error"
          title={`Failed to load ${reportType} data`}
          message={errorMessage}
          show={failedToLoad}
          onClose={() => {
            setFailedToLoad(false), setErrorMessage("");
          }}
        />
      )}

      {/* Report form and data table */}
      <div className="p-4">
        <div className="container">
          <SuperAdminReportPageForm
            reportType={reportType}
            formattedReportType={pascalCaseToTitleCase(reportType)}
            reportTypeIsValid={reportTypeIsValid}
            dateRangeIsChecked={dateRangeIsChecked}
            startDate={startDate}
            endDate={endDate}
            startDateIsValid={startDateIsValid}
            endDateIsValid={endDateIsValid}
            dateRangeIsValid={dateRangeIsValid}
            errorMessage={errorMessage}
            startDateErrorMessage={startDateErrorMessage}
            endDateErrorMessage={endDateErrorMessage}
            onStartDateChange={(startDate) => handleStartDateChange(startDate)}
            onEndDateChange={(endDate) => handleEndDateChange(endDate)}
            onReportTypeChange={(reportType) => setReportType(reportType)}
            onSelectDateRange={() => handleSelectDateRange()}
          />
          <SuperAdminDataTable
            loading={loading}
            failedToLoad={failedToLoad}
            errorMessage={errorMessage}
            reportType={reportType}
            formattedReportType={pascalCaseToTitleCase(reportType)}
            startDate={startDate}
            endDate={endDate}
            pageNumber={pageNumber}
            rowCount={rowCount}
            columnHeaders={columnHeaders}
            sortAscending={sortAscending}
            sortColumnIndex={sortColumnIndex}
            dataRows={dataRows}
            onSetFailedToLoad={(failedToLoad) => setFailedToLoad(failedToLoad)}
            onSetErrorMessage={(errorMessage) => setErrorMessage(errorMessage)}
            onPaginate={(pageNumber) => handlePaginateData(pageNumber)}
            onSortData={(newColumnIndex) => handleSortData(newColumnIndex)}
            onDownloadCsv={() => handleDownloadCsv()}
          />
        </div>
      </div>
    </Fragment>
  );
}

interface ResponseBody {
  headers: string[];
  rows: (string | number)[][];
  rowCount: number;
}
