// --------------------------------------------------------------
// Created On: 2023-09-29
// Author: Zachary Thomas
//
// Last Modified: 2024-12-13
// Modified By: Dimitra Weinstein
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useState, useEffect, useMemo } from "react";
import {
  API,
  CUSTOMER_SERVICE_EMAIL,
  CUSTOMER_SERVICE_PHONE,
  CUSTOMER_SERVICE_OVERRIDE_MESSAGE,
} from "../../constants/miscellaneous";
import DeviceStatusRow from "./DeviceStatusRow/DeviceStatusRow";
import Spinner from "../../components/Spinner/Spinner";
import Error500Page from "../Error500Page/Error500Page";
import Card from "../../components/Card/Card";
import TextBlurb from "../../components/TextBlurb/TextBlurb";
import DeviceStatusModal from "./DeviceStatusModal/DeviceStatusModal";
import useApi from "../../hooks/useApi";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import FilteredTableContainer from "../../components/FilteredTableContainer/FilteredTableContainer";
import SortedTableHeader from "../../components/SortedTableHeader/SortedTableHeader";
import deepCopy from "../../utilities/deepCopy";
import styles from "./DeviceStatusPage.module.scss";

// Page for viewing device status information (diagnostic information).
export default function DeviceStatusPage(): Component {
  const initialDevice = {
    deviceId: 0,
    deviceType: "",
    deviceIdentifier: "",
    activationStatus: null,
    connected: null,
    modbusError: null,
    signalStrength: null,
    rssi: null,
    lastComm: null,
    statuses: [],
  };
  const currentUser = useSelector(getCurrentUser);
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [sortAscending, setSortAscending] = useState<boolean>(false);
  const [sortColumnIndex, setSortColumnIndex] = useState<number>(1);
  const [monitoringDevices, setMonitoringDevices] = useState<DeviceWithStatus[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedDevice, setSelectedDevice] = useState<DeviceWithStatus>(initialDevice);
  const filteredMonitoringDevices = useMemo(
    () => getFilteredMonitoringDevices(monitoringDevices, filter),
    [JSON.stringify(monitoringDevices), filter]
  );

  // Filter monitoring devices.
  function getFilteredMonitoringDevices(monitoringDevices: DeviceWithStatus[], filter: string): DeviceWithStatus[] {
    return monitoringDevices.filter(
      (monitoringDevice) => monitoringDevice.deviceIdentifier.toLowerCase().indexOf(filter.toLowerCase()) >= 0
    );
  }

  // Get device subscription info.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/device/status`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        const devicesWithStatus: DeviceWithStatus[] = [];
        responseBody.monitoringDevices.forEach((monitoringDevice) => {
          const deviceStatusLookUps = responseBody.deviceStatuses;
          const deviceWithStatus = {
            deviceId: monitoringDevice.deviceId,
            deviceType: monitoringDevice.deviceType,
            deviceIdentifier: monitoringDevice.deviceIdentifier,
            activationStatus: getStatusValueWithLookup(
              "ACTIVATION_STATUS",
              monitoringDevice.statuses,
              deviceStatusLookUps
            ),
            connected: getStatusValueWithLookup("CONNECTED", monitoringDevice.statuses, deviceStatusLookUps),
            modbusError: getStatusValueWithLookup("MODBUS_COMM_ERROR", monitoringDevice.statuses, deviceStatusLookUps),
            signalStrength: getStatusValueWithLookup("SIGNAL_STRENGTH", monitoringDevice.statuses, deviceStatusLookUps),
            rssi: getStatusValueWithLookup("RSSI", monitoringDevice.statuses, deviceStatusLookUps),
            lastComm: getStatusValueWithLookup("LAST_COMM", monitoringDevice.statuses, deviceStatusLookUps),
            statuses: buildStatuses(monitoringDevice.statuses, deviceStatusLookUps),
          };
          devicesWithStatus.push(deviceWithStatus);
        });
        setMonitoringDevices(devicesWithStatus);
        setFilter("");
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    []
  );

  // Finds the value of a status if it exists in the passed in statuses and matches in the normalized statuses.
  function getStatusValueWithLookup(
    statusCode: string,
    statuses: NormalizedStatus[],
    deviceStatusLookUps: DeviceStatusLookUp[]
  ): string | null {
    const statusLookUp = deviceStatusLookUps.find((statusLookUp) => statusLookUp.code === statusCode);
    if (statusLookUp === undefined) {
      return null;
    }
    const deviceStatusId = statusLookUp.deviceStatusId;
    const foundStatus = statuses.find((status) => status.deviceStatusId === deviceStatusId);
    if (foundStatus === undefined) {
      return null;
    }
    return foundStatus.value;
  }

  // Finds the value of a status if it exists in the passed in statuses array or return null if nothing is found.
  function getStatusValue(statusCode: string, statuses: Status[]): string | null {
    const foundStatus = statuses.find((status) => status.code === statusCode);
    if (foundStatus === undefined) {
      return null;
    } else {
      return foundStatus.value;
    }
  }

  // To trim down on the size of the status objects, we send a normalized object. This function builds each status object into a fully denormalized object.
  function buildStatuses(normalizedStatuses: NormalizedStatus[], deviceStatusLookUps: DeviceStatusLookUp[]): Status[] {
    const statuses: Status[] = [];
    normalizedStatuses.forEach((normalizedStatus) => {
      const statusLookUp = deviceStatusLookUps.find(
        (statusLookUp) => statusLookUp.deviceStatusId === normalizedStatus.deviceStatusId
      );
      if (statusLookUp !== undefined) {
        const status = {
          deviceStatusId: normalizedStatus.deviceStatusId,
          value: normalizedStatus.value,
          name: statusLookUp.name,
          code: statusLookUp.code,
          description: statusLookUp.description,
        };
        statuses.push(status);
      }
    });
    return statuses;
  }

  // When the sorting rules change, re-sort all fields.
  useEffect(() => {
    const monitoringDevicesDeepCopy = deepCopy(monitoringDevices);
    setMonitoringDevices(sortStatuses(monitoringDevicesDeepCopy));
  }, [sortAscending, sortColumnIndex]);

  // Update sorting order.
  function updateSort(newIndex: number): void {
    if (newIndex === sortColumnIndex) {
      setSortAscending(!sortAscending);
    } else {
      setSortAscending(false);
      setSortColumnIndex(newIndex);
    }
  }

  // Sort devices based on the current column and ascension rule.
  function sortStatuses(monitoringDevices: DeviceWithStatus[]): DeviceWithStatus[] {
    return monitoringDevices.sort((a, b) => {
      let aValue: string | number = "";
      let bValue: string | number = "";

      switch (sortColumnIndex) {
        case 1:
          aValue = a.deviceIdentifier;
          bValue = b.deviceIdentifier;
          break;
        case 2:
          aValue = a.deviceType;
          bValue = b.deviceType;
          break;
        case 3:
          aValue = a.activationStatus || "N/A";
          bValue = b.activationStatus || "N/A";
          break;
        case 4:
          aValue = a.connected || "N/A";
          bValue = b.connected || "N/A";
          break;
        case 5:
          aValue = a.modbusError || "N/A";
          bValue = b.modbusError || "N/A";
          break;
        case 6:
          if (a.rssi === null) {
            aValue = "N/A";
          } else {
            aValue = parseInt(a.rssi, 10);
          }
          if (b.rssi === null) {
            bValue = "N/A";
          } else {
            bValue = parseInt(b.rssi, 10);
          }
          break;
        case 7:
          aValue = a.lastComm || "N/A";
          bValue = b.lastComm || "N/A";
          break;
        default:
          aValue = a.deviceIdentifier;
          bValue = b.deviceIdentifier;
      }

      if (aValue < bValue) {
        if (sortAscending) {
          return -1;
        } else {
          return 1;
        }
      } else if (aValue > bValue) {
        if (sortAscending) {
          return 1;
        } else {
          return -1;
        }
      } else {
        return 0;
      }
    });
  }

  // Select a monitoring device to view additional information about.
  function selectMonitoringDevice(monitoringDevice: DeviceWithStatus): void {
    setSelectedDevice(monitoringDevice);
    setShowModal(true);
  }

  // Update a monitoring device and its status data.
  function handleUpdatingStatus(monitoringDevice: Device): void {
    const deviceWithStatus = {
      deviceId: monitoringDevice.deviceId,
      deviceType: monitoringDevice.deviceType,
      deviceIdentifier: monitoringDevice.deviceIdentifier,
      activationStatus: getStatusValue("ACTIVATION_STATUS", monitoringDevice.statuses),
      connected: getStatusValue("CONNECTED", monitoringDevice.statuses),
      modbusError: getStatusValue("MODBUS_COMM_ERROR", monitoringDevice.statuses),
      signalStrength: getStatusValue("SIGNAL_STRENGTH", monitoringDevice.statuses),
      rssi: getStatusValue("RSSI", monitoringDevice.statuses),
      lastComm: getStatusValue("LAST_COMM", monitoringDevice.statuses),
      statuses: monitoringDevice.statuses,
    };
    const monitoringDevicesDeepCopy = deepCopy(monitoringDevices);
    const monitoringDeviceIndex = monitoringDevicesDeepCopy.findIndex(
      (monitoringDevice: DeviceWithStatus) => monitoringDevice.deviceId === deviceWithStatus.deviceId
    );

    if (monitoringDeviceIndex > -1) {
      monitoringDevicesDeepCopy[monitoringDeviceIndex] = deviceWithStatus;
      setMonitoringDevices(monitoringDevicesDeepCopy);
      setSelectedDevice(monitoringDevicesDeepCopy[monitoringDeviceIndex]);
    }
  }

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="p-4">
      <Spinner loading={loading} />

      {monitoringDevices.length > 0 ? (
        <FilteredTableContainer
          title={`Monitoring Device Statuses (${filteredMonitoringDevices.length})`}
          filterPrompt="Filter by device identifier..."
          filter={filter}
          hasContent={monitoringDevices.length > 0}
          hasFilteredContent={filteredMonitoringDevices.length > 0}
          showFilterInput={true}
          pluralContentType="devices"
          onChangeFilter={(filter) => setFilter(filter)}
        >
          <table className="table table-hover mb-0 pb-0">
            <thead className={styles.header}>
              <tr>
                <SortedTableHeader
                  name="Device Identifier"
                  index={1}
                  selectedIndex={sortColumnIndex}
                  selectedAscending={sortAscending}
                  onClick={(index) => updateSort(index)}
                />
                <SortedTableHeader
                  name="Device Type"
                  index={2}
                  selectedIndex={sortColumnIndex}
                  selectedAscending={sortAscending}
                  onClick={(index) => updateSort(index)}
                />
                <SortedTableHeader
                  name="Activation Status"
                  index={3}
                  selectedIndex={sortColumnIndex}
                  selectedAscending={sortAscending}
                  onClick={(index) => updateSort(index)}
                />
                <SortedTableHeader
                  name="Connected"
                  index={4}
                  selectedIndex={sortColumnIndex}
                  selectedAscending={sortAscending}
                  onClick={(index) => updateSort(index)}
                />
                <SortedTableHeader
                  name="Modbus Error"
                  index={5}
                  selectedIndex={sortColumnIndex}
                  selectedAscending={sortAscending}
                  onClick={(index) => updateSort(index)}
                />
                <SortedTableHeader
                  name="Signal Strength"
                  index={6}
                  selectedIndex={sortColumnIndex}
                  selectedAscending={sortAscending}
                  onClick={(index) => updateSort(index)}
                />
                <SortedTableHeader
                  name="Last Comm"
                  index={7}
                  selectedIndex={sortColumnIndex}
                  selectedAscending={sortAscending}
                  onClick={(index) => updateSort(index)}
                />
              </tr>
            </thead>
            <tbody>
              {filteredMonitoringDevices.map((monitoringDevice) => (
                <DeviceStatusRow
                  key={monitoringDevice.deviceId}
                  deviceIdentifier={monitoringDevice.deviceIdentifier}
                  deviceType={monitoringDevice.deviceType}
                  activationStatus={monitoringDevice.activationStatus}
                  connected={monitoringDevice.connected}
                  modbusError={monitoringDevice.modbusError}
                  signalStrength={monitoringDevice.signalStrength}
                  lastComm={monitoringDevice.lastComm}
                  onClick={() => selectMonitoringDevice(monitoringDevice)}
                />
              ))}
            </tbody>
          </table>
        </FilteredTableContainer>
      ) : (
        <Fragment>
          {!loading && (
            <div className="col-12 col-xl-10 col-xxl-8 offset-xl-1 offset-xxl-2">
              <Card title="Devices Not Found">
                <div className="my-5">
                  <TextBlurb
                    title="No monitoring devices are registered with this company"
                    paragraph={
                      CUSTOMER_SERVICE_OVERRIDE_MESSAGE === null
                        ? `If you believe this is an error, please contact customer service by email at ${CUSTOMER_SERVICE_EMAIL}` +
                          ` or by phone at ${CUSTOMER_SERVICE_PHONE}.`
                        : `If you believe this is an error, ${CUSTOMER_SERVICE_OVERRIDE_MESSAGE}`
                    }
                    icon="exclamation-triangle"
                  />
                </div>
              </Card>
            </div>
          )}
        </Fragment>
      )}
      <DeviceStatusModal
        showModal={showModal}
        deviceId={selectedDevice.deviceId}
        deviceType={selectedDevice.deviceType}
        deviceIdentifier={selectedDevice.deviceIdentifier}
        statuses={selectedDevice.statuses}
        onClose={() => setShowModal(false)}
        onUpdateStatus={(monitoringDevice) => handleUpdatingStatus(monitoringDevice)}
      />
    </div>
  );
}

interface ResponseBody {
  monitoringDevices: NormalizedDevice[];
  deviceStatuses: DeviceStatusLookUp[];
}

interface NormalizedDevice {
  deviceId: number;
  deviceType: string;
  deviceIdentifier: string;
  statuses: NormalizedStatus[];
}

interface NormalizedStatus {
  deviceStatusId: number;
  value: string;
}

interface DeviceStatusLookUp {
  deviceStatusId: number;
  name: string;
  code: string;
  description: string;
}

interface DeviceWithStatus {
  deviceId: number;
  deviceType: string;
  deviceIdentifier: string;
  activationStatus: string | null;
  connected: string | null;
  modbusError: string | null;
  signalStrength: string | null;
  rssi: string | null;
  lastComm: string | null;
  statuses: Status[];
}

interface Device {
  deviceId: number;
  deviceType: string;
  deviceIdentifier: string;
  statuses: Status[];
}

interface Status {
  description: string;
  deviceStatusId: number;
  name: string;
  value: string;
  code: string;
}
