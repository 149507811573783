// --------------------------------------------------------------
// Created On: 2024-12-05
// Author: Hannah Vaughan
//
// Last Modified: 2024-12-19
// Modified By: Hannah Vaughan
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./CompanyListRow.module.scss";

// Row listing a company's name and whether it is migrating, a packager, and/or a tester.
export default function CompanyListRow(props: Props): Component {
  return (
    <div className="p-2">
      <span data-test="company-name">{props.name}</span>
      {props.isMigrating && (
        <span data-test="company-is-migrating" className={styles.migratingTag}>
          Migrating
        </span>
      )}
      {props.isPackager && (
        <span data-test="company-is-packager" className={styles.packagerTag}>
          Packager
        </span>
      )}
      {props.isTest && (
        <span data-test="company-is-tester" className={styles.testerTag}>
          Tester
        </span>
      )}
    </div>
  );
}

CompanyListRow.propTypes = {
  companyId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isMigrating: PropTypes.bool.isRequired,
  isPackager: PropTypes.bool.isRequired,
  isTest: PropTypes.bool.isRequired,
};

interface Props {
  companyId: number;
  name: string;
  isMigrating: boolean;
  isPackager: boolean;
  isTest: boolean;
}
