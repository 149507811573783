// --------------------------------------------------------------
// Created On: 2021-09-21
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";

// Generic modal footer.
export default function ModalFooter(props: Props): Component {
  return (
    <Modal.Footer data-test="modal-footer" className={props.className || undefined}>
      {props.children}
    </Modal.Footer>
  );
}

ModalFooter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

interface Props {
  className?: string;
  children?: Children;
}
