// --------------------------------------------------------------
// Created On: 2022-01-17
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import apiRequest from "../../../utilities/api/apiRequest";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import formatTitleCase from "../../../utilities/formatTitleCase";
import formatDateLocal from "../../../utilities/time/formatDateLocal";
import { useSelector, useDispatch } from "react-redux";
import { toggleAlert, toggleAllAlerts, deleteAlert, deleteAllAlerts } from "../../../redux/actions";
import { getAlerts } from "../../../redux/selectors";
import { API } from "../../../constants/miscellaneous";
import styles from "./AlertTable.module.scss";

// Table with alert data.
export default function AlertTable(props: Props): Component {
  const [allChecked, setAllChecked] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const alerts = useSelector(getAlerts);
  const dispatch = useDispatch();

  // See if all alerts are read.
  useEffect(() => {
    let tempAllChecked = true;
    alerts.forEach((alert: AlertMessage) => {
      if (alert.unread) {
        tempAllChecked = false;
      }
    });
    setAllChecked(tempAllChecked);
  }, [JSON.stringify(alerts)]);

  // Toggle read status of a single alert message.
  async function toggleAlertMessage(alertMessageId: number, unread: boolean): Promise<void> {
    dispatch(toggleAlert(alertMessageId));
    const requestBody = { alertMessageIds: [alertMessageId], unread: !unread };
    void apiRequest(`${API}/alertmessage`, "PATCH", requestBody);
  }

  // Toggle read status of all alert messages.
  async function toggleAllAlertMessages(): Promise<void> {
    dispatch(toggleAllAlerts());
    const alertMessageIds: number[] = [];
    alerts.forEach((alert) => alertMessageIds.push(alert.alertMessageId));
    const requestBody = { alertMessageIds: alertMessageIds, unread: allChecked };
    void apiRequest(`${API}/alertmessage`, "PATCH", requestBody);
  }

  // Delete a single alert message.
  async function deleteAlertMessage(alertMessageId: number): Promise<void> {
    dispatch(deleteAlert(alertMessageId));
    const requestBody = { alertMessageIds: [alertMessageId] };
    void apiRequest(`${API}/alertmessage`, "DELETE", requestBody);
  }

  // Delete all alert messages.
  async function deleteAllAlertMessages(): Promise<void> {
    dispatch(deleteAllAlerts());
    setShowConfirmDelete(false);
    const alertMessageIds: number[] = [];
    alerts.forEach((alert) => alertMessageIds.push(alert.alertMessageId));
    const requestBody = { alertMessageIds: alertMessageIds };
    void apiRequest(`${API}/alertmessage`, "DELETE", requestBody);
  }

  return !props.loading ? (
    <div className={`${styles.body} shadow-sm mt-5`} data-test="alert-details">
      <div className={`${styles.header} p-4 text-center`}>Alert Details</div>

      {alerts.length > 0 ? (
        <div className="table-responsive">
          <table className={`${styles.alertTable} table mb-0 pb-0`}>
            <thead>
              <tr>
                <th>Urgency</th>
                <th>Message</th>
                <th>Date</th>
                <th>
                  <input
                    className="form-check-input form-check-big"
                    type="checkbox"
                    checked={allChecked}
                    onChange={() => toggleAllAlertMessages()}
                  />
                </th>
                <th>
                  <i
                    className={`${styles.deleteButton} fa fa-fw fa-times`}
                    onClick={() => setShowConfirmDelete(true)}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {alerts.map((alert: AlertMessage) => (
                <tr className="list-item py-2" key={alert.alertMessageId} data-test="alerts-table-row">
                  <td
                    className={`${styles.alert} align-middle`}
                    style={{
                      color: `var(--alert-${alert.urgency})`,
                    }}
                  >
                    {formatTitleCase(alert.urgency)}
                  </td>

                  <td className={`align-middle ${alert.unread ? "" : styles.messageRead}`}>
                    <span>{alert.message}</span>
                  </td>

                  <td className={`align-middle ${alert.unread ? "" : styles.messageRead}`}>
                    {formatDateLocal(alert.date)}
                  </td>

                  <td className="align-middle">
                    <input
                      className="form-check-input form-check-big"
                      type="checkbox"
                      checked={!alert.unread}
                      onChange={() => toggleAlertMessage(alert.alertMessageId, alert.unread)}
                    />
                  </td>

                  <td className="align-middle">
                    <i
                      className={`${styles.deleteButton} fa fa-fw fa-times`}
                      onClick={() => deleteAlertMessage(alert.alertMessageId)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className={`${styles.emptyList} py-5 px-4`}>You do not have any alert messages to view.</div>
      )}

      <ConfirmModal
        showModal={showConfirmDelete}
        title="Delete all alert messages?"
        content={"Are you sure that you want to delete all alert messages? This action cannot be undone."}
        yesText="Delete All"
        noText="Cancel"
        danger={true}
        onClose={() => setShowConfirmDelete(false)}
        onYes={() => deleteAllAlertMessages()}
        onNo={() => setShowConfirmDelete(false)}
      />
    </div>
  ) : null;
}

AlertTable.propTypes = {
  loading: PropTypes.bool.isRequired,
};

interface Props {
  loading: boolean;
}

interface AlertMessage {
  alertMessageId: number;
  alertCode: string;
  date: string;
  message: string;
  unread: boolean;
  urgency: string;
}
