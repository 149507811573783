// --------------------------------------------------------------
// Created On: 2021-09-01
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useEffect, useState } from "react";
import {
  API,
  SECONDS_TO_WAIT_TO_RETURN_DEVICE_DATA,
  SECONDS_BETWEEN_DEVICE_READ_QUERIES,
  MAX_QUERIES_FOR_DEVICE_READ,
  MS_PER_SECOND,
} from "../../constants/miscellaneous";
import Spinner from "../Spinner/Spinner";
import { getCurrentUser } from "../../redux/selectors";
import { useSelector } from "react-redux";
import apiRequest from "../../utilities/api/apiRequest";
import PropTypes from "prop-types";
import styles from "./SampleDeviceDataButton.module.scss";

// When clicked, this button requests the current monitoring device to sample new data.
export default function SampleDeviceDataButton(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [queriesRemaining, setQueriesRemaining] = useState<number>(0);
  const [selectedDeviceId, setSelectedDeviceId] = useState<number | null>(null);
  const [currentIsoTime, setCurrentIsoTime] = useState<string | null>(null);
  const currentUser = useSelector(getCurrentUser);

  // Poll sample data until we get new data or we time out.
  useEffect(() => {
    if (queriesRemaining > 0 && selectedDeviceId !== null && currentIsoTime !== null) {
      const timerId = setTimeout(
        () => checkDeviceUpdated(selectedDeviceId, currentIsoTime, queriesRemaining),
        MS_PER_SECOND * SECONDS_BETWEEN_DEVICE_READ_QUERIES
      );

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [queriesRemaining, selectedDeviceId, currentIsoTime]);

  // Sample device to get the most current device data.
  function sampleDevice(deviceId: number | null): void {
    if (deviceId !== null) {
      void apiRequest(`${API}/company/${currentUser.companyId}/device/${deviceId}/query`, "GET", null);

      const currentDate = new Date();
      if (props.onDeviceError !== undefined) {
        props.onDeviceError("");
      }
      setCurrentIsoTime(currentDate.toISOString().split(".")[0]);
      setSelectedDeviceId(deviceId);
      setQueriesRemaining(MAX_QUERIES_FOR_DEVICE_READ);
      setLoading(true);
      if (props.onIsSamplingChange !== undefined) {
        props.onIsSamplingChange(true);
      }
    }
  }

  // Check if a device's data has been updated and send the appropriate response.
  async function checkDeviceUpdated(deviceId: number, isoTime: string, queriesRemaining: number): Promise<void> {
    const [response, responseBody] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/device/${deviceId}/hasupdated/${isoTime}`,
      "GET",
      null
    )) as [Response, ResponseBody];

    if (response.ok) {
      if (responseBody.hasUpdated) {
        console.log(
          `New device sample data found. Waiting ${SECONDS_TO_WAIT_TO_RETURN_DEVICE_DATA} seconds to batch results.`
        );
        setTimeout(getSampledData, SECONDS_TO_WAIT_TO_RETURN_DEVICE_DATA * MS_PER_SECOND);
      } else if (queriesRemaining <= 1) {
        // The device failed to update.
        setLoading(false);
        if (props.onIsSamplingChange !== undefined) {
          props.onIsSamplingChange(false);
        }
        setCurrentIsoTime(null);
        setSelectedDeviceId(null);
        setQueriesRemaining(0);
        if (props.onDeviceError !== undefined) {
          props.onDeviceError("Monitoring device was unable to take sample.");
        }
      } else {
        // The device is still in the process of updating.
        setQueriesRemaining(queriesRemaining - 1);
      }
    } else {
      // If we get an error, stop trying to process a device update.
      setLoading(false);
      if (props.onIsSamplingChange !== undefined) {
        props.onIsSamplingChange(false);
      }
      setCurrentIsoTime(null);
      setSelectedDeviceId(null);
      setQueriesRemaining(0);
      if (props.onDeviceError !== undefined) {
        props.onDeviceError("Monitoring device was unable to take sample.");
      }
    }
  }

  // An updated sample exists and should be returned.
  function getSampledData(): void {
    setLoading(false);
    if (props.onIsSamplingChange !== undefined) {
      props.onIsSamplingChange(false);
    }
    setCurrentIsoTime(null);
    setSelectedDeviceId(null);
    setQueriesRemaining(0);
    if (props.onDeviceRead !== undefined) {
      props.onDeviceRead();
    }
  }

  return (
    <Fragment>
      <Spinner loading={loading} />
      <button
        data-test="sample-device-button"
        className={`${props.smallButton ? styles.smallBody : styles.body} btn btn-primary ${props.className}`}
        type="button"
        disabled={props.disabled || selectedDeviceId !== null}
        onClick={() => sampleDevice(props.deviceId)}
      >
        <span className="d-none d-lg-inline-block d-xl-none d-xxl-inline-block">Sample Device</span>
        <span className="d-inline-block d-lg-none d-xl-inline-block d-xxl-none">Sample</span>
      </button>
    </Fragment>
  );
}

SampleDeviceDataButton.propTypes = {
  deviceId: PropTypes.number,
  smallButton: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onLoadingStateChange: PropTypes.func,
  onIsSamplingChange: PropTypes.func,
  onDeviceRead: PropTypes.func,
  onDeviceError: PropTypes.func,
};

interface Props {
  deviceId: number | null;
  smallButton?: boolean;
  disabled?: boolean;
  className?: string;
  onIsSamplingChange?: (isSampling: boolean) => void;
  onDeviceRead?: () => void;
  onDeviceError?: (errorMessage: string) => void;
}

interface ResponseBody {
  hasUpdated: boolean;
}
