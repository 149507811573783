// --------------------------------------------------------------
// Created On: 2022-06-21
// Author: Zachary Thomas
//
// Last Modified: 2025-01-14
// Modified By: Zachary Thomas
//
// Copyright 2024 - 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useMemo, useEffect } from "react";
import Error from "../../components/Error/Error";
import Warning from "../../components/Warning/Warning";
import getApiError from "../../utilities/api/getApiError";
import nowToLocalIso from "../../utilities/time/nowToLocalIso";
import apiRequest from "../../utilities/api/apiRequest";
import Spinner from "../../components/Spinner/Spinner";
import AccumulationChart from "../../components/AccumulationChart/AccumulationChart";
import LineChart from "../../components/LineChart/LineChart";
import GraphControls from "./GraphControls/GraphControls";
import Card from "../../components/Card/Card";
import isoAddDays from "../../utilities/time/isoAddDays";
import isoToDate from "../../utilities/time/isoToDate";
import isoToLocalDate from "../../utilities/time/isoToLocalDate";
import isoToTimestamp from "../../utilities/time/isoToTimestamp";
import stringIsValidNumber from "../../utilities/stringIsValidNumber";
import Error500Page from "../Error500Page/Error500Page";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSelectedDeviceAttributes, getCurrentUser } from "../../redux/selectors";
import smoothTimeDataSeries from "../../utilities/smoothTimeDataSeries";
import useApi from "../../hooks/useApi";
import { ACCUMULATION_SUPPORTED_ATTRIBUTES } from "../../constants/attributes";
import {
  API,
  DATA_STARTING_DATE_OFFSET,
  DATA_ENDING_DATE_OFFSET,
  MAX_NUMBER_OF_GRAPHING_ATTRIBUTES,
} from "../../constants/miscellaneous";
import styles from "./AssetAttributeGraphPage.module.scss";

// Page for querying custom attribute graphs for the current asset.
export default function AssetAttributeGraphPage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [assetName, setAssetName] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [startDate, setStartDate] = useState<string>(nowToLocalIso(DATA_STARTING_DATE_OFFSET).split("T")[0]);
  const [endDate, setEndDate] = useState<string>(nowToLocalIso(DATA_ENDING_DATE_OFFSET).split("T")[0]);
  const [queriedStartDate, setQueriedStartDate] = useState(nowToLocalIso(DATA_STARTING_DATE_OFFSET).split("T")[0]);
  const [queriedEndDate, setQueriedEndDate] = useState(nowToLocalIso(DATA_ENDING_DATE_OFFSET).split("T")[0]);
  const [graphingMode, setGraphingMode] = useState<GraphingMode>("STANDARD");
  const [lineSeries, setLineSeries] = useState<TimeDataSeries[] | null>(null);
  const [warningMessage, setWarningMessage] = useState<string>("");
  const selectedDeviceAttributes = useSelector(getSelectedDeviceAttributes);
  const selectedAccumulationDeviceAttributes = useMemo(
    () =>
      selectedDeviceAttributes.filter((selectedDeviceAttribute) =>
        ACCUMULATION_SUPPORTED_ATTRIBUTES.includes(selectedDeviceAttribute.code)
      ),
    [JSON.stringify(selectedDeviceAttributes)]
  );
  const currentUser = useSelector(getCurrentUser);
  const { assetId } = useParams();
  const inAccumulationMode = [
    "HOURLY_ACCUMULATION",
    "DAILY_ACCUMULATION",
    "WEEKLY_ACCUMULATION",
    "MONTHLY_ACCUMULATION",
  ].includes(graphingMode);

  // Get the asset's name.
  useApi(
    () => {
      if (assetId !== undefined && parseInt(assetId, 10) > 0) {
        setLoading(true);
        return true;
      } else {
        setLoading(false);
        return false;
      }
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/asset/${assetId}/document`,
    },
    async (response: Response, responseBody: AssetNameResponseBody) => {
      if (response.ok && responseBody) {
        setAssetName(responseBody.assetName);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(false);
      }
      setLoading(false);
    },
    [assetId]
  );

  // When the graphing mode is changed, clear any existing graphs.
  useEffect(() => {
    setLineSeries(null);
  }, [graphingMode]);

  // Check if the current query is valid.
  function queryIsValid(): boolean {
    if (startDate.length === 0) {
      setErrorMessage("A valid start date must be selected.");
      return false;
    } else if (endDate.length === 0) {
      setErrorMessage("A valid end date must be selected.");
      return false;
    } else if (!inAccumulationMode && selectedDeviceAttributes.length === 0) {
      setErrorMessage("At least one attribute must be selected to query data.");
      return false;
    } else if (!inAccumulationMode && selectedDeviceAttributes.length > MAX_NUMBER_OF_GRAPHING_ATTRIBUTES) {
      setErrorMessage(`You may not graph more than ${MAX_NUMBER_OF_GRAPHING_ATTRIBUTES} attributes at once.`);
      return false;
    } else if (inAccumulationMode && selectedAccumulationDeviceAttributes.length === 0) {
      setErrorMessage("At least one attribute must be selected to query data.");
      return false;
    } else if (inAccumulationMode && selectedAccumulationDeviceAttributes.length > MAX_NUMBER_OF_GRAPHING_ATTRIBUTES) {
      setErrorMessage(`You may not graph more than ${MAX_NUMBER_OF_GRAPHING_ATTRIBUTES} attributes at once.`);
      return false;
    } else {
      return true;
    }
  }

  // Update the graph by getting API data.
  async function handleGraphUpdate(): Promise<void> {
    if (!queryIsValid() || assetId === undefined) {
      return;
    }

    setQueriedStartDate(startDate);
    setQueriedEndDate(endDate);
    const deviceAttributeCodes: string[] = [];

    selectedDeviceAttributes.forEach((deviceAttribute) => deviceAttributeCodes.push(deviceAttribute.code));

    // Call the API endpoint once for each attribute that we are interested in.
    const apiRequests: Promise<[Response, HistoryResponseBody]>[] = [];
    let queryAttributes: DeviceAttribute[] = selectedDeviceAttributes;
    if (inAccumulationMode) {
      queryAttributes = selectedAccumulationDeviceAttributes;
    }

    queryAttributes.forEach((queryAttribute) => {
      const requestBody = {
        startDate: `${startDate}T00:00:00`,
        endDate: isoAddDays(`${endDate}T00:00:00`, 1).split(".")[0],
        companyId: currentUser.companyId,
        attributeCode: queryAttribute.code,
      };
      setLoading(true);
      const apiRequestPromise = apiRequest(
        `${API}/company/${currentUser.companyId}/asset/${assetId}/history`,
        "POST",
        requestBody
      ) as unknown;
      apiRequests.push(apiRequestPromise as Promise<[Response, HistoryResponseBody]>);
    });

    // Wait for all of the API requests to resolve.
    const resolvedPromises = await Promise.all(apiRequests);
    setLoading(false);

    // Process each API request that have resolved.
    let apiErrorResponse = null;
    const tempSeriesArray: TimeDataSeries[] = [];

    resolvedPromises.forEach(([response, responseBody]) => {
      if (response.ok && responseBody) {
        let unitLongName = "";
        let unitShortName = "";

        const startDateObject = isoToDate(`${startDate}T00:00:00`);
        const endDateObject = isoToDate(isoAddDays(`${endDate}T00:00:00`, 1));

        const historicalAttribute = responseBody;
        if (historicalAttribute !== null && historicalAttribute !== undefined) {
          let name = historicalAttribute.attributeName;
          if (name === null || name === undefined) {
            name = "";
          }

          unitLongName = "";
          unitShortName = "";
          if (unitLongName !== null && unitLongName !== undefined) {
            unitLongName = historicalAttribute.unitLongName;
          }
          if (unitShortName !== null || unitShortName !== undefined) {
            unitShortName = historicalAttribute.unitShortName;
          }

          // Convert incoming data into a series of tuples that supports graphing.
          const series: TimeDataSeries = {
            name: name,
            unitName: unitLongName,
            unitShortName: unitShortName,
            data: [],
          };

          historicalAttribute.dataPoints.forEach((dataPoint: HistoricalDataPoint) => {
            const rowDateObject = isoToLocalDate(dataPoint.dateTimeUtc);

            // If the data point is a boolean, return it as a string representation of a number.
            if (dataPoint.value.toLowerCase() === "true") {
              dataPoint.value = "1";
            } else if (dataPoint.value.toLowerCase() === "false") {
              dataPoint.value = "0";
            }

            if (
              rowDateObject >= startDateObject &&
              rowDateObject < endDateObject &&
              stringIsValidNumber(dataPoint.value)
            ) {
              series.data.push([isoToTimestamp(dataPoint.dateTimeUtc), parseFloat(dataPoint.value)]);
            }
          });

          tempSeriesArray.push(series);
        }
      } else {
        apiErrorResponse = response;
      }
    });

    // Check if there was an error while processing API responses.
    if (apiErrorResponse !== null) {
      setErrorMessage(await getApiError(apiErrorResponse, "Unable to get graph data."));
    } else {
      setErrorMessage("");
    }

    // Check if any attributes are returning no records and warn the user.
    const emptyAttributes: string[] = [];
    tempSeriesArray.forEach((timeSeries) => {
      if (timeSeries.data.length === 0) {
        emptyAttributes.push(timeSeries.name);
      }
    });

    if (emptyAttributes.length === 1) {
      setWarningMessage(`No data could be found for '${emptyAttributes[0]}' in the date range.`);
    } else if (emptyAttributes.length > 1) {
      setWarningMessage(
        `No data could be found for any of the following attributes in the date range: ${emptyAttributes.join(", ")}.`
      );
    } else {
      setWarningMessage("");
    }

    // If a graphing mode was set, apply it now.
    if (graphingMode === "MOVING_AVERAGE") {
      const smoothTimeSeriesArray: TimeDataSeries[] = [];
      tempSeriesArray.forEach((timeSeries) => smoothTimeSeriesArray.push(smoothTimeDataSeries(timeSeries)));
      setLineSeries(smoothTimeSeriesArray);
    } else {
      setLineSeries(tempSeriesArray);
    }
  }

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="p-4">
      <Spinner loading={loading} />

      <Card title={`Graph for ${assetName}`}>
        <div className={styles.body}>
          <GraphControls
            startDate={startDate}
            endDate={endDate}
            mode={graphingMode}
            onChangeStartDate={(startDate) => setStartDate(startDate)}
            onChangeEndDate={(endDate) => setEndDate(endDate)}
            onChangeMode={(mode) => setGraphingMode(mode)}
            onGraph={() => handleGraphUpdate()}
          />

          {lineSeries !== null && !inAccumulationMode && <LineChart series={lineSeries} />}

          {lineSeries !== null && inAccumulationMode && (
            <AccumulationChart
              series={lineSeries}
              accumulationType={graphingMode as AccumulationGraphingMode}
              startDate={queriedStartDate}
              endDate={queriedEndDate}
            />
          )}

          <div className="mx-3">
            <Warning message={warningMessage} />
            <Error message={errorMessage} />
          </div>
        </div>
      </Card>
    </div>
  );
}

interface AssetNameResponseBody {
  assetName: string;
}

interface HistoryResponseBody {
  attributeName: string;
  unitLongName: string;
  unitShortName: string;
  dataPoints: HistoricalDataPoint[];
  error?: string;
}

interface HistoricalDataPoint {
  dateTimeUtc: string;
  value: string;
  isCachedUpload?: boolean;
  uploadDateUtc?: string | null;
}

interface TimeDataSeries {
  name: string;
  unitName: string;
  unitShortName: string;
  data: [number, number][];
}

type GraphingMode =
  | "STANDARD"
  | "MOVING_AVERAGE"
  | "HOURLY_ACCUMULATION"
  | "DAILY_ACCUMULATION"
  | "WEEKLY_ACCUMULATION"
  | "MONTHLY_ACCUMULATION";

type AccumulationGraphingMode =
  | "HOURLY_ACCUMULATION"
  | "DAILY_ACCUMULATION"
  | "WEEKLY_ACCUMULATION"
  | "MONTHLY_ACCUMULATION";
