// --------------------------------------------------------------
// Created On: 2024-12-20
// Author: Hannah Vaughan
//
// Last Modified: 2025-01-03
// Modified By: Zachary Thomas
//
// Copyright 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";

// Explains what a company is.
export default function HelpCompany(): Component {
  return (
    <div>
      <p className="px-3 my-2">
        Each company can be a packager, in migration, and/or a testing company. Each company can also be associated with
        an Epicor account to manage device ownership, or device ownership can be managed on a individual device basis.
      </p>

      <p className="px-3 mt-3 mb-2">
        <b>Packager:</b> If a company is flagged as a packager, then that company cannot view the actual data being
        reported for assets (prevents them from tracking the location of assets via GPS). As a packager they are also
        required to pass a validation check before they are able to transfer any assets.
      </p>

      <p className="px-3 mt-3 mb-2">
        <b>Migrating:</b> If a company is migrating, then when a new asset is created it will automatically be flagged
        as in migration. Assets that are in migration will not apply templates and configuration until they are manually
        taken out of migration.
      </p>

      <p className="px-3 mt-3 mb-2">
        <b>Tester:</b> Tester companies do not show any of their information on reports that are generated from the
        super admin dashboard. For example, if you generate a report for the number of assets across all companies, if
        100 assets exist in tester companies, then these assets will not show on the report. The same is true for users,
        companies, alerts, alert thresholds, etc.
      </p>

      <p className="px-3 mt-3 mb-2">
        <b>Associated Account:</b> A company can optionally be associated with an Epicor account. A company associated
        with an Epicor account will be granted ownership of all monitoring devices that are owned by that account.
      </p>

      <p className="px-3 mt-3 mb-2">
        <b>Owned Devices:</b> In addition to the ability to grant ownership of monitoring devices to a company by
        linking an Epicor account. It is also possible to link ownership of individual devices to a company.
      </p>
    </div>
  );
}
