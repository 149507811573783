// --------------------------------------------------------------
// Created On: 2022-12-09
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import stringIsValidNumber from "./stringIsValidNumber";
import distanceGpsCoordinates from "./distanceGpsCoordinates";
import { LOCATION_MIN_GPS_DISTANCE_BETWEEN_POINTS_KM } from "../constants/miscellaneous";

// Clean up and format location history API response body.
export default function cleanLocationHistory(responseBody: ResponseBody): ProcessedResponseBody {
  let locationHistoryPoints: LocationHistoryPoint[] = [];

  // Get all historical location data into a sorted array.
  responseBody.dataPoints.forEach((dataPoint) => {
    const gpsValues = dataPoint.value.split(",");
    if (gpsValues.length === 2 && stringIsValidNumber(gpsValues[0]) && stringIsValidNumber(gpsValues[1])) {
      const newLocation = {
        changeDate: dataPoint.dateTimeUtc.split(".")[0],
        latitude: parseFloat(gpsValues[0]),
        longitude: parseFloat(gpsValues[1]),
        hidden: false,
      };
      locationHistoryPoints.push(newLocation);
    }
  });

  // Sort the locations in order of date.
  locationHistoryPoints = locationHistoryPoints.sort((a, b) => {
    const dateA = a.changeDate.toUpperCase();
    const dateB = b.changeDate.toUpperCase();
    if (dateA < dateB) {
      return 1;
    } else if (dateA > dateB) {
      return -1;
    } else {
      return 0;
    }
  });

  // Remove points that are within a very close proximity of each other.
  const filteredLocationHistoryPoints: LocationHistoryPoint[] = [];
  let previousPoint: LocationHistoryPoint | null = null;
  locationHistoryPoints.forEach((locationHistoryPoint, i) => {
    // If this is the first or the last point, never remove it.
    // Otherwise device must have moved more than the minimum amount to add this point.
    if (
      previousPoint === null ||
      i === locationHistoryPoints.length - 1 ||
      distanceGpsCoordinates(
        locationHistoryPoint.latitude,
        locationHistoryPoint.longitude,
        previousPoint.latitude,
        previousPoint.longitude
      ) > LOCATION_MIN_GPS_DISTANCE_BETWEEN_POINTS_KM
    ) {
      filteredLocationHistoryPoints.push(locationHistoryPoint);
      previousPoint = locationHistoryPoint;
    }
  });

  const processedResponseBody = {
    dataPoints: filteredLocationHistoryPoints,
  };

  console.log("Processed Response Body: ", processedResponseBody);
  return processedResponseBody;
}

interface ResponseBody {
  dataPoints: HistoricalDataPoint[];
}

interface HistoricalDataPoint {
  dateTimeUtc: string;
  value: string;
  isCachedUpload?: boolean;
  uploadDateUtc?: string | null;
}

interface ProcessedResponseBody {
  dataPoints: LocationHistoryPoint[];
}

interface LocationHistoryPoint {
  changeDate: string;
  latitude: number;
  longitude: number;
  speed?: string;
  hidden: boolean;
}
