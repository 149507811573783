// --------------------------------------------------------------
// Created On: 2024-07-22
// Author: Hannah Vaughan
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useEffect, useState } from "react";
import MainConfigurationControls from "./MainConfigurationControls/MainConfigurationControls";
import useApi from "../../hooks/useApi";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import deepCopy from "../../utilities/deepCopy";
import Toast from "../../components/Toast/Toast";
import {
  API,
  MIN_VALUE_FOR_SIGNED_REGISTER,
  MAX_VALUE_FOR_SIGNED_REGISTER,
  MIN_VALUE_FOR_UNSIGNED_REGISTER,
  MAX_VALUE_FOR_UNSIGNED_REGISTER,
} from "../../constants/miscellaneous";
import { getCurrentUser } from "../../redux/selectors";
import Error500Page from "../Error500Page/Error500Page";
import Spinner from "../../components/Spinner/Spinner";
import apiRequest from "../../utilities/api/apiRequest";
import getApiError from "../../utilities/api/getApiError";
import ControllerPageWrapper from "./ControllerPageWrapper/ControllerPageWrapper";
import { CONFIGURE_CONTROLLERS_PERMISSION } from "../../constants/permissions";
import ControllerNotSupportedCard from "./ControllerNotSupportedCard/ControllerNotSupportedCard";
import Accordion from "../../components/Accordion/Accordion";
import stringIsValidNumber from "../../utilities/stringIsValidNumber";
import userHasPermission from "../../utilities/userHasPermission";

// Page that supports remote controller configuration and on demand register reads / writes for the current asset.
export default function AssetControllerPage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [assetName, setAssetName] = useState<string>("");
  const [configPages, setConfigPages] = useState<Page[]>([]);
  const [refreshPageCount, setRefreshPageCount] = useState<number>(0);
  const [registerMode, setRegisterMode] = useState<RegisterMode>("READ");
  const [startAddressInput, setStartAddressInput] = useState<string>("");
  const [dataTypeValue, setDataTypeValue] = useState<number>(0);
  const [serverIdInput, setServerIdInput] = useState<string>("");
  const [byteOrderValue, setByteOrderValue] = useState<number>(-1);
  const [configValueInput, setConfigValueInput] = useState<string>("");
  const [operationIsAllowed, setOperationIsAllowed] = useState<boolean>(true);
  const currentUser = useSelector(getCurrentUser);
  const { assetId } = useParams();

  // Get all controller configuration information for the current asset from the API.
  useApi(
    () => {
      if (assetId !== undefined) {
        setLoading(true);
        return true;
      } else {
        setLoading(false);
        return false;
      }
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/asset/${assetId}/controllerconfig`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        if (responseBody.assetName !== undefined) {
          setAssetName(responseBody.assetName);
        }
        if (responseBody.operationIsAllowed !== undefined) {
          setOperationIsAllowed(responseBody.operationIsAllowed);
        }
        initializeControllerConfiguration(responseBody.pages, null);
        setConfigPages(responseBody.pages);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [assetId, refreshPageCount]
  );

  // If the register mode changes, clear the config value input.
  // This to prevent confusion about a values origin when switching between modes.
  useEffect(() => {
    setConfigValueInput("");
  }, [registerMode]);

  // Gets an array of category names from an array of pages.
  function getCategoryNames(pages: Page[]): string[] {
    const categoryNames: string[] = [];
    pages.forEach((page) => page.categories.forEach((category) => categoryNames.push(category.categoryName)));
    return categoryNames;
  }

  // Check if the register selections are valid.
  function registerAddressIsValid(checkByteOrder: boolean, checkValue: boolean): boolean {
    if (startAddressInput.trim() === "") {
      setErrorMessage("An address must be specified.");
      return false;
    } else if (dataTypeValue === 0) {
      setErrorMessage("A data type must be specified.");
      return false;
    } else if (serverIdInput.trim() === "") {
      setErrorMessage("A server ID must be specified.");
      return false;
    } else if (checkByteOrder && byteOrderValue === -1) {
      setErrorMessage("A byte order must be specified.");
      return false;
    } else if (checkValue && configValueInput.trim() === "") {
      setErrorMessage("A value must be specified.");
      return false;
    } else {
      return true;
    }
  }

  // Either reads the controller configuration value with the provided start address, data type, and server ID or
  // writes a new value to the controller configuration with the provided start address, data type, and server ID.
  async function readWriteConfigValue(): Promise<void> {
    if (registerMode === "READ") {
      void readFromRegister();
    } else if (registerMode === "WRITE") {
      void writeToRegister();
    } else {
      setErrorMessage("Internal server error. Unknown read/write mode selected.");
    }
  }

  // Reads a value from a specific register.
  async function readFromRegister(): Promise<void> {
    if (registerAddressIsValid(false, false)) {
      const requestBody = {
        startAddress: parseInt(startAddressInput),
        slaveId: parseInt(serverIdInput),
        dataType: dataTypeValue,
        value: 0, // The value doesn't matter here because this request is only reading the value, not updating it.
        byteOrder: byteOrderValue,
      };

      setLoading(true);
      const [response, responseBody] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/asset/${assetId}/controllerconfig/register`,
        "POST",
        requestBody
      )) as [Response, ReadRegisterResponseBody];
      setLoading(false);

      if (response.ok && responseBody) {
        setConfigValueInput(`${responseBody.value}`);
        setErrorMessage("");
      } else {
        setErrorMessage(await getApiError(response, "Unable to read register."));
      }
    }
  }

  // Writes a value to a specific register.
  async function writeToRegister(): Promise<void> {
    if (registerAddressIsValid(true, true)) {
      const requestBody = {
        startAddress: parseInt(startAddressInput),
        slaveId: parseInt(serverIdInput),
        dataType: dataTypeValue,
        value: parseFloat(configValueInput),
        byteOrder: byteOrderValue,
      };

      setLoading(true);
      const [response] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/asset/${assetId}/controllerconfig/setregister`,
        "PUT",
        requestBody
      )) as [Response, SetRegisterResponseBody];
      setLoading(false);

      if (response.ok) {
        setErrorMessage("");
      } else {
        setErrorMessage(await getApiError(response, "Unable to write to register."));
      }
    }
  }

  // Reads the updated category based on the provided pageName and categoryName.
  async function handleReadCategory(pageName: string, categoryName: string): Promise<void> {
    const requestBody = {
      categories: [categoryName],
    };

    setLoading(true);
    const [response, responseBody] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/asset/${assetId}/controllerconfig`,
      "POST",
      requestBody
    )) as [Response, ResponseBody];

    if (response.ok && responseBody) {
      const pagesDeepCopy: Page[] = deepCopy(configPages);
      const sourcePageIndex = responseBody.pages.findIndex((page) => page.pageName === pageName);
      const targetPageIndex = pagesDeepCopy.findIndex((page) => page.pageName === pageName);
      if (sourcePageIndex > -1 && targetPageIndex > -1) {
        const sourceCategoryIndex = responseBody.pages[sourcePageIndex].categories.findIndex(
          (category) => category.categoryName === categoryName
        );
        const targetCategoryIndex = pagesDeepCopy[targetPageIndex].categories.findIndex(
          (category) => category.categoryName === categoryName
        );
        if (sourceCategoryIndex > -1 && targetCategoryIndex > -1) {
          // Reset the previous value for the updated category.
          initializeControllerConfiguration(null, responseBody.pages[sourcePageIndex].categories);
          // Apply the values of the updated category to the complete collection of pages.
          pagesDeepCopy[targetPageIndex].categories[targetCategoryIndex] =
            responseBody.pages[sourcePageIndex].categories[sourceCategoryIndex];
          setConfigPages(pagesDeepCopy);
          setErrorMessage("");
        }
      }
    } else {
      setErrorMessage(await getApiError(response, "Unable to read controller configuration category."));
    }
    setLoading(false);
  }

  // Reads all configuration data for the controller.
  async function handleReadAll(): Promise<void> {
    const requestBody = {
      categories: getCategoryNames(configPages),
    };
    setLoading(true);
    const [response, responseBody] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/asset/${assetId}/controllerconfig`,
      "POST",
      requestBody
    )) as [Response, ResponseBody];

    if (response.ok && responseBody) {
      setRefreshPageCount((previous) => previous + 1);
    } else {
      setErrorMessage(await getApiError(response, "Unable to sample all parameters."));
    }
    setLoading(false);
  }

  // Initializes previous value and tooltip for all controller configurations in place.
  function initializeControllerConfiguration(pages: Page[] | null, categories: Category[] | null): void {
    if (pages !== null) {
      pages.forEach((page) =>
        page.categories.forEach((category) =>
          category.controllerConfigurations.forEach((controllerConfiguration) => {
            controllerConfiguration.previousValue = controllerConfiguration.value;
            controllerConfiguration.tooltip = `[Address]: ${controllerConfiguration.startAddress} [Overview]: ${controllerConfiguration.tooltip}`;
          })
        )
      );
    }
    if (categories !== null) {
      categories.forEach((category) =>
        category.controllerConfigurations.forEach((controllerConfiguration) => {
          controllerConfiguration.previousValue = controllerConfiguration.value;
          controllerConfiguration.tooltip = `[Address]: ${controllerConfiguration.startAddress} [Overview]: ${controllerConfiguration.tooltip}`;
        })
      );
    }
  }

  // Updates the current value of a controller configuration.
  function handleChangeControllerConfig(
    pageName: string,
    categoryName: string,
    controllerConfigurationId: number,
    value: string
  ): void {
    const configPagesDeepCopy = deepCopy(configPages);
    const configPage = configPagesDeepCopy.find((configPage) => configPage.pageName === pageName);
    if (configPage !== undefined) {
      const category = configPage.categories.find((category) => category.categoryName === categoryName);
      if (category !== undefined) {
        const controllerConfiguration = category.controllerConfigurations.find(
          (controllerConfiguration) => controllerConfiguration.controllerConfigurationId === controllerConfigurationId
        );
        if (controllerConfiguration !== undefined) {
          controllerConfiguration.value = value;
          setConfigPages(configPagesDeepCopy);
        }
      }
    }
  }

  // Write a specific value to a specific controller configuration.
  async function handleWriteControllerConfig(
    pageName: string,
    categoryName: string,
    controllerConfigurationId: number,
    value: string
  ): Promise<void> {
    // Get the target controller configuration category.
    let controllerConfiguration: ControllerConfiguration | undefined = undefined;
    const pagesDeepCopy = deepCopy(configPages);
    const page = pagesDeepCopy.find((page) => page.pageName === pageName);
    if (page !== undefined) {
      const category = page.categories.find((category) => category.categoryName === categoryName);
      if (category !== undefined) {
        controllerConfiguration = category.controllerConfigurations.find(
          (controllerConfiguration) => controllerConfiguration.controllerConfigurationId === controllerConfigurationId
        );
      }
    }

    // Check if we can write to this register.
    if (isValidConfigurationWrite(controllerConfiguration) && controllerConfiguration !== undefined) {
      const requestBody = {
        controllerConfigurationId: controllerConfigurationId,
        value: value,
      };
      setLoading(true);
      const [response, responseBody] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/asset/${assetId}/controllerconfig`,
        "PUT",
        requestBody
      )) as [Response, UpdateRegisterResponseBody];
      if (response.ok && responseBody) {
        setErrorMessage("");
        // Since we were successful in writing to the config,
        // we want to update our previous value so that we cannot write the same exact value again until it changes.
        controllerConfiguration.previousValue = value;
        setConfigPages(pagesDeepCopy);
      } else {
        setErrorMessage(await getApiError(response, "Unable to write to register."));
      }
      setLoading(false);
    }
  }

  // Check if a write to a controller configuration is valid.
  function isValidConfigurationWrite(controllerConfiguration: ControllerConfiguration | undefined): boolean {
    if (controllerConfiguration === undefined) {
      setErrorMessage(`Internal server error. Could not find register to write to.`);
      return false;
    } else if (!stringIsValidNumber(`${controllerConfiguration.value}`)) {
      setErrorMessage(`Entered value is not a valid number.`);
      return false;
    } else if (
      controllerConfiguration.displaySigned &&
      (parseFloat(`${controllerConfiguration.value}`) < MIN_VALUE_FOR_SIGNED_REGISTER ||
        parseFloat(`${controllerConfiguration.value}`) > MAX_VALUE_FOR_SIGNED_REGISTER)
    ) {
      setErrorMessage(
        `Value to write must be between ${MIN_VALUE_FOR_SIGNED_REGISTER} and ${MAX_VALUE_FOR_SIGNED_REGISTER}.`
      );
      return false;
    } else if (
      !controllerConfiguration.displaySigned &&
      (parseFloat(`${controllerConfiguration.value}`) < MIN_VALUE_FOR_UNSIGNED_REGISTER ||
        parseFloat(`${controllerConfiguration.value}`) > MAX_VALUE_FOR_UNSIGNED_REGISTER)
    ) {
      setErrorMessage(
        `Value to write must be between ${MIN_VALUE_FOR_UNSIGNED_REGISTER} and ${MAX_VALUE_FOR_UNSIGNED_REGISTER}.`
      );
      return false;
    } else {
      return true;
    }
  }

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="container py-4">
      <Spinner loading={loading} />

      <Toast
        title="Error"
        message={errorMessage}
        show={errorMessage.length > 0}
        onClose={() => setErrorMessage("")}
        type="error"
      />

      {configPages.length > 0 && operationIsAllowed && userHasPermission([[CONFIGURE_CONTROLLERS_PERMISSION]]) ? (
        <Fragment>
          <MainConfigurationControls
            assetName={assetName}
            registerMode={registerMode}
            startAddressInput={startAddressInput}
            dataTypeValue={dataTypeValue}
            serverIdInput={serverIdInput}
            byteOrderValue={byteOrderValue}
            configValueInput={configValueInput}
            loading={loading}
            onClickSampleAllParameters={() => handleReadAll()}
            onChangeRegisterMode={(isWrite) => setRegisterMode(isWrite ? "WRITE" : "READ")}
            onChangeStartAddressInput={(startAddressInput) => setStartAddressInput(startAddressInput)}
            onChangeDataTypeValue={(dataTypeValue) => setDataTypeValue(dataTypeValue)}
            onChangeServerIdInput={(serverIdInput) => setServerIdInput(serverIdInput)}
            onChangeByteOrderValue={(byteOrderValue) => setByteOrderValue(byteOrderValue)}
            onChangeConfigValueInput={(configValueInput) => setConfigValueInput(configValueInput)}
            onClickReadWriteConfigValue={() => readWriteConfigValue()}
          />
          <div className="row justify-content-center mt-2">
            {configPages.map((configPage) => (
              <Accordion
                key={configPage.pageName.replace(/\s/g, "")}
                className="my-3"
                id={configPage.pageName.replace(/\s/g, "")}
                title={configPage.pageName}
              >
                <ControllerPageWrapper
                  key={configPage.pageName.replace(/\s/g, "")}
                  id={configPage.pageName.replace(/\s/g, "")}
                  page={configPage.pageName}
                  categories={configPage.categories}
                  loading={loading}
                  onReadCategory={(categoryName) => handleReadCategory(configPage.pageName, categoryName)}
                  onChangeControllerConfig={(categoryName, controllerConfigurationId, value) =>
                    handleChangeControllerConfig(configPage.pageName, categoryName, controllerConfigurationId, value)
                  }
                  onWriteControllerConfig={(categoryName, controllerConfigurationId, value) =>
                    handleWriteControllerConfig(configPage.pageName, categoryName, controllerConfigurationId, value)
                  }
                />
              </Accordion>
            ))}
          </div>
        </Fragment>
      ) : (
        <ControllerNotSupportedCard assetName={assetName} loading={loading} operationIsAllowed={operationIsAllowed} />
      )}
    </div>
  );
}

interface ResponseBody {
  assetName: string;
  operationIsAllowed: boolean;
  pages: Page[];
}

interface UpdateRegisterResponseBody {
  controllerConfigurations: ControllerConfiguration[];
}

interface ReadRegisterResponseBody {
  value: number;
}

interface SetRegisterResponseBody {
  message: string;
}

type RegisterMode = "READ" | "WRITE";

interface Page {
  pageName: string;
  categories: Category[];
}

interface Category {
  categoryName: string;
  tooltip: string;
  controllerConfigurations: ControllerConfiguration[];
}

interface ControllerConfiguration {
  controllerConfigurationId: number;
  name: string;
  tooltip: string;
  startAddress: string;
  units: string | null;
  value: string | number | null;
  previousValue: string | number | null;
  displaySigned: boolean;
}
