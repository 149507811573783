// --------------------------------------------------------------
// Created On: 2021-08-06
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useMemo } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import isoToDate from "../../utilities/time/isoToDate";
import formatDateObjectDailyLocal from "../../utilities/time/formatDateObjectDailyLocal";
import formatDateObjectHourlyLocal from "../../utilities/time/formatDateObjectHourlyLocal";
import { MS_PER_HOUR } from "../../constants/miscellaneous";
import { BREAKPOINT_SMALL } from "../../constants/breakpoints";
import PropTypes from "prop-types";

// Accumulation chart for viewing data grouped by time slices.
export default function AccumulationChart(props: Props): Component {
  const defaultColors = ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"];
  const [series, options] = useMemo(
    () => getFormattedData(props.startDate, props.endDate, props.series, props.accumulationType),
    [JSON.stringify(props.series), props.accumulationType, props.startDate, props.endDate]
  );

  // Get the structured and formatted data needed to display the accumulation data.
  function getFormattedData(
    startDate: string,
    endDate: string,
    series: VariousTimeDataSeries[],
    accumulationType: AccumulationType
  ): [AccumulationDataSeries[], ApexOptions | null] {
    const [categories, accumulationSeries] = calculateAccumulation(startDate, endDate, accumulationType, series);
    const options = getOptions(accumulationSeries, categories);
    return [accumulationSeries, options];
  }

  // Get the individual intervals in the x-axis as categories and combine the current data points into these categories.
  function calculateAccumulation(
    startDate: string,
    endDate: string,
    accumulationType: AccumulationType,
    series: VariousTimeDataSeries[]
  ): [string[], AccumulationDataSeries[]] {
    const categories: string[] = [];
    const startDateObject = isoToDate(`${startDate}T00:00:00`);
    const dayAfterEndDateObject = isoToDate(`${endDate}T00:00:00`);
    dayAfterEndDateObject.setDate(dayAfterEndDateObject.getDate() + 1);
    const accumulationSeries: AccumulationDataSeries[] = createAccumulationSeries(series);
    // Only calculate accumulation if the start and end dates are valid date objects.
    if (
      startDateObject < dayAfterEndDateObject &&
      startDateObject instanceof Date &&
      dayAfterEndDateObject instanceof Date
    ) {
      const currentDateObject = new Date(`${startDate}T00:00:00`);
      const nextDateObject = new Date(`${startDate}T00:00:00`);
      switch (accumulationType) {
        case "HOURLY_ACCUMULATION": {
          while (nextDateObject.getTime() < dayAfterEndDateObject.getTime()) {
            currentDateObject.setTime(nextDateObject.getTime());
            nextDateObject.setTime(nextDateObject.getTime() + MS_PER_HOUR);
            categories.push(String(formatDateObjectHourlyLocal(currentDateObject)));
            addDataToAccumulationSeries(series, accumulationSeries, currentDateObject, nextDateObject, startDateObject);
          }
          break;
        }
        case "DAILY_ACCUMULATION": {
          while (nextDateObject.getTime() < dayAfterEndDateObject.getTime()) {
            currentDateObject.setTime(nextDateObject.getTime());
            nextDateObject.setDate(nextDateObject.getDate() + 1);
            categories.push(String(formatDateObjectDailyLocal(currentDateObject)));
            addDataToAccumulationSeries(series, accumulationSeries, currentDateObject, nextDateObject, startDateObject);
          }
          break;
        }
        case "WEEKLY_ACCUMULATION": {
          // We break out the response into data chunked by the calendar weeks while keeping within start/end date bounds.
          while (nextDateObject.getTime() < dayAfterEndDateObject.getTime()) {
            currentDateObject.setTime(nextDateObject.getTime());
            nextDateObject.setTime(getStartOfNextWeek(nextDateObject, dayAfterEndDateObject).getTime());
            // We need to get the date before the next date to show on the x-axis label since the next date is actually
            // the start of the next week.
            const finalDayObject = new Date();
            finalDayObject.setTime(nextDateObject.getTime());
            finalDayObject.setDate(finalDayObject.getDate() - 1);
            categories.push(
              `${formatDateObjectDailyLocal(currentDateObject)} - ${formatDateObjectDailyLocal(finalDayObject)}`
            );
            addDataToAccumulationSeries(series, accumulationSeries, currentDateObject, nextDateObject, startDateObject);
          }
          break;
        }
        case "MONTHLY_ACCUMULATION": {
          // We break out the response into data chunked by the calendar months while keeping within start/end date bounds.
          while (nextDateObject.getTime() < dayAfterEndDateObject.getTime()) {
            currentDateObject.setTime(nextDateObject.getTime());
            nextDateObject.setTime(getStartOfNextMonth(nextDateObject, dayAfterEndDateObject).getTime());
            // We need to get the date before the next date to show on the x-axis label since the next date is actually
            // the start of the next month.
            const finalDayObject = new Date();
            finalDayObject.setTime(nextDateObject.getTime());
            finalDayObject.setDate(finalDayObject.getDate() - 1);
            categories.push(
              `${formatDateObjectDailyLocal(currentDateObject)} - ${formatDateObjectDailyLocal(finalDayObject)}`
            );
            addDataToAccumulationSeries(series, accumulationSeries, currentDateObject, nextDateObject, startDateObject);
          }
          break;
        }
        default:
          break;
      }
    }
    return [categories, accumulationSeries];
  }

  // Given a date, get the next day that is the first day of a week.
  function getStartOfNextWeek(currentDateObject: Date, dayAfterEndDateObject: Date): Date {
    // The standard JS getDay method returns the day of the week represented by an integer (0 to 6).
    const sunday = 0;
    const startOfNextWeek = new Date();
    startOfNextWeek.setTime(currentDateObject.getTime());
    startOfNextWeek.setDate(startOfNextWeek.getDate() + 1);
    // We increment the next day until we either find a Sunday or reach the last date in our date range.
    while (startOfNextWeek.getDay() !== sunday && startOfNextWeek.getTime() < dayAfterEndDateObject.getTime()) {
      startOfNextWeek.setDate(startOfNextWeek.getDate() + 1);
    }
    return startOfNextWeek;
  }

  // Given a date, get the next day that is the first day of a month.
  function getStartOfNextMonth(currentDateObject: Date, dayAfterEndDateObject: Date): Date {
    const currentMonth = currentDateObject.getMonth();
    const startOfNextMonth = new Date();
    startOfNextMonth.setTime(currentDateObject.getTime());
    startOfNextMonth.setDate(startOfNextMonth.getDate() + 1);
    // We increment the next day until we either find the first day of the next month
    // or we reach the last date in our date range.
    while (
      startOfNextMonth.getMonth() === currentMonth &&
      startOfNextMonth.getTime() < dayAfterEndDateObject.getTime()
    ) {
      startOfNextMonth.setDate(startOfNextMonth.getDate() + 1);
    }
    return startOfNextMonth;
  }

  // Given a various time data series, convert it to a column data series without any data.
  function createAccumulationSeries(series: VariousTimeDataSeries[]): AccumulationDataSeries[] {
    const accumulationSeries: AccumulationDataSeries[] = [];
    series.forEach((individualSeries) => {
      accumulationSeries.push({
        name: individualSeries.name,
        unitName: individualSeries.unitName,
        unitShortName: individualSeries.unitShortName,
        data: [],
      });
    });
    return accumulationSeries;
  }

  // Attempt to populate each data series within a given start and end date with accumulated data.
  function addDataToAccumulationSeries(
    series: VariousTimeDataSeries[],
    accumulationSeries: AccumulationDataSeries[],
    currentDateObject: Date,
    nextStartDateObject: Date,
    startDateObject: Date
  ): void {
    series.forEach((individualSeries, i) => {
      // We will get the oldest and newest values from our time data series that match the time frame.
      let newestTimestamp: number | null = null;
      let oldestTimestamp: number | null = null;
      let newestValue: number | null = null;
      let oldestValue: number | null = null;
      individualSeries.data.forEach((dataPoint) => {
        if (dataPoint.length === 2) {
          const dataPointTimestamp = dataPoint[0];
          const dataPointValue = parseFloat(dataPoint[1] as string);
          const currentDateTimestamp = currentDateObject.getTime();
          const nextStartDateTimestamp = nextStartDateObject.getTime();
          if (dataPointTimestamp >= currentDateTimestamp && dataPointTimestamp < nextStartDateTimestamp) {
            if (newestTimestamp === null || dataPointTimestamp > newestTimestamp) {
              newestTimestamp = dataPointTimestamp;
              newestValue = dataPointValue;
            }
            if (oldestTimestamp === null || dataPointTimestamp < oldestTimestamp) {
              oldestTimestamp = dataPointTimestamp;
              oldestValue = dataPointValue;
            }
          }
        }
      });

      let accumulatedValue: number;
      if (oldestTimestamp === null || oldestValue === null || newestTimestamp === null || newestValue === null) {
        // If we didn't find any data in our current time slice, then we assume no new accumulation.
        accumulatedValue = 0;
      } else {
        // So that we don't lose value changes between time slices, we need to get the difference between the oldest value
        // that we have found and the the previous reported value in the current date range that we have selected.
        // Example: [0 to 5][10 to 10]. If you just check the difference in the time slices you will have [5] and [0]
        // for a total of 5 accumulated, but we should expect to see total of 10 accumulated if we also check between time slices.
        let previousTimestamp: number | null = null;
        let previousValue: number = oldestValue;
        individualSeries.data.forEach((dataPoint) => {
          if (dataPoint.length === 2) {
            const dataPointTimestamp = dataPoint[0];
            const dataPointValue = parseFloat(dataPoint[1] as string);
            if (
              oldestTimestamp !== null &&
              dataPointTimestamp < oldestTimestamp &&
              dataPointTimestamp >= startDateObject.getTime() &&
              (previousTimestamp === null || dataPointTimestamp > previousTimestamp)
            ) {
              previousTimestamp = dataPointTimestamp;
              previousValue = dataPointValue;
            }
          }
        });
        accumulatedValue = newestValue - previousValue;
      }

      // We will use the matching indexes of the accumulation series to populate the accumulated data.
      if (accumulationSeries.length > i) {
        accumulationSeries[i].data.push(accumulatedValue);
      }
    });
  }

  // Get the options needed to configure the column chart.
  function getOptions(series: AccumulationDataSeries[], categories: string[]): ApexOptions | null {
    const yAxes: ApexYAxis[] = [];
    series.forEach((singleSeries, i) => {
      const showColoredAxis = i < defaultColors.length && series.length > 1;
      yAxes.push({
        seriesName: singleSeries.name,
        opposite: i % 2 !== 0,
        max: verticalBorderMax(singleSeries),
        min: verticalBorderMin(singleSeries),
        labels: {
          formatter: (value) => {
            return `${
              typeof value === "string" ? parseFloat(parseFloat(value).toFixed(4)) : parseFloat(value.toFixed(4))
            } ${singleSeries.unitShortName === null ? "" : singleSeries.unitShortName}`;
          },
          style: {
            colors: showColoredAxis ? defaultColors[i] : "#000000",
          },
        },
        axisBorder: {
          show: showColoredAxis,
          color: showColoredAxis ? defaultColors[i] : "#000000",
        },
      });
    });

    // Set general options.
    if (series !== null && series.length > 0) {
      return {
        chart: {
          height: 500,
          type: "bar",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        grid: {
          row: {
            colors: ["var(--chart-background)", "var(--chart-background-alternative)"],
            opacity: 1,
          },
          padding: {
            left: 5,
            right: 0,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: categories,
        },
        yaxis: yAxes,
        responsive: [
          {
            breakpoint: BREAKPOINT_SMALL,
            options: {
              yaxis: {
                show: false,
                labels: {
                  offsetX: 0,
                },
              },
              grid: {
                row: {
                  colors: ["var(--chart-background)", "var(--chart-background-alternative)"],
                  opacity: 1,
                },
                padding: {
                  left: -100,
                  right: -100,
                },
              },
            },
          },
        ],
      };
    } else {
      return null;
    }
  }

  // Calculate max y-axis border value.
  function verticalBorderMax(series: AccumulationDataSeries): number {
    let maxValue: number | null = null;
    // Find the max value.
    series.data.forEach((dataPoint) => {
      let value = dataPoint;
      if (typeof value === "string") {
        value = parseFloat(value as string);
      }
      if (maxValue === null || value > maxValue) {
        maxValue = value;
      }
    });

    // Add a buffer to the max value.
    if (maxValue === null) {
      maxValue = 0;
    } else if (maxValue >= -0.00000001 && maxValue < 0.00000001) {
      maxValue = 0.25;
    } else {
      maxValue = parseFloat(((maxValue as number) + Math.abs(maxValue) * 0.25).toFixed(4));
    }

    return maxValue;
  }

  // Calculate min y-axis border value.
  function verticalBorderMin(series: AccumulationDataSeries): number {
    let minValue: number | null = null;
    // Find the min value.
    series.data.forEach((dataPoint) => {
      let value = dataPoint;
      if (typeof value === "string") {
        value = parseFloat(value as string);
      }
      if (minValue === null || value < minValue) {
        minValue = value;
      }
    });

    if (minValue === null || minValue >= 0) {
      // If our minValue is non-negative, then just assume the minimum for accumulation is 0.
      minValue = 0;
    } else if (minValue <= 0.00000001 && minValue > -0.00000001) {
      minValue = -0.25;
    } else {
      const offset = parseFloat((minValue - Math.abs(minValue) * 0.25).toFixed(4));
      if (minValue > 0 && offset < 0) {
        minValue = 0;
      } else {
        minValue = offset;
      }
    }

    return minValue;
  }

  return options ? <Chart options={options} series={series} type="bar" height={500} /> : null;
}

AccumulationChart.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  series: PropTypes.array.isRequired,
  accumulationType: PropTypes.string.isRequired,
};

interface Props {
  startDate: string;
  endDate: string;
  series: VariousTimeDataSeries[];
  accumulationType: AccumulationType;
}

interface VariousTimeDataSeries {
  name: string;
  unitName: string;
  unitShortName: string;
  data: [number, number | string][];
  dataMarkers?: DataMarker[];
}

interface DataMarker {
  seriesIndex: number;
  dataPointIndex: number;
}

interface AccumulationDataSeries {
  name: string;
  unitName: string;
  unitShortName: string;
  data: number[];
}

type AccumulationType = "HOURLY_ACCUMULATION" | "DAILY_ACCUMULATION" | "WEEKLY_ACCUMULATION" | "MONTHLY_ACCUMULATION";
