// --------------------------------------------------------------
// Created On: 2023-03-14
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import scheduleTimeConversion from "./scheduleTimeConversion";

// Convert schedule settings from UTC to local time.
export default function scheduleFromUtcToLocal(scheduleSettings: ReportSchedule[]): ReportSchedule[] | null {
  return scheduleTimeConversion(scheduleSettings, true);
}

interface ReportSchedule {
  reportScheduleId: number;
  type: string;
  value: string;
}
