// --------------------------------------------------------------
// Created On: 2021-11-03
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import { useNavigate } from "react-router-dom";
import { RPM_COMPANY } from "../../../constants/miscellaneous";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import { setCurrentUser } from "../../../redux/actions";
import styles from "./CompanyOptions.module.scss";

// Company dropdown for returning to RPM admin dashboard.
export default function CompanyOptions(): Component {
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Exit the current company and return to the RPM admin dashboard.
  function exitCompany(): void {
    dispatch(
      setCurrentUser(
        currentUser.userId,
        currentUser.userName,
        currentUser.emailAddress,
        0,
        RPM_COMPANY,
        false,
        currentUser.permissions,
        false
      )
    );
    navigate("/");
  }

  return (
    <div className={`${styles.body} dropdown me-3`}>
      {/* Button that displays the current company. */}
      <button
        className={`${styles.dropdownContainer} btn btn-primary dropdown-toggle py-0`}
        type="button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div className="d-flex align-items-center">
          <div className={`${styles.companyNameContainer} mx-3`}>
            <div className={`${styles.companyName} d-none d-xxl-inline`}>{currentUser.companyName}</div>
            <i className={`${styles.companyIcon} fa fa-fw fa-rocket d-inline d-xxl-none`} />
          </div>
        </div>
      </button>

      {/* Dropdown menu of options. */}
      <div className="dropdown-menu dropdown-menu-end">
        <button className={`${styles.option} dropdown-item`} onClick={() => exitCompany()}>
          Exit &apos;{currentUser.companyName}&apos;
        </button>
      </div>
    </div>
  );
}
