// --------------------------------------------------------------
// Created On: 2021-05-14
// Author: Zachary Thomas
//
// Last Modified: 2025-01-07
// Modified By: Dimitra Weinstein
//
// Copyright 2024 - 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect, Fragment } from "react";
import { Route, Routes, Navigate, useLocation, useNavigate } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import NavbarChild from "./components/NavbarChild/NavbarChild";
import NavigationDrawer from "./components/NavigationDrawer/NavigationDrawer";
import Footer from "./components/Footer/Footer";
import DashboardUserPage from "./pages/DashboardUserPage/DashboardUserPage";
import DashboardAdminPage from "./pages/DashboardAdminPage/DashboardAdminPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import AlertPage from "./pages/AlertPage/AlertPage";
import AssetDataPage from "./pages/AssetDataPage/AssetDataPage";
import ManageUsersPage from "./pages/ManageUsersPage/ManageUsersPage";
import ManageAssetsPage from "./pages/ManageAssetsPage/ManageAssetsPage";
import ManageRentalAssetsPage from "./pages/ManageRentalAssetsPage/ManageRentalAssetsPage";
import ManageReportsPage from "./pages/ManageReportsPage/ManageReportsPage";
import ManageViewsPage from "./pages/ManageViewsPage/ManageViewsPage";
import ManageRolesPage from "./pages/ManageRolesPage/ManageRolesPage";
import ManageAlertThresholdsPage from "./pages/ManageAlertThresholdsPage/ManageAlertThresholdsPage";
import ManageTemplatesPage from "./pages/ManageTemplatesPage/ManageTemplatesPage";
import ReviewRequestPage from "./pages/ReviewRequestPage/ReviewRequestPage";
import ReviewInvitePage from "./pages/ReviewInvitePage/ReviewInvitePage";
import CreateAccountPage from "./pages/CreateAccountPage/CreateAccountPage";
import ChangeProfilePage from "./pages/ChangeProfilePage/ChangeProfilePage";
import ChangePasswordPage from "./pages/ChangePasswordPage/ChangePasswordPage";
import ChangeTemporaryPasswordPage from "./pages/ChangeTemporaryPasswordPage/ChangeTemporaryPasswordPage";
import ManageTransferAssetsPage from "./pages/ManageTransferAssetsPage/ManageTransferAssetsPage";
import DeviceSubscriptionPage from "./pages/DeviceSubscriptionPage/DeviceSubscriptionPage";
import DeviceStatusPage from "./pages/DeviceStatusPage/DeviceStatusPage";
import DeviceAttributesPage from "./pages/DeviceAttributesPage/DeviceAttributesPage";
import AttributeInformationPage from "./pages/AttributeInformationPage/AttributeInformationPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage/ForgotPasswordPage";
import ScanAssetPage from "./pages/ScanAssetPage/ScanAssetPage";
import SearchPage from "./pages/SearchPage/SearchPage";
import MobilePolicyConfirmationPage from "./pages/MobilePolicyConfirmationPage/MobilePolicyConfirmationPage";
import AssetDocumentsPage from "./pages/AssetDocumentsPage/AssetDocumentsPage";
import AssetAttributeGraphPage from "./pages/AssetAttributeGraphPage/AssetAttributeGraphPage";
import AssetRemoteOperationsPage from "./pages/AssetRemoteOperationsPage/AssetRemoteOperationsPage";
import Error404Page from "./pages/Error404Page/Error404Page";
import Error500Page from "./pages/Error500Page/Error500Page";
import userHasPermission from "./utilities/userHasPermission";
import AssetMaintenanceLogsPage from "./pages/AssetMaintenanceLogsPage/AssetMaintenanceLogsPage";
import AssetLocationHistoryPage from "./pages/AssetLocationHistoryPage/AssetLocationHistoryPage";
import AssetAlertLogsPage from "./pages/AssetAlertLogsPage/AssetAlertLogsPage";
import AssetControllerPage from "./pages/AssetControllerPage/AssetControllerPage";
import AdminDeviceAlertPage from "./pages/AdminDeviceAlertPage/AdminDeviceAlertPage";
import PolicyPage from "./pages/PolicyPage/PolicyPage";
import MobileAppLandingPage from "./pages/MobileAppLandingPage/MobileAppLandingPage";
import MobileAppLoadingPage from "./pages/MobileAppLoadingPage/MobileAppLoadingPage";
import SuperAdminReportPage from "./pages/SuperAdminReportPage/SuperAdminReportPage";
import ManageCompaniesPage from "./pages/ManageCompaniesPage/ManageCompaniesPage";
import { useSelector } from "react-redux";
import { getAuthorizerToken, getCurrentUser } from "./redux/selectors";
import { RPM_COMPANY, PUBLIC_PAGES } from "./constants/miscellaneous";
import {
  ALIAS_ATTRIBUTES_PERMISSION,
  CREATE_ASSETS_PERMISSION,
  CREATE_ASSET_GROUPS_PERMISSION,
  CREATE_ASSET_RENTALS_PERMISSION,
  CREATE_REPORTS_PERMISSION,
  CREATE_ROLES_PERMISSION,
  CREATE_USER_GROUPS_PERMISSION,
  DELETE_ASSETS_PERMISSION,
  DELETE_ASSET_GROUPS_PERMISSION,
  REVOKE_ASSET_RENTALS_PERMISSION,
  RETURN_ASSET_RENTALS_PERMISSION,
  DELETE_REPORTS_PERMISSION,
  DELETE_ROLES_PERMISSION,
  DELETE_USERS_PERMISSION,
  DELETE_USER_GROUPS_PERMISSION,
  INVITE_USERS_PERMISSION,
  UPDATE_ASSETS_PERMISSION,
  UPDATE_ASSET_GROUPS_PERMISSION,
  UPDATE_ASSET_RENTALS_PERMISSION,
  UPDATE_REPORTS_PERMISSION,
  UPDATE_ROLES_PERMISSION,
  UPDATE_USERS_PERMISSION,
  UPDATE_USER_GROUPS_PERMISSION,
  VIEW_SUBSCRIPTIONS_PERMISSION,
  VIEW_DEVICE_STATUSES_PERMISSION,
  MANAGE_ALERT_THRESHOLDS_PERMISSION,
  ACCEPT_ASSET_RENTALS_PERMISSION,
  DECLINE_ASSET_RENTALS_PERMISSION,
  TRANSFER_ASSETS_PERMISSION,
  ACCEPT_ASSET_TRANSFERS_PERMISSION,
  DECLINE_ASSET_TRANSFERS_PERMISSION,
  CREATE_TEMPLATES_PERMISSION,
  UPDATE_TEMPLATES_PERMISSION,
  DELETE_TEMPLATES_PERMISSION,
} from "./constants/permissions";
import "./App.scss";

// Handles page routing.
export default function App(): Component {
  const [inCompany, setInCompany] = useState<boolean>(true);
  const [showNavigationDrawer, setShowNavigationDrawer] = useState<boolean>(false);
  const [redirectUrl, setRedirectUrl] = useState<string>("");
  const currentPage = `/${useLocation().pathname.split("/")[1]}`;
  const authorizationToken = useSelector(getAuthorizerToken);
  const currentUser = useSelector(getCurrentUser);
  const location = useLocation();
  const navigate = useNavigate();

  // If the user is not logged in, and they are not on a public page, bring them to the login page.
  useEffect(() => {
    if (!PUBLIC_PAGES.includes(currentPage)) {
      if (authorizationToken.length === 0) {
        if (location.pathname !== "/" && location.pathname !== "/index.html") {
          setRedirectUrl(location.pathname);
        }
        navigate("/login");
      }
    }
  }, []);

  // Keep track of the status of the current user for routing purposes.
  useEffect(() => {
    setInCompany(currentUser.companyName !== RPM_COMPANY);
  }, [JSON.stringify(currentUser)]);

  // Based on the users permissions and the base route,
  // decide where to redirect when clicking the navbar dropdown without making a selection.
  function permissionBasedNavigate(baseRoute: "/manage" | "/monitoring-devices" | "/data-processing"): string {
    // Routes for 'monitoring-devices' base route.
    if (baseRoute === "/monitoring-devices") {
      if (userHasPermission([[VIEW_SUBSCRIPTIONS_PERMISSION]])) {
        return "/monitoring-devices/subscriptions";
      }

      if (userHasPermission([[VIEW_DEVICE_STATUSES_PERMISSION]])) {
        return "/monitoring-devices/statuses";
      }

      return "/monitoring-devices";
    }

    if (baseRoute === "/data-processing") {
      if (userHasPermission([[ALIAS_ATTRIBUTES_PERMISSION]])) {
        return "/data-processing/attributes";
      }

      return "/data-processing/attribute-info";
    }

    // Routes for 'manage' base route.
    if (baseRoute === "/manage") {
      if (
        userHasPermission([
          [CREATE_ASSETS_PERMISSION],
          [UPDATE_ASSETS_PERMISSION],
          [DELETE_ASSETS_PERMISSION],
          [CREATE_ASSET_GROUPS_PERMISSION],
          [UPDATE_ASSET_GROUPS_PERMISSION],
          [DELETE_ASSET_GROUPS_PERMISSION],
        ])
      ) {
        return "/manage/assets";
      }

      if (
        userHasPermission([[CREATE_TEMPLATES_PERMISSION], [UPDATE_TEMPLATES_PERMISSION], [DELETE_TEMPLATES_PERMISSION]])
      ) {
        return "/manage/templates";
      }

      if (
        userHasPermission([
          [INVITE_USERS_PERMISSION],
          [UPDATE_USERS_PERMISSION],
          [DELETE_USERS_PERMISSION],
          [CREATE_USER_GROUPS_PERMISSION],
          [UPDATE_USER_GROUPS_PERMISSION],
          [DELETE_USER_GROUPS_PERMISSION],
        ])
      ) {
        return "/manage/users";
      }

      if (
        userHasPermission([
          [CREATE_ASSET_RENTALS_PERMISSION],
          [UPDATE_ASSET_RENTALS_PERMISSION],
          [REVOKE_ASSET_RENTALS_PERMISSION],
          [RETURN_ASSET_RENTALS_PERMISSION],
          [ACCEPT_ASSET_RENTALS_PERMISSION],
          [DECLINE_ASSET_RENTALS_PERMISSION],
        ])
      ) {
        return "/manage/rentals";
      }

      if (
        userHasPermission([
          [TRANSFER_ASSETS_PERMISSION],
          [ACCEPT_ASSET_TRANSFERS_PERMISSION],
          [DECLINE_ASSET_TRANSFERS_PERMISSION],
        ])
      ) {
        return "/manage/transfers";
      }

      if (userHasPermission([[CREATE_REPORTS_PERMISSION], [UPDATE_REPORTS_PERMISSION], [DELETE_REPORTS_PERMISSION]])) {
        return "/manage/reports";
      }

      if (userHasPermission([[CREATE_ROLES_PERMISSION], [UPDATE_ROLES_PERMISSION], [DELETE_ROLES_PERMISSION]])) {
        return "/manage/roles";
      }

      return "/manage/views";
    }

    return baseRoute;
  }

  return (
    <Fragment>
      <Navbar currentPage={currentPage} onOpenNavigationDrawer={() => setShowNavigationDrawer(true)} />

      <NavbarChild currentPage={currentPage} fullPath={location.pathname} />

      <NavigationDrawer
        currentPage={currentPage}
        showNavigationDrawer={showNavigationDrawer}
        onCloseNavigationDrawer={() => setShowNavigationDrawer(false)}
      />

      <main>
        <div className="app container-fluid px-0">
          <Routes>
            {inCompany ? (
              <Fragment>
                {/* Dashboard. */}
                {currentUser.isPackager ? (
                  <Fragment>
                    <Route path="/" element={<Navigate to="/manage" replace={true} />} />
                    <Route path="/dashboard/companies" element={<Navigate to="/manage/assets" replace={true} />} />
                  </Fragment>
                ) : (
                  <Fragment>
                    <Route path="/" element={<Navigate to="/dashboard" replace={true} />} />
                    <Route path="/dashboard" element={<Navigate to="/dashboard/map" replace={true} />} />
                    <Route path="/dashboard/companies" element={<Navigate to="/dashboard/map" replace={true} />} />
                    <Route path="/dashboard/*" element={<DashboardUserPage />} />
                  </Fragment>
                )}

                {/* Management. */}
                <Route path="/manage/views" element={<ManageViewsPage />} />

                <Route path="/manage" element={<Navigate to={permissionBasedNavigate("/manage")} replace={true} />} />
                {userHasPermission([
                  [CREATE_ASSETS_PERMISSION],
                  [UPDATE_ASSETS_PERMISSION],
                  [DELETE_ASSETS_PERMISSION],
                  [CREATE_ASSET_GROUPS_PERMISSION],
                  [UPDATE_ASSET_GROUPS_PERMISSION],
                  [DELETE_ASSET_GROUPS_PERMISSION],
                ]) && <Route path="/manage/assets" element={<ManageAssetsPage />} />}

                {userHasPermission([
                  [CREATE_TEMPLATES_PERMISSION],
                  [UPDATE_TEMPLATES_PERMISSION],
                  [DELETE_TEMPLATES_PERMISSION],
                ]) && <Route path="/manage/templates" element={<ManageTemplatesPage />} />}

                {userHasPermission([
                  [CREATE_ASSET_RENTALS_PERMISSION],
                  [UPDATE_ASSET_RENTALS_PERMISSION],
                  [REVOKE_ASSET_RENTALS_PERMISSION],
                  [RETURN_ASSET_RENTALS_PERMISSION],
                  [ACCEPT_ASSET_RENTALS_PERMISSION],
                  [DECLINE_ASSET_RENTALS_PERMISSION],
                ]) && <Route path="/manage/rentals" element={<ManageRentalAssetsPage />} />}

                {userHasPermission([
                  [TRANSFER_ASSETS_PERMISSION],
                  [ACCEPT_ASSET_TRANSFERS_PERMISSION],
                  [DECLINE_ASSET_TRANSFERS_PERMISSION],
                ]) && <Route path="/manage/transfers" element={<ManageTransferAssetsPage />} />}

                {userHasPermission([
                  [INVITE_USERS_PERMISSION],
                  [UPDATE_USERS_PERMISSION],
                  [DELETE_USERS_PERMISSION],
                  [CREATE_USER_GROUPS_PERMISSION],
                  [UPDATE_USER_GROUPS_PERMISSION],
                  [DELETE_USER_GROUPS_PERMISSION],
                ]) && <Route path="/manage/users" element={<ManageUsersPage />} />}

                {userHasPermission([
                  [CREATE_REPORTS_PERMISSION],
                  [UPDATE_REPORTS_PERMISSION],
                  [DELETE_REPORTS_PERMISSION],
                ]) && <Route path="/manage/reports" element={<ManageReportsPage />} />}

                {userHasPermission([
                  [CREATE_ROLES_PERMISSION],
                  [UPDATE_ROLES_PERMISSION],
                  [DELETE_ROLES_PERMISSION],
                ]) && <Route path="/manage/roles" element={<ManageRolesPage />} />}

                {/* Data Processing. */}
                <Route
                  path="/data-processing"
                  element={<Navigate to={permissionBasedNavigate("/data-processing")} replace={true} />}
                />

                {/* Monitoring Devices. */}
                <Route
                  path="/monitoring-devices"
                  element={<Navigate to={permissionBasedNavigate("/monitoring-devices")} replace={true} />}
                />

                {userHasPermission([[VIEW_SUBSCRIPTIONS_PERMISSION]]) && (
                  <Route path="/monitoring-devices/subscriptions" element={<DeviceSubscriptionPage />} />
                )}

                {userHasPermission([[VIEW_DEVICE_STATUSES_PERMISSION]]) && (
                  <Route path="/monitoring-devices/statuses" element={<DeviceStatusPage />} />
                )}

                {userHasPermission([[ALIAS_ATTRIBUTES_PERMISSION]]) && (
                  <Route path="/data-processing/attributes" element={<DeviceAttributesPage />} />
                )}

                <Route path="/data-processing/attribute-info" element={<AttributeInformationPage />} />

                {userHasPermission([[MANAGE_ALERT_THRESHOLDS_PERMISSION]]) && (
                  <Route path="/data-processing/alert-thresholds" element={<ManageAlertThresholdsPage />} />
                )}

                {/* Assets. */}
                <Route path="/asset/:assetId" element={<Navigate to="/asset/:assetId/data" replace={true} />} />
                <Route path="/asset/:assetId/map" element={<AssetLocationHistoryPage />} />
                <Route path="/asset/:assetId/documents" element={<AssetDocumentsPage />} />
                <Route path="/asset/:assetId/logs" element={<AssetMaintenanceLogsPage />} />
                <Route path="/asset/:assetId/alerts" element={<AssetAlertLogsPage />} />
                <Route path="/asset/:assetId/graph" element={<AssetAttributeGraphPage />} />
                <Route path="/asset/:assetId/operation" element={<AssetRemoteOperationsPage />} />
                <Route path="/asset/:assetId/controller" element={<AssetControllerPage />} />
                <Route path="/asset/:assetId/data" element={<AssetDataPage />} />
                <Route path="/scan-asset/:partNumber/:serialNumber" element={<ScanAssetPage />} />
                <Route path="/scan-asset/:partNumber/:serialNumber/:deviceIdentifier" element={<ScanAssetPage />} />

                {/* Review user request to join company. */}
                {userHasPermission([[INVITE_USERS_PERMISSION]]) && (
                  <Route path="/review-account/:name/:emailAddress/:companyCode" element={<ReviewRequestPage />} />
                )}
              </Fragment>
            ) : (
              <Fragment>
                {/* Dashboard. */}
                <Route path="/" element={<Navigate to="/dashboard" replace={true} />} />
                <Route path="/dashboard" element={<Navigate to="/dashboard/companies" replace={true} />} />
                <Route path="dashboard/map" element={<Navigate to="/dashboard/companies" replace={true} />} />
                <Route path="/dashboard/companies" element={<DashboardAdminPage />} />
                <Route path="/assets" element={<Navigate to="/assets/chatty" replace={true} />} />
                <Route path="/assets/chatty" element={<AdminDeviceAlertPage />} />
                <Route path="/reports" element={<SuperAdminReportPage />} />

                {/* Company Management. */}
                <Route path="/manage" element={<Navigate to={"/manage/companies"} />} />
                <Route path="/manage/companies" element={<ManageCompaniesPage />} />
              </Fragment>
            )}

            {/* Alerts. */}
            <Route path="/alert" element={<Navigate to="/alert/overview" replace={true} />} />
            <Route path="/alert/overview" element={<AlertPage />} />

            {/* Account settings. */}
            <Route path="/account" element={<Navigate to="/account/user-info" replace={true} />} />
            <Route path="/account/user-info" element={<ChangeProfilePage />} />
            <Route path="/account/password" element={<ChangePasswordPage />} />

            {/* Miscellaneous pages. */}
            <Route
              path="/login"
              element={
                <LoginPage
                  redirectUrl={redirectUrl}
                  onRedirect={() => setRedirectUrl("")}
                  isForgotPasswordRedirect={false}
                  authorizationError={false}
                  policyError={false}
                />
              }
            />
            <Route
              path="/login/auth"
              element={
                <LoginPage
                  redirectUrl={redirectUrl}
                  onRedirect={() => setRedirectUrl("")}
                  isForgotPasswordRedirect={false}
                  authorizationError={true}
                  policyError={false}
                />
              }
            />
            <Route
              path="/login/policy-rejected"
              element={
                <LoginPage
                  redirectUrl={redirectUrl}
                  onRedirect={() => setRedirectUrl("")}
                  isForgotPasswordRedirect={false}
                  authorizationError={false}
                  policyError={true}
                />
              }
            />
            <Route
              path="/login/temporary-password"
              element={
                <LoginPage
                  redirectUrl={redirectUrl}
                  onRedirect={() => setRedirectUrl("")}
                  isForgotPasswordRedirect={true}
                  authorizationError={false}
                  policyError={false}
                />
              }
            />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route
              path="/change-temp-password"
              element={<ChangeTemporaryPasswordPage redirectUrl={redirectUrl} onRedirect={() => setRedirectUrl("")} />}
            />
            <Route path="/accept-invite/:emailAddress/:companyCode/:companyName" element={<ReviewInvitePage />} />
            <Route path="/register" element={<CreateAccountPage />} />
            <Route path="/search" element={<Navigate to="/search/advanced" replace={true} />} />
            <Route path="/search/:initialSearchQuery" element={<SearchPage />} />
            <Route path="/eula-viewer" element={<PolicyPage policyType="EULA" displayType="pdf" />} />
            <Route
              path="/privacy-policy-viewer"
              element={<PolicyPage policyType="Privacy Policy" displayType="pdf" />}
            />
            <Route path="/eula-text" element={<PolicyPage policyType="EULA" displayType="text" />} />
            <Route
              path="/privacy-policy-text"
              element={<PolicyPage policyType="Privacy Policy" displayType="text" />}
            />
            <Route path="/mobile-app-landing" element={<MobileAppLandingPage />} />
            <Route path="/mobile-app-loading" element={<MobileAppLoadingPage />} />
            <Route path="/mobile-app-eula" element={<MobilePolicyConfirmationPage />} />
            <Route path="/error-500" element={<Error500Page />} />
            <Route path="*" element={<Error404Page />} />
          </Routes>
        </div>
      </main>
      <Footer />
    </Fragment>
  );
}
