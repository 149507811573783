// --------------------------------------------------------------
// Created On: 2021-10-21
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect } from "react";
import {
  API,
  MIN_PASSWORD_LENGTH,
  MAX_PASSWORD_LENGTH,
  VALID_PASSWORD_NUMBER_REGULAR_EXPRESSION,
  VALID_PASSWORD_LOWERCASE_REGULAR_EXPRESSION,
  VALID_PASSWORD_UPPERCASE_REGULAR_EXPRESSION,
  VALID_PASSWORD_SPECIAL_REGULAR_EXPRESSION,
} from "../../constants/miscellaneous";
import PasswordRequirements from "../../components/PasswordRequirements/PasswordRequirements";
import getApiError from "../../utilities/api/getApiError";
import Spinner from "../../components/Spinner/Spinner";
import Card from "../../components/Card/Card";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import Error from "../../components/Error/Error";
import Success from "../../components/Success/Success";
import apiRequest from "../../utilities/api/apiRequest";
import { useSelector } from "react-redux";
import { getCurrentUser, getAccessToken } from "../../redux/selectors";

// Page for changing the current user's password.
export default function ChangePasswordPage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const currentUser = useSelector(getCurrentUser);
  const accessToken = useSelector(getAccessToken);

  // If an input is being edited, remove any old error or success messages.
  useEffect(() => {
    setErrorMessage("");
    setSuccessMessage("");
  }, [currentPassword, newPassword, confirmPassword]);

  // Validate the passwords.
  function passwordIsValid(): boolean {
    if (newPassword.length < MIN_PASSWORD_LENGTH || newPassword.length > MAX_PASSWORD_LENGTH) {
      setSuccessMessage("");
      setErrorMessage(
        `Your new password must be between ${MIN_PASSWORD_LENGTH} and ${MAX_PASSWORD_LENGTH} characters long.`
      );
      return false;
    } else if (!VALID_PASSWORD_NUMBER_REGULAR_EXPRESSION.test(newPassword)) {
      setSuccessMessage("");
      setErrorMessage("Your new password must include at least one number.");
      return false;
    } else if (!VALID_PASSWORD_LOWERCASE_REGULAR_EXPRESSION.test(newPassword)) {
      setSuccessMessage("");
      setErrorMessage("Your new password must include at least one lower case letter.");
      return false;
    } else if (!VALID_PASSWORD_UPPERCASE_REGULAR_EXPRESSION.test(newPassword)) {
      setSuccessMessage("");
      setErrorMessage("Your new password must include at least one upper case letter.");
      return false;
    } else if (!VALID_PASSWORD_SPECIAL_REGULAR_EXPRESSION.test(newPassword)) {
      setSuccessMessage("");
      setErrorMessage("Your new password must include at least one special character.");
      return false;
    } else if (newPassword !== confirmPassword) {
      setSuccessMessage("");
      setErrorMessage("Your new password does not match the confirmation password.");
      return false;
    } else {
      return true;
    }
  }

  // Save the new password.
  async function saveChanges(): Promise<void> {
    if (passwordIsValid()) {
      const requestBody = {
        emailAddress: currentUser.emailAddress,
        currentPassword: currentPassword,
        newPassword: newPassword,
        accessToken: accessToken,
      };

      setLoading(true);
      const [response] = (await apiRequest(`${API}/registration/password`, "PUT", requestBody)) as [
        Response,
        ResponseBody
      ];
      setLoading(false);

      if (response.ok) {
        setErrorMessage("");
        setSuccessMessage("The new password has been saved.");
      } else {
        setSuccessMessage("");
        setErrorMessage(await getApiError(response, "Unable to update password."));
      }
    }
  }

  return (
    <div className="page-change-password p-4 col-12 col-xl-10 col-xxl-8 offset-xl-1 offset-xxl-2">
      <Spinner loading={loading} />

      <Card title="Change Password">
        <div className="mx-4 my-3">
          {/* Password requirements. */}
          <PasswordRequirements />

          {/* Password inputs. */}
          <div className="form-update-account mb-4">
            <label className="mb-3 font-weight-bold">Current Password</label>
            <PasswordInput
              password={currentPassword}
              onChangePassword={(currentPassword) => setCurrentPassword(currentPassword)}
            />
          </div>

          <div className="form-update-account mb-4">
            <label className="mb-3 font-weight-bold">New Password</label>
            <PasswordInput password={newPassword} onChangePassword={(newPassword) => setNewPassword(newPassword)} />
          </div>

          <div className="form-update-account">
            <label className="mb-3 font-weight-bold">Confirm New Password</label>
            <PasswordInput
              password={confirmPassword}
              onChangePassword={(confirmPassword) => setConfirmPassword(confirmPassword)}
            />
          </div>

          <div className="status-messages mt-3">
            <Error message={errorMessage} />
            <Success message={successMessage} />
          </div>

          {/* Hide button if a save was just attempted and nothing has changed since. */}
          {errorMessage.length === 0 && successMessage.length === 0 && (
            <div className="row justify-content-end">
              <div className="col-auto">
                <button className="btn btn-primary mx-auto" type="button" onClick={() => saveChanges()}>
                  Save Password
                </button>
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}

interface ResponseBody {
  message: string;
}
