// --------------------------------------------------------------
// Created On: 2023-07-11
// Author: Zachary Thomas
//
// Last Modified: 2024-12-30
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Google map constants.
export const GOOGLE_MAPS_API_KEY = "AIzaSyDex8EqZOH2aYYumdgvc6139kI5es5s0nI";
export const GOOGLE_MAPS_DEFAULT_ZOOM = 5;
export const GOOGLE_MAPS_MIN_ZOOM = 3;
export const GOOGLE_MAPS_DEFAULT_LAT = 39.5;
export const GOOGLE_MAPS_DEFAULT_LNG = -98.35;
export const GOOGLE_MAPS_HOVER_DISTANCE = 30;
export const GOOGLE_MAPS_BOTTOM_LEFT = 6;
export const GOOGLE_MAPS_HORIZONTAL_BAR = 1;
export const GOOGLE_MAPS_ROAD_MAP = "roadmap";
export const GOOGLE_MAPS_SATELLITE = "satellite";
export const GOOGLE_MAPS_DELAY_TO_CLEANUP_BROKEN_INFO_WINDOWS_MILLISECONDS = 50;
