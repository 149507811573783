// --------------------------------------------------------------
// Created On: 2024-12-10
// Author: Dimitra Weinstein
//
// Last Modified: 2025-01-21
// Modified By: Zachary Thomas
//
// Copyright 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import Card from "../../../components/Card/Card";
import { SUPER_ADMIN_REPORT_TYPES } from "../../../constants/superAdminReportTypes";
import SuperAdminReportDateSelector from "./SuperAdminReportDateSelector/SuperAdminReportDateSelector";
import styles from "./SuperAdminReportPageForm.module.scss";

// Report page form.
export default function SuperAdminReportPageForm(props: Props): Component {
  return (
    <div className="mb-5">
      <Card title="Admin Reports" isLeftAligned={false}>
        <form className="p-3" onSubmit={(e) => e.preventDefault()}>
          {/* Report Type Dropdown select menu. */}
          <div className="row">
            <div className="col">
              <label className="form-label p-2">Type of Report</label>
              <select
                className={`form-select ${!props.reportTypeIsValid ? styles.inputError : ""}`}
                value={props.reportType}
                onChange={(e) => props.onReportTypeChange(e.target.value)}
                aria-label="Report Type Dropdown"
              >
                <option value={""} disabled>
                  Please select a report type...
                </option>
                {SUPER_ADMIN_REPORT_TYPES.map((reportType) => (
                  <option key={reportType.code} value={reportType.code}>
                    {reportType.description}
                  </option>
                ))}
              </select>
              {!props.reportTypeIsValid && (
                <div className="col-12 col-lg-9">
                  <span className={styles.errorTooltip}>{`${props.errorMessage}`}</span>
                </div>
              )}
            </div>
          </div>
          {/* Select Date Range Checkbox Input */}
          <div className="row p-3">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                onClick={() => props.onSelectDateRange()}
              />
              <label>Select Date Range</label>
            </div>
          </div>
          {/* Date Selector */}
          <SuperAdminReportDateSelector
            dateRangeIsChecked={props.dateRangeIsChecked}
            startDate={props.startDate}
            endDate={props.endDate}
            errorMessage={props.errorMessage}
            startDateErrorMessage={props.startDateErrorMessage}
            endDateErrorMessage={props.endDateErrorMessage}
            startDateIsValid={props.startDateIsValid}
            endDateIsValid={props.endDateIsValid}
            dateRangeIsValid={props.dateRangeIsValid}
            onStartDateChange={(startDate) => props.onStartDateChange(startDate)}
            onEndDateChange={(endDate) => props.onEndDateChange(endDate)}
          />
        </form>
      </Card>
    </div>
  );
}

SuperAdminReportPageForm.propTypes = {
  reportType: PropTypes.string.isRequired,
  formattedReportType: PropTypes.string.isRequired,
  reportTypeIsValid: PropTypes.bool.isRequired,
  dateRangeIsChecked: PropTypes.bool.isRequired,
  dateRangeIsValid: PropTypes.bool.isRequired,
  startDateIsValid: PropTypes.bool.isRequired,
  endDateIsValid: PropTypes.bool.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  errorMessage: PropTypes.string,
  startDateErrorMessage: PropTypes.string,
  endDateErrorMessage: PropTypes.string,
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
  onReportTypeChange: PropTypes.func.isRequired,
  onSelectDateRange: PropTypes.func.isRequired,
};

interface Props {
  reportType: string;
  formattedReportType: string;
  reportTypeIsValid: boolean;
  dateRangeIsChecked: boolean;
  dateRangeIsValid: boolean;
  startDateIsValid: boolean;
  endDateIsValid: boolean;
  startDate: string | null;
  endDate: string | null;
  errorMessage: string;
  startDateErrorMessage: string;
  endDateErrorMessage: string;
  onStartDateChange: (startDate: string) => void;
  onEndDateChange: (endDate: string) => void;
  onReportTypeChange: (reportType: string) => void;
  onSelectDateRange: () => void;
}
