// --------------------------------------------------------------
// Created On: 2022-07-28
// Author: Zachary Thomas
//
// Last Modified: 2025-01-09
// Modified By: Zachary Thomas
//
// Copyright 2024 - 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect, Fragment } from "react";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import SaveChangesModal from "../../../components/SaveChangesModal/SaveChangesModal";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import Error from "../../../components/Error/Error";
import Spinner from "../../../components/Spinner/Spinner";
import InlineEllipsisSpinner from "../../../components/InlineEllipsisSpinner/InlineEllipsisSpinner";
import apiRequest from "../../../utilities/api/apiRequest";
import getApiError from "../../../utilities/api/getApiError";
import AssetDetails from "../../../components/AssetDetails/AssetDetails";
import DisplayDiagnosisStatus from "../../../components/DisplayDiagnosisStatus/DisplayDiagnosisStatus";
import { API, RPM_COMPANY } from "../../../constants/miscellaneous";
import { TRANSFER_ASSET_TYPES } from "../../../constants/reducerActions";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import useApi from "../../../hooks/useApi";
import styles from "./OutgoingTransferAssetModal.module.scss";

// Modal for transferring assets to other companies.
export default function OutgoingTransferAssetModal(props: Props): Component {
  const currentUser = useSelector(getCurrentUser);
  const [loading, setLoading] = useState<boolean>(false);
  const [testingConfiguration, setTestingConfiguration] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [showConfirmExit, setShowConfirmExit] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [emailMessage, setEmailMessage] = useState("");
  const [asset, setAsset] = useState<DetailedAsset | null>(null);
  const [companyName, setCompanyName] = useState<string>("");
  const [confirmTransferMessage, setConfirmTransferMessage] = useState<string>("");
  const [diagnosisPerformed, setDiagnosisPerformed] = useState<boolean>(false);
  const [configurationTestCount, setConfigurationTestCount] = useState<number>(0);
  const [deviceShouldReport, setDeviceShouldReport] = useState<boolean>(false);
  const [deviceConnected, setDeviceConnected] = useState<boolean>(false);
  const [controllerShouldReport, setControllerShouldReport] = useState<boolean>(false);
  const [controllerConnected, setControllerConnected] = useState<boolean>(false);
  const [analogSensors, setAnalogSensors] = useState<AnalogSensor[]>([]);
  const confirmationMessage = "TRANSFER ASSET";
  const configurationIsBad =
    (deviceShouldReport && !deviceConnected) ||
    (controllerShouldReport && !controllerConnected) ||
    analogSensors.some((analogSensor) => !analogSensor.connected);

  // If an asset is already selected, search for asset details to display.
  useEffect(() => {
    if (!props.isCreatingNewRecord) {
      void searchAssets(props.asset.name);
    }
  }, [props.isCreatingNewRecord, JSON.stringify(props.asset)]);

  // If we have found an asset diagnose connection information.
  useApi(
    () => {
      if (asset !== null) {
        setTestingConfiguration(true);
        setConfirmTransferMessage("");
        return true;
      } else {
        setTestingConfiguration(false);
        setConfirmTransferMessage("");
        return false;
      }
    },
    {
      method: "POST",
      url: `${API}/company/${currentUser.companyId}/asset/${asset === null ? 0 : asset.assetId}/validate`,
    },
    async (response: Response, responseBody: DiagnosisResponseBody) => {
      if (response.ok && responseBody) {
        setDeviceShouldReport(responseBody.deviceShouldReport);
        setDeviceConnected(responseBody.deviceConnected);
        setControllerShouldReport(responseBody.controllerShouldReport);
        setControllerConnected(responseBody.controllerConnected);
        setAnalogSensors(responseBody.analogSensors);
        setDiagnosisPerformed(true);
        setErrorMessage("");
      } else {
        setErrorMessage("Internal server error. Unable to diagnosis the status of the asset.");
        setDiagnosisPerformed(false);
      }
      setTestingConfiguration(false);
    },
    [JSON.stringify(asset), configurationTestCount]
  );

  // Check that the selected asset is ready to be transferred.
  function transferAssetIsValid(): boolean {
    if (companyName.length === 0) {
      setErrorMessage("You must enter an email address that is associated with an RPM Squared account.");
      return false;
    } else if (asset === null) {
      setErrorMessage("You must select an asset to transfer to a company.");
      return false;
    } else if (confirmTransferMessage.trim() !== confirmationMessage) {
      setErrorMessage(
        `You must type '${confirmationMessage}' to confirm that you understand the impact of an asset transfer.`
      );
      return false;
    } else if (currentUser.isPackager && deviceShouldReport && !deviceConnected) {
      setErrorMessage(
        "Asset doesn't pass pre-transfer check: Wireless connection to monitoring device cannot be established."
      );
      return false;
    } else if (currentUser.isPackager && controllerShouldReport && !controllerConnected) {
      setErrorMessage(
        "Asset doesn't pass pre-transfer check: Either incorrect controller is selected, or controller is not properly connected to monitoring device."
      );
      return false;
    } else if (currentUser.isPackager && analogSensors.some((analogSensor) => !analogSensor.connected)) {
      setErrorMessage("Asset doesn't pass pre-transfer check: Analog sensor is not connected.");
      return false;
    } else if (currentUser.isPackager && !diagnosisPerformed) {
      setErrorMessage("Pre-transfer check could not be performed. Please try again later.");
      return false;
    }

    return true;
  }

  // Transfer an asset to another company.
  async function transferAsset(): Promise<void> {
    if (transferAssetIsValid() && asset !== null) {
      const requestBody = {
        assetId: asset.assetId,
        emailAddress: email,
      };

      setLoading(true);
      const [response, responseBody] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/asset/${requestBody.assetId}/transfer`,
        "POST",
        requestBody
      )) as [Response, PostResponseBody];
      setLoading(false);

      if (response.ok) {
        const transferredAsset = {
          assetId: asset.assetId,
          name: asset.name,
          companyName: responseBody.companyName,
        };

        props.onAction({
          type: TRANSFER_ASSET_TYPES.CREATE_ASSET,
          payload: {
            asset: transferredAsset,
          },
        });

        discardChanges();
      } else {
        setErrorMessage(await getApiError(response, "Unable to transfer asset."));
      }
    }
  }

  // Cancel the transfer of an asset.
  async function cancelAssetTransfer(assetId: number): Promise<void> {
    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/transfer/cancel/${assetId}`,
      "DELETE",
      null
    )) as [Response, DeleteResponseBody];
    setLoading(false);

    if (response.ok) {
      discardChanges();
      props.onAction({
        type: TRANSFER_ASSET_TYPES.DELETE_ASSET,
        payload: {
          assetId: assetId,
        },
      });
    } else {
      setShowConfirmDelete(false);
      setErrorMessage(await getApiError(response, "Unable to cancel asset transfer."));
    }
  }

  // Search for company by email.
  async function searchCompaniesByEmail(email: string): Promise<void> {
    setLoading(true);
    const [response, responseBody] = (await apiRequest(`${API}/userexists/${email}`, "GET", null)) as [
      Response,
      EmailResponseBody
    ];
    setLoading(false);
    setErrorMessage("");
    if (response.ok && responseBody) {
      if (responseBody.companyName === RPM_COMPANY) {
        setCompanyName("");
        setEmailMessage(`Assets cannot be transferred to accounts in the '${RPM_COMPANY}' company.`);
      } else {
        setEmailMessage(
          `This email address is valid. This asset will be transferred to the '${responseBody.companyName}' company`
        );
        setCompanyName(responseBody.companyName);
      }
    } else {
      setCompanyName("");
      setEmailMessage(
        "This email address either is not associated with an RPM Squared account or is not authorized to receive asset transfers."
      );
    }
  }

  // Search for a matching asset.
  async function searchAssets(filter: string): Promise<void> {
    setFilter(filter);
    setErrorMessage("");
    const foundAsset = props.assets.find((asset) => asset.name === filter);

    if (foundAsset === undefined) {
      setAsset(null);
    } else {
      // Get asset information.
      setLoading(true);
      const [response, responseBody] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/asset/${foundAsset.assetId}/configuration`,
        "GET",
        null
      )) as [Response, GetResponseBody];
      setLoading(false);
      setErrorMessage("");
      if (response.ok && responseBody) {
        setAsset(responseBody);
      } else {
        setAsset(null);
      }
    }
    setDiagnosisPerformed(false);
  }

  // Exit modal if no changes have been made. Otherwise prompt user.
  function exitModal(): void {
    if (!props.isCreatingNewRecord || (asset === null && email.length === 0)) {
      discardChanges();
    } else {
      setShowConfirmExit(true);
    }
  }

  // Save changes.
  function saveChanges(): void {
    void transferAsset();
    setShowConfirmExit(false);
    discardChanges();
  }

  // Exit without saving changes.
  function discardChanges(): void {
    setShowConfirmExit(false);
    setShowConfirmDelete(false);
    setAsset(null);
    setConfirmTransferMessage("");
    setCompanyName("");
    setEmail("");
    setErrorMessage("");
    setDeviceShouldReport(false);
    setDeviceConnected(false);
    setControllerShouldReport(false);
    setControllerConnected(false);
    setAnalogSensors([]);
    setDiagnosisPerformed(false);
    props.onClose();
  }

  return (
    <div>
      <Spinner loading={loading} />

      <Modal
        show={true}
        onHide={() => exitModal()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--modal-z-index)" }}
        size="lg"
        centered
        animation
      >
        <ModalHeader>
          <h5 className="font-weight-bold">{props.isCreatingNewRecord ? "Transfer Asset" : "View Asset Transfer"}</h5>
        </ModalHeader>

        <ModalBody>
          {props.isCreatingNewRecord && (
            <div className="mx-2 mb-2">
              <label className="mb-3 font-weight-bold">Enter new owner&apos;s email address</label>
              <input
                data-test="outgoing-transfer-modal-email-input"
                className="form-control mb-1 mx-auto"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => searchCompaniesByEmail(email)}
              />
              <p className={`${companyName.length === 0 ? styles.error : styles.success} mb-4`}>{emailMessage}</p>

              <label className="mb-3 font-weight-bold">Search for asset to transfer</label>
              <input
                data-test="outgoing-transfer-modal-search-input"
                className="form-control mb-4 mx-auto"
                type="text"
                list="datalist-assets"
                autoComplete="off"
                value={filter}
                onChange={(e) => searchAssets(e.target.value)}
              />
              <datalist id="datalist-assets">
                {props.assets.map((asset) => (
                  <option key={asset.assetId} value={asset.name} />
                ))}
              </datalist>
            </div>
          )}

          {!props.isCreatingNewRecord && (
            <p className="text-center mt-3">
              Asset is being transferred to &apos;<b>{props.asset.companyName}</b>&apos;.
              <br />
              This asset is pending approval from the receiving company.
            </p>
          )}

          {asset !== null && (
            <AssetDetails
              name={asset.name}
              nickname={asset.nickname}
              productModel={asset.productModel}
              productIdentifier={asset.productIdentifier}
              productManufacturer={asset.productManufacturer}
              deviceType={asset.deviceType}
              deviceTypeName={asset.deviceTypeName}
              deviceIdentifier={asset.deviceIdentifier}
              controllerModel={asset.controllerName}
              loading={false}
              isRented={false}
            />
          )}

          {testingConfiguration && (
            <div>
              <div className="text-center">
                Checking Asset Configuration <InlineEllipsisSpinner loading={true} />
              </div>
            </div>
          )}

          {asset !== null && !testingConfiguration && diagnosisPerformed && (
            <div className="row justify-content-center px-5">
              <div className="col-12 col-xl-8">
                <DisplayDiagnosisStatus
                  loading={false}
                  deviceShouldReport={deviceShouldReport}
                  deviceConnected={deviceConnected}
                  controllerShouldReport={controllerShouldReport}
                  controllerConnected={controllerConnected}
                  analogSensors={analogSensors}
                />
              </div>
            </div>
          )}

          {asset !== null && !testingConfiguration && configurationIsBad && (
            <div className="text-center">
              <button
                className="btn btn-primary text-center mx-auto my-2"
                data-test="retry-asset-diagnosis-button"
                type="button"
                disabled={testingConfiguration}
                onClick={() => setConfigurationTestCount((prev) => prev + 1)}
              >
                Retry Configuration Check
              </button>
            </div>
          )}

          {/* Only show the disclaimer once all other fields are entered correctly. */}
          {props.isCreatingNewRecord && companyName.length > 0 && asset !== null && (
            <div className="m-3">
              <p>
                Once an asset is transferred to another company, the transfer cannot be reversed. This transfer will
                transfer the ownership of the asset and the ownership of any attached monitoring devices to the selected
                company. To confirm that you understand please type &apos;<b>{confirmationMessage}</b>&apos; into the
                box below this message.
              </p>
              <input
                data-test="confirm-asset-transfer"
                className="form-control"
                type="text"
                value={confirmTransferMessage}
                onChange={(e) => setConfirmTransferMessage(e.target.value.toUpperCase())}
              />
            </div>
          )}

          {errorMessage.length > 0 && (
            <div className="m-3">
              <Error message={errorMessage} />
            </div>
          )}
        </ModalBody>

        <ModalFooter className={styles.footer}>
          {props.isCreatingNewRecord ? (
            <Fragment>
              <button
                data-test="transfer-asset-button"
                className={`${styles.btn} btn btn-primary`}
                type="button"
                onClick={() => transferAsset()}
              >
                Transfer Asset
              </button>

              <button className={`${styles.btn} btn btn-secondary`} type="button" onClick={() => exitModal()}>
                Cancel
              </button>
            </Fragment>
          ) : (
            <Fragment>
              <button
                data-test="cancel-transfer-button"
                className={`${styles.btn} btn btn-danger me-auto" type="button`}
                onClick={() => setShowConfirmDelete(true)}
              >
                Cancel Transfer
              </button>

              <button className={`${styles.btn} btn btn-secondary`} type="button" onClick={() => exitModal()}>
                Close
              </button>
            </Fragment>
          )}
        </ModalFooter>
      </Modal>

      <ConfirmModal
        showModal={showConfirmDelete}
        title="Cancel Asset Transfer"
        content="Are you sure that you want to cancel this asset transfer?"
        yesText="Cancel Transfer"
        noText="Close"
        danger={true}
        onClose={() => setShowConfirmDelete(false)}
        onYes={() => cancelAssetTransfer(props.asset.assetId)}
        onNo={() => setShowConfirmDelete(false)}
      />

      <SaveChangesModal
        showModal={showConfirmExit}
        title="Changes have not been saved!"
        content="Are you sure that you want to exit without saving your changes?"
        onClose={() => setShowConfirmExit(false)}
        onSave={() => saveChanges()}
        onNoSave={() => discardChanges()}
      />
    </div>
  );
}

OutgoingTransferAssetModal.propTypes = {
  isCreatingNewRecord: PropTypes.bool.isRequired,
  asset: PropTypes.object.isRequired,
  assets: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

interface Props {
  isCreatingNewRecord: boolean;
  asset: TransferAsset;
  assets: OwnedAsset[];
  onClose: () => void;
  onAction: (action: Action) => void;
}

interface TransferAsset {
  assetId: number;
  name: string;
  companyName: string;
}

interface OwnedAsset {
  assetId: number;
  name: string;
}

interface DetailedAsset {
  assetId: number;
  name: string;
  nickname: string;
  productModel: string;
  productIdentifier: string;
  productManufacturer: string;
  deviceType: string;
  deviceTypeName: string;
  deviceIdentifier: string;
  controllerModel: string;
  controllerName: string;
}

interface GetResponseBody {
  assetId: number;
  name: string;
  nickname: string;
  productModel: string;
  productIdentifier: string;
  productManufacturer: string;
  deviceType: string;
  deviceTypeName: string;
  deviceIdentifier: string;
  controllerModel: string;
  controllerName: string;
  operateAccessPermission: boolean;
  alertThresholdPermission: boolean;
}

interface DiagnosisResponseBody {
  deviceShouldReport: boolean;
  deviceConnected: boolean;
  controllerShouldReport: boolean;
  controllerConnected: boolean;
  analogSensors: AnalogSensor[];
}

interface PostResponseBody {
  assetTransferId: number;
  companyName: string;
  error?: string;
}

interface AnalogSensor {
  position: number;
  connected: boolean;
}

interface DeleteResponseBody {
  error?: string;
}

interface EmailResponseBody {
  isHidden: string;
  companyName: string;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  assets?: TransferAsset[];
  asset?: TransferAsset;
  assetId?: number;
  companyId?: number;
}
