// --------------------------------------------------------------
// Created On: 2021-09-21
// Author: Zachary Thomas
//
// Last Modified: 2024-12-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect, useMemo, Fragment } from "react";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import SaveChangesModal from "../../../components/SaveChangesModal/SaveChangesModal";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import Error from "../../../components/Error/Error";
import Spinner from "../../../components/Spinner/Spinner";
import PopulateControl from "../../../components/PopulateControl/PopulateControl";
import deepCopy from "../../../utilities/deepCopy";
import apiRequest from "../../../utilities/api/apiRequest";
import getApiError from "../../../utilities/api/getApiError";
import AssociationContainer from "../../../components/AssociationModal/AssociationContainer/AssociationContainer";
import useApi from "../../../hooks/useApi";
import IconTooltip from "../../../components/IconTooltip/IconTooltip";
import {
  API,
  MIN_GROUP_NAME_LENGTH,
  MAX_GROUP_NAME_LENGTH,
  MAX_ASSOCIATED_USERS,
} from "../../../constants/miscellaneous";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import userHasPermission from "../../../utilities/userHasPermission";
import {
  CREATE_USER_GROUPS_PERMISSION,
  DELETE_USER_GROUPS_PERMISSION,
  UPDATE_USER_GROUPS_PERMISSION,
} from "../../../constants/permissions";
import { USERGROUP_TYPES } from "../../../constants/reducerActions";
import styles from "./UsergroupModal.module.scss";

// Modal for creating, updating, and deleting user groups.
export default function UsergroupModal(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [usergroupName, setUsergroupName] = useState<string>("");
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
  const [roleId, setRoleId] = useState<number>(0);
  const [selectionType, setSelectionType] = useState<string>("");
  const [associatedUsers, setAssociatedUsers] = useState<User[]>([]);
  const [previousAssociatedUsers, setPreviousAssociatedUsers] = useState<User[]>([]);
  const [showConfirmExit, setShowConfirmExit] = useState<boolean>(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const currentUser = useSelector(getCurrentUser);
  const unassociatedUsers = useMemo(
    () => getUnassociatedUsers(associatedUsers, props.users),
    [JSON.stringify(associatedUsers), JSON.stringify(props.users)]
  );
  const modifiedRoles = useMemo(() => getModifiedRoles(props.roles), [JSON.stringify(props.roles)]);

  // Get the current associated users for the current assetgroup.
  useApi(
    () => {
      if (props.usergroupId > 0 && props.showModal) {
        setLoading(true);
        return true;
      } else {
        setLoading(false);
        setUsergroupName("");
        setIsDefault(false);
        setPreviousAssociatedUsers([]);
        setAssociatedUsers([]);
        return false;
      }
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/usergroup/${props.usergroupId}`,
    },
    async (response: Response, responseBody: GetResponseBody) => {
      if (response.ok && responseBody) {
        setPreviousAssociatedUsers(responseBody.users);
        setAssociatedUsers(responseBody.users);
        setUsergroupName(responseBody.name);
        setIsDefault(responseBody.isDefault);
        setRoleId(props.roleId);
      } else {
        setErrorMessage("Internal server error. Unable to load associated user information.");
      }
      setLoading(false);
    },
    [props.showModal, props.usergroupId]
  );

  // By default assign the "Basic User" role when creating a new user group.
  useEffect(() => {
    if (props.mode === "create") {
      props.roles.forEach((role) => {
        if (role.name === "Basic User") {
          setRoleId(role.roleId);
        }
      });
    }
  }, [props.mode, JSON.stringify(props.roles)]);

  // Get the list of unassociated users.
  function getUnassociatedUsers(associatedUsers: User[], users: User[]): User[] {
    const userAssociationMap: UserAssociationMap = {};

    // Use a hash table to figure out what users are unassociated.
    users.forEach((user) => {
      userAssociationMap[user.userId] = user;
    });
    associatedUsers.forEach((associatedUser) => {
      if (associatedUser.userId in userAssociationMap) {
        delete userAssociationMap[associatedUser.userId];
      }
    });

    // Convert the resulting hash table to an array of users.
    const unassociatedUsers: User[] = [];
    Object.keys(userAssociationMap).forEach((userAssociationMapKey: string) => {
      unassociatedUsers.push(userAssociationMap[userAssociationMapKey]);
    });

    return sortUsers(unassociatedUsers);
  }

  // Sort an array of users.
  function sortUsers(users: User[]): User[] {
    return users.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      const emailA = a.emailAddress.toUpperCase();
      const emailB = b.emailAddress.toUpperCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else if (emailA < emailB) {
        return -1;
      } else if (emailA > emailB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  // Disassociate users.
  function disassociateUsers(userIds: number[]): void {
    let associatedUsersDeepCopy = deepCopy(associatedUsers);

    // Filter the users out of the association list.
    associatedUsersDeepCopy = associatedUsersDeepCopy.filter(
      (associatedUser) => !userIds.includes(associatedUser.userId)
    );

    // Update state and clear any selections.
    setAssociatedUsers(associatedUsersDeepCopy);
    setSelectedUserIds([]);
    setSelectionType("");
  }

  // Associate users.
  function associateUsers(userIds: number[]): void {
    let associatedUsersDeepCopy = deepCopy(associatedUsers);
    const unassociatedUsersDeepCopy = deepCopy(unassociatedUsers);

    // Add the users to the association list.
    userIds.forEach((userId) => {
      const item = unassociatedUsersDeepCopy.find((unassociatedUser) => unassociatedUser.userId === userId);
      if (item !== undefined) {
        associatedUsersDeepCopy = [item, ...associatedUsersDeepCopy];
      }
    });

    // Update state and clear any selections.
    setAssociatedUsers(associatedUsersDeepCopy);
    setSelectedUserIds([]);
    setSelectionType("");
  }

  // Disassociate all users.
  function disassociateAllUsers(): void {
    setAssociatedUsers([]);
    setSelectedUserIds([]);
    setSelectionType("");
  }

  // Associate all users.
  function associateAllUsers(): void {
    setAssociatedUsers(sortUsers(deepCopy(props.users)));
    setSelectedUserIds([]);
    setSelectionType("");
  }

  // Select users.
  function selectUsers(userIds: number[], type: string): void {
    setSelectedUserIds(userIds);
    setSelectionType(type);
  }

  // Validate the use group settings.
  function usergroupIsValid(): boolean {
    if (usergroupName.trim().length < MIN_GROUP_NAME_LENGTH || usergroupName.trim().length > MAX_GROUP_NAME_LENGTH) {
      setErrorMessage(
        `The user group name must be between ${MIN_GROUP_NAME_LENGTH} and ${MAX_GROUP_NAME_LENGTH} characters long.`
      );
      return false;
    } else if (associatedUsers.length > MAX_ASSOCIATED_USERS) {
      setErrorMessage(`A user group is not allowed to have over ${MAX_ASSOCIATED_USERS} associated users.`);
      return false;
    } else {
      return true;
    }
  }

  // Create a user group.
  async function createUsergroup(): Promise<void> {
    if (usergroupIsValid()) {
      const userIds: number[] = [];
      associatedUsers.forEach((user) => userIds.push(user.userId));

      const requestBody = {
        name: usergroupName.trim(),
        isDefault: isDefault,
        description: "",
        userIds: userIds,
        roleId: roleId,
      };

      setLoading(true);
      const [response, responseBody] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/usergroup`,
        "POST",
        requestBody
      )) as [Response, PostResponseBody];
      setLoading(false);

      if (response.ok) {
        const newUsergroup = {
          usergroupId: responseBody.usergroupId,
          name: usergroupName.trim(),
          isDefault: isDefault,
          users: associatedUsers,
          roleId: roleId,
        };

        props.onAction({
          type: USERGROUP_TYPES.CREATE_USERGROUP,
          payload: {
            usergroup: newUsergroup,
          },
        });

        discardChanges();
      } else {
        setErrorMessage(await getApiError(response, "Unable to create user group."));
      }
    }
  }

  // Edit a user group.
  async function editUsergroup(): Promise<void> {
    if (usergroupIsValid()) {
      const userIds: number[] = [];
      associatedUsers.forEach((user) => userIds.push(user.userId));

      const requestBody = {
        name: usergroupName.trim(),
        isDefault: isDefault,
        description: "",
        userIds: userIds,
        roleId: roleId,
      };

      setLoading(true);
      const [response] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/usergroup/${props.usergroupId}`,
        "PUT",
        requestBody
      )) as [Response, PutResponseBody];
      setLoading(false);

      if (response.ok) {
        const updatedUsergroup = {
          usergroupId: props.usergroupId,
          name: usergroupName.trim(),
          isDefault: isDefault,
          users: associatedUsers,
          roleId: roleId,
        };

        props.onAction({
          type: USERGROUP_TYPES.UPDATE_USERGROUP,
          payload: {
            usergroup: updatedUsergroup,
          },
        });

        setErrorMessage("");
        props.onClose();
      } else {
        setErrorMessage(await getApiError(response, "Unable to update user group."));
      }
    }
  }

  // Delete a user group.
  async function deleteUsergroup(usergroupId: number): Promise<void> {
    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/usergroup/${props.usergroupId}`,
      "DELETE",
      null
    )) as [Response, DeleteResponseBody];
    setLoading(false);

    if (response.ok) {
      discardChanges();
      props.onAction({
        type: USERGROUP_TYPES.DELETE_USERGROUP,
        payload: {
          usergroupId: usergroupId,
        },
      });
    } else {
      setShowConfirmDelete(false);
      setErrorMessage(await getApiError(response, "Unable to delete user group."));
    }
  }

  // Returns whether the current user is allowed to edit the form with their current permissions.
  function formIsEditable(): boolean {
    return (
      (props.mode === "create" && userHasPermission([[CREATE_USER_GROUPS_PERMISSION]])) ||
      (props.mode !== "create" && userHasPermission([[UPDATE_USER_GROUPS_PERMISSION]]))
    );
  }

  // Exit modal if no changes have been made. Otherwise prompt user to see if they want to save changes.
  function exitModal(): void {
    if (usergroupChanged()) {
      setShowConfirmExit(true);
    } else {
      discardChanges();
    }
  }

  // Check if the usergroup changed.
  function usergroupChanged(): boolean {
    // Check if the user group name is the same.
    if (props.name !== usergroupName) {
      return true;
    }

    // Check if the user group default setting is the same.
    if (props.isDefault !== isDefault) {
      return true;
    }

    // Check if the role is the same.
    if (props.roleId !== roleId) {
      return true;
    }

    // Check if the users are the same.
    const beforeUserIds = previousAssociatedUsers.map((user) => user.userId);
    const afterUserIds = associatedUsers.map((user) => user.userId);

    beforeUserIds.sort((a, b) => a - b);
    afterUserIds.sort((a, b) => a - b);

    if (JSON.stringify(beforeUserIds) !== JSON.stringify(afterUserIds)) {
      return true;
    }

    return false;
  }

  // Save changes.
  function saveChanges(): void {
    if (props.mode === "create") {
      void createUsergroup();
    } else {
      void editUsergroup();
    }
    setShowConfirmExit(false);
  }

  // Exit without saving changes.
  function discardChanges(): void {
    setErrorMessage("");
    setIsDefault(false);
    setUsergroupName("");
    setAssociatedUsers([]);
    setShowConfirmExit(false);
    setShowConfirmDelete(false);
    props.onClose();
  }

  // Returns the list of roles, with the 'Basic User' role replaced with 'None' and always appearing at the start of the list.
  function getModifiedRoles(roles: Role[]): Role[] {
    let rolesDeepCopy = deepCopy(roles);
    const basicUserRoleIndex = rolesDeepCopy.findIndex((role) => role.name === "Basic User");
    if (basicUserRoleIndex > -1) {
      const noneRole = {
        roleId: rolesDeepCopy[basicUserRoleIndex].roleId,
        name: "None",
        description: "",
        immutable: true,
      };
      rolesDeepCopy.splice(basicUserRoleIndex, 1);
      rolesDeepCopy = rolesDeepCopy.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        } else {
          return 0;
        }
      });
      rolesDeepCopy = [noneRole, ...rolesDeepCopy];
    }
    return rolesDeepCopy;
  }

  return (
    <div>
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => exitModal()}
        backdropClassName={styles.backdrop}
        style={{ zIndex: "var(--modal-z-index)" }}
        size="xl"
        animation
        centered
      >
        <ModalHeader>
          <h5 className="modal-title font-weight-bold">
            {props.mode === "create" ? <span>Create User Group</span> : <span>Edit User Group</span>}
          </h5>
        </ModalHeader>

        <ModalBody className={styles.body}>
          {/* User group name input. */}
          {props.mode === "create" ? (
            <div className="form-group mx-2">
              <label className="mb-3">User Group Name</label>
              <input
                data-test="usergroup-name-input"
                className="form-control mx-auto mb-4"
                type="text"
                maxLength={MAX_GROUP_NAME_LENGTH}
                value={usergroupName}
                disabled={!formIsEditable()}
                onChange={(e) => setUsergroupName(e.target.value)}
              />
            </div>
          ) : (
            <div className="form-group mx-2">
              <input
                data-test="usergroup-name-input"
                className={`${styles.name} form-control px-0 mb-3 mx-auto`}
                type="text"
                maxLength={MAX_GROUP_NAME_LENGTH}
                value={usergroupName}
                disabled={!formIsEditable()}
                onChange={(e) => setUsergroupName(e.target.value)}
              />
            </div>
          )}
          {/* Controls if this user group will auto-populate new users. */}
          <PopulateControl
            resourceType="user"
            active={isDefault}
            disabled={!formIsEditable()}
            onChange={() => setIsDefault((previousIsDefault) => !previousIsDefault)}
          />
          <div className="form-group mx-2 my-3">
            <label className={`${styles.label} mb-3`}>
              <span>Role&nbsp;</span>
              <IconTooltip
                id="usergroup-tooltip"
                icon="info-circle"
                message="A user group can be given a role. When a role is assigned to a group, each user in
            that group gains the permissions that are granted by that role."
                color="var(--info-tooltip)"
              />
            </label>
            <select
              data-test="usergroup-role-select"
              className="form-select"
              value={String(roleId)}
              disabled={!formIsEditable()}
              onChange={(e) => setRoleId(parseInt(e.target.value, 10))}
            >
              {modifiedRoles.map((role) => (
                <option value={role.roleId} key={role.roleId}>
                  {role.name}
                </option>
              ))}
            </select>
          </div>

          {/* Association menu. */}
          <AssociationContainer
            type="user"
            associatedItemsTitle="Associated Users"
            unassociatedItemsTitle="Unassociated Users"
            associatedItems={associatedUsers}
            unassociatedItems={unassociatedUsers}
            itemIdKey="userId"
            selectedItemIds={selectedUserIds}
            selectionType={selectionType}
            disabled={!formIsEditable()}
            onSelectItems={(userIds, type) => selectUsers(userIds, type)}
            onAssociateItems={(userIds) => associateUsers(userIds)}
            onDisassociateItems={(userIds) => disassociateUsers(userIds)}
            onAssociateAllItems={() => associateAllUsers()}
            onDisassociateAllItems={() => disassociateAllUsers()}
            onConfirmRoleChange={() => {
              /* Do nothing. */
            }}
          />
          {errorMessage.length > 0 && (
            <div className="mt-3">
              <Error message={errorMessage} />
            </div>
          )}
        </ModalBody>

        <ModalFooter className={styles.footer}>
          {props.mode === "create" ? (
            <Fragment>
              <button
                data-test="create-usergroup-button"
                className={`${styles.button} btn btn-primary`}
                type="button"
                onClick={() => createUsergroup()}
              >
                Create User Group
              </button>

              <button
                data-test="cancel-button"
                className={`${styles.button} btn btn-secondary`}
                type="button"
                onClick={() => exitModal()}
              >
                Cancel
              </button>
            </Fragment>
          ) : (
            <Fragment>
              {userHasPermission([[DELETE_USER_GROUPS_PERMISSION]]) && (
                <button
                  data-test="delete-usergroup-button"
                  className={`${styles.button} btn btn-danger me-auto`}
                  type="button"
                  onClick={() => setShowConfirmDelete(true)}
                >
                  Delete
                </button>
              )}

              {userHasPermission([[UPDATE_USER_GROUPS_PERMISSION]]) && (
                <button
                  data-test="save-usergroup-button"
                  className={`${styles.button} btn btn-primary`}
                  type="button"
                  onClick={() => editUsergroup()}
                >
                  Save Changes
                </button>
              )}

              <button
                data-test="cancel-button"
                className={`${styles.button} btn btn-secondary`}
                type="button"
                onClick={() => exitModal()}
              >
                Cancel
              </button>
            </Fragment>
          )}
        </ModalFooter>
      </Modal>

      {/* Additional child modals. */}
      {props.showModal && (
        <Fragment>
          <ConfirmModal
            showModal={props.showModal && showConfirmDelete}
            title={`Delete '${usergroupName}'?`}
            content={`Are you sure that you want to delete the user group '${usergroupName}'?`}
            yesText="Delete User Group"
            noText="Cancel"
            danger={true}
            onClose={() => setShowConfirmDelete(false)}
            onYes={() => deleteUsergroup(props.usergroupId)}
            onNo={() => setShowConfirmDelete(false)}
          />

          <SaveChangesModal
            showModal={props.showModal && showConfirmExit}
            title="Changes have not been saved!"
            content="Are you sure that you want to exit without saving your changes?"
            onClose={() => setShowConfirmExit(false)}
            onSave={() => saveChanges()}
            onNoSave={() => discardChanges()}
          />
        </Fragment>
      )}
    </div>
  );
}

UsergroupModal.propTypes = {
  mode: PropTypes.oneOf(["create", "edit"]).isRequired,
  usergroupId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  roleId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

interface Props {
  mode: "create" | "edit";
  usergroupId: number;
  name: string;
  isDefault: boolean;
  showModal: boolean;
  users: User[];
  roles: Role[];
  roleId: number;
  onClose: () => void;
  onAction: (action: Action) => void;
}

interface User {
  userId: number;
  name: string;
  emailAddress: string;
  phoneNumberCountryCode: string | null;
  phoneNumber: string | null;
  roleId: number;
}

interface Role {
  roleId: number;
  name: string;
  description: string;
  immutable: boolean;
}

interface Usergroup {
  usergroupId: number;
  name: string;
  isDefault: boolean;
  roleId: number;
}

interface GetResponseBody {
  name: string;
  isDefault: boolean;
  users: User[];
}

interface PostResponseBody {
  usergroupId: number;
}

interface PutResponseBody {
  message: string;
}

interface DeleteResponseBody {
  message: string;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  users?: User[];
  user?: User;
  userId?: number;
  usergroups?: Usergroup[];
  usergroup?: Usergroup;
  usergroupId?: number;
}

interface UserAssociationMap {
  [key: string]: User;
}
